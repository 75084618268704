<template>
  <!-- Main top bar -->
  <div>
    <!-- <div>
      <b-message type="is-warning" has-icon aria-close-label="Close message">
        Your session is about to expire. Log in again to continue without interruption.
      </b-message>
    </div> -->

    <div
      class="is-fullwidth is-flex is-flex-direction-row is-align-items-center is-justify-content-space-between py-5 px-5">
      <div class="is-flex is-flex-direction-column">
        <div>
          <i class="is-clickable mdi mdi-36px mdi-home features-item-icon"><span class="status"></span></i>
          <span class="ml-1 is-size-5 is-capitalized">{{
            store.topBarText
          }}</span>
        </div>

        <div v-if="store.showSites">
          <div v-if="!navigating">
            <div>
              <a @click="handleSwitchClick" style="cursor: pointer;" class="is-size-7 is-flex is-align-items-end">
                {{ !showSelect ? 'Switch Site' : 'Cancel' }}
                <b-icon class="pl-2" :icon="showSelect ? 'close' : 'chevron-down'" size="is-small">
                </b-icon>
              </a>
            </div>

            <div v-if="showSelect">
              <SearcheableSelect size="is-small" @blurred="handleBlurred" :focusOnMount="true" @selected="r => handleSelected(r)"
                placeholder="" :dataSource="sites" />
            </div>
          </div>
          <div v-else class="is-size-7">
            Navigating...
          </div>
        </div>
      </div>

      <div class="is-justify-content-center is-flex">
        <div class="is-flex is-align-items-center">
          <b-field class="control">
            <b-input class="mm" type="text" icon="magnify" placeholder="Search for something...">
            </b-input>
          </b-field>
        </div>
        <div class="pl-5 pt-1 is-flex is-align-items-center">
          <b-dropdown style="cursor: pointer" position="is-bottom-left" append-to-body aria-role="list">
            <template #trigger="{ active }">
              <div class="is-flex is-align-items-center">
                <p style="font-size: 1.3rem">
                  <span :icon="active" class="is-align-self-center fi fi-gb mr-1"></span>
                  <span class="is-size-7">EN</span>
                </p>
              </div>
            </template>

            <b-dropdown-item aria-role="listitem">
              <p style="font-size: 1.3rem">
                <span class="is-align-self-center fi fi-fr mr-1"></span>
                <span class="is-size-7">FR</span>
              </p>
            </b-dropdown-item>
            <b-dropdown-item aria-role="listitem">
              <p style="font-size: 1.3rem">
                <span class="is-align-self-center fi fi-de mr-1"></span>
                <span class="is-size-7">DE</span>
              </p>
            </b-dropdown-item>
            <b-dropdown-item aria-role="listitem">
              <p style="font-size: 1.3rem">
                <span class="is-align-self-center fi fi-pr mr-1"></span>
                <span class="is-size-7">PR</span>
              </p>
            </b-dropdown-item>
          </b-dropdown>
        </div>

        <div class="pl-5 is-flex is-flex-direction-column is-justify-content-center">
          <b-dropdown :triggers="['hover']" position="is-bottom-left" aria-role="list">
            <template #trigger="{ hover }">
              <div class="is-clickable circleBase type1">
                <b-image :src="useGravatar
                  ? store.userData.gravatar
                  : require('@/assets/mp.jpg')
                  " :icon-right="hover" alt="Avatar" :rounded="true">
                </b-image>
              </div>
            </template>
            <div style="width: 240px" class="dditem py-2" aria-role="listitem">
              <p class="has-text-grey has-text-weight-bold">
                {{ store.username }}
              </p>
              <p style="font-size: 13px" class="has-text-grey">
                {{ store.userData.email }}
              </p>
            </div>
            <hr class="my-1" />
            <b-dropdown-item @click="
              $router.push({
                path: '/us/profile',
                query: { tab: 'preferences' },
              })
              " aria-role="listitem">
              <p>Edit Preferences</p>
            </b-dropdown-item>
            <b-dropdown-item @click="
              $router.push({
                path: '/us/profile',
                query: { tab: 'account' },
              })
              " aria-role="listitem">
              <p>My Account</p>
            </b-dropdown-item>
            <b-dropdown-item @click="logoutClicked = true" aria-role="listitem">Log Out</b-dropdown-item>
          </b-dropdown>
        </div>
      </div>
    </div>

    <ConfirmModal :show="logoutClicked" header="Logout" content="Are you sure you want to log out?"
      @response="logoutHandler" />
  </div>
</template>

<script>
import { useUserStore } from "@/store";
import ConfirmModal from "./ConfirmModal.vue";
import { API } from "@/utils";
import SearcheableSelect from "./SearcheableSelect.vue";

export default {
  created() {
    this.useGravatar = this.store.preferences.useGravatar;
  },

  async mounted() {
    let response = await API.get("/sites");
    if (response && !response.error.exists) {
      this.sites = response.data.items;
    }
  },

  setup() {
    const store = useUserStore();
    return { store };
  },
  data() {
    return {
      logoutClicked: false,
      useGravatar: false,
      navigating: false,
      showSelect: false,
      sites: [],
    };
  },
  
  methods: {
    back() {
      this.$router.go(-1);
    },

    handleSwitchClick() {
      this.showSelect = !this.showSelect;
    },

    handleSelected(r) {
      if (r && r.uuid) {
        this.navigating = true;
        window.location = `/us/sites/${r.uuid}`;
      }
    },

    handleBlurred(v) {
      setTimeout(() => {
        if (v === undefined) {
          this.showSelect = false;
        }
      }, 300);
    },
    async logoutHandler(confirmed) {
      if (confirmed) {
        let response = await API.get("/logout");
        if (response.error.exists) {
          this.$buefy.snackbar.open({
            message: response.error.message,
            type: "is-danger",
            position: "is-top",
          });
        } else {
          this.store.reset();
          this.$buefy.snackbar.open({
            message: response.data,
            position: "is-top",
          });
          this.$router.push("/authwall");
        }
      }
      this.logoutClicked = false;
    },
  },
  components: { ConfirmModal, SearcheableSelect },
};
</script>

<style scoped>
.mm {
  width: 20vw;
}

.circleBase {
  border-radius: 50%;
}

.dd>.mdi:before {
  font-size: 25px !important;
}

.type1 {
  width: 34px;
  height: 34px;
  background: #4a4a4a;
}

.type1:hover {
  border-style: solid;
}

input::placeholder {
  font-size: 15px;
}

.dditem {
  display: block;
  font-size: 0.875rem;
  line-height: 1.5;
  padding: 0.375rem 1rem;
  position: relative;
}

.demarcator {
  width: 18px;
  border-color: #f5f7fb;
  border-left: solid;
  border-width: 1px;
  height: 30px;
}
</style>
