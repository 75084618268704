<template>
  <div>
    <!-- Roles -->
    <div class="columns">
      <div class="column">
        <div class="card">
          <header class="card-header is-danger p-2">
            <p class="card-header-title is-size-6">Access Roles <span class="ml-1 has-text-grey">({{ roles.length }})</span></p>
            <p class="is-flex is-align-items-center">
              <b-button
                @click="createClicked = !createClicked"
                class="mr-3 px-5"
                aria-label="more options"
                :type="`${
                  !createClicked ? 'is-info is-light' : 'is-light is-danger'
                }`"
                outlined
                :icon-left="createClicked ? 'cancel' : 'plus'"
              >
                {{ createClicked ? "Cancel" : "Create Access Role" }}
              </b-button>
            </p>
          </header>

          <ModifiableTable
            :showHeader="true"
            :modifiable="false"
            :tableData="roles"
            :tableColumns="rcolumns"
            @deleteRowData="(r) => deleteHandler(r)"
          />
        </div>
      </div>
    </div>

    <CreateRole
      :permissions="permissions"
      :show="createClicked"
      v-if="createClicked"
      @response="(r) => createRoleHandler(r)"
    />

    <ConfirmModal
      header="Delete Role"
      :content="`Are you sure you want to delete the ${snakeToStartCase(
        aboutToDeleteData.name
      )} role?`"
      :show="aboutToDelete"
      @response="(r) => deleteModalConfirmHandler(r)"
    />
    <!-- Permisions Table -->
    <div class="columns">
      <div class="column">
        <div class="card">
          <header class="card-header is-danger p-2">
            <p class="card-header-title is-size-6">Access Permissions <span class="ml-1 has-text-grey">({{ permissions.length }})</span></p>
            <button class="card-header-icon" aria-label="more options">
              <span class="icon">
                <i
                  class="mdi mdi-lock-open-variant-outline mdi-40px"
                  aria-hidden="true"
                ></i>
              </span>
            </button>
          </header>

          <ModifiableTable
            :modifiable="false"
            :deletable="false"
            :showHeader="true"
            :tableData="permissions"
            :tableColumns="pcolumns"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ModifiableTable from "@/components/ModifiableTable.vue";
import { useUserStore } from "@/store";
import { API, snakeToStartCase, fetchNextSetOfData, numberize } from "@/utils";
import CreateRole from "./CreateRole.vue";
import ConfirmModal from "@/components/ConfirmModal.vue";

const EXCLUDE_PARAMS = ["uuid"];
const ROLE_VISIBLE_FIELDS = [
  "no",
  "name",
  "description",
  "permissible_actions",
  "modifiable",
];
const PERMISSION_VISIBLE_FIELDS = ["no", "name", "description"];

export default {
  setup() {
    const store = useUserStore();
    return { store };
  },
  watch: {
    // whenever question changes, this function will run
    search(query) {
      this.renderedData = this.tableData;
      if (query.length >= 3) {
        this.isLoading = true;
        setTimeout(() => {
          let filterResult = [];
          for (const item of this.tableData) {
            // Search the values
            for (const s of Object.values(item)) {
              if (
                JSON.stringify(s)
                  .toLocaleLowerCase()
                  .includes(query.toLocaleLowerCase()) &&
                !filterResult.includes(item)
              ) {
                filterResult.push(item);
              }
            }
          }
          this.renderedData = filterResult;
          this.isLoading = false;
        }, 400);
      }
    },
  },
  data() {
    return {
      rcolumns: [],
      pcolumns: [],
      isLoading: false,
      roles: [],
      permissions: [],
      after: "",
      createClicked: false,
      search: "",
      aboutToDeleteData: {},
      aboutToDelete: false,
      timeoutID: "",
    };
  },
  methods: {
    snakeToStartCase(text) {
      return snakeToStartCase(text);
    },
    async updatePermissions() {
      let maxRequests = 20;
      let requestsCount = 1;
      let allFetched = false;
      if (this.after !== "") {
        while (!allFetched && requestsCount <= maxRequests) {
          requestsCount += 1;
          let response = await fetchNextSetOfData(
            "permissions",
            this.after,
            this.permissions
          );
          this.after = response.newUuidAfter;
          allFetched = response.allIsFetched;
        }
      }
    },
    async fetchPermissions() {
      this.isLoading = true;
      let permissions = [...this.permissions];

      let response = await API.get(`/permissions`);
      if (!response.error.exists && response.data.items_count) {
        if (response.data.items_count == response.data.items_per_page) {
          this.after = response.data.uuid_after;
          numberize(response.data.items, permissions);
        }
        if (this.pcolumns.length === 0) {
          this.pcolumns = Object.keys(permissions[0])
            .filter((c) => !EXCLUDE_PARAMS.includes(c))
            .map((c) => {
              return {
                visible: true,
                sortable: false,
                centered: false,
                label: snakeToStartCase(c),
                field: c === "name" ? "tag" : c,
              };
            });

          for (
            let index = 0;
            index < PERMISSION_VISIBLE_FIELDS.length;
            index++
          ) {
            let indexInColumns = this.pcolumns
              .map((c) => c.field)
              .indexOf(PERMISSION_VISIBLE_FIELDS[index]);
            // `move` is added to Array.prototype in `utils.js`
            this.pcolumns.move(indexInColumns, index);
          }
        }
      }
      this.permissions = [];
      this.permissions = permissions
      this.isLoading = false;
    },
    deleteHandler(rowData) {
      this.aboutToDeleteData = rowData;
      this.aboutToDelete = true;
    },
    async deleteModalConfirmHandler(confirmed) {
      this.isLoading = true;
      if (confirmed) {
        this.aboutToDelete = false;
        this.isLoading = true;
        let response = await API.delete(`/role/${this.aboutToDeleteData.uuid}`);
        if (!response.error.exists) {
          this.$buefy.snackbar.open({
            message: "Role successfully deleted",
            type: "is-success",
            pauseOnHover: false,
            position: "is-bottom",
          });
          this.aboutToDeleteData = {};
          this.fetchRoles();
        } else {
          this.$buefy.snackbar.open({
            message: response.error.message,
            type: "is-danger",
            pauseOnHover: false,
            position: "is-top",
          });
        }
      }
      this.aboutToDelete = false;
      this.isLoading = false;
    },
    createRoleHandler(successful) {
      if (successful) {
        this.createClicked = !successful;
        this.$buefy.snackbar.open({
          message: "Role successfully created",
          type: "is-success",
          pauseOnHover: false,
          position: "is-bottom",
        });
        this.fetchRoles();
      } else {
        this.createClicked = successful;
      }
    },
    async fetchRoles() {
      this.isLoading = true;
      let roles = []; // Reset
      let response = await API.get(`/roles`);
      if (!response.error.exists && response.data.items_count) {
        roles = response.data.items.map((c, index) => {
          return {
            uuid: c.uuid,
            name: c.name,
            no: index + 1,
            description: c.description,
            tagListItems: c.permissions,
            modifiable: c.is_custom ? "Yes" : "No",
            permissible_actions: c.permissions.length,
          };
        });
        if (this.rcolumns.length === 0) {
          this.rcolumns = Object.keys(roles[0])
            .filter((c) => !EXCLUDE_PARAMS.includes(c))
            .map((c) => {
              return {
                width: c === "tagListItems" ? 800 : "",
                field: c === "tagListItems" ? "tagList" : c,
                sortable: ["no", "permissible_actions"].includes(c),
                centered: !["name", "description"].includes(c),
                label:
                  c === "tagListItems" ? "Permissions" : snakeToStartCase(c),
                visible: ROLE_VISIBLE_FIELDS.includes(c),
              };
            });
          // Order columns in expected view
          for (let index = 0; index < ROLE_VISIBLE_FIELDS.length; index++) {
            let indexInColumns = this.rcolumns
              .map((c) => c.field)
              .indexOf(ROLE_VISIBLE_FIELDS[index]);
            // `move` is added to Array.prototype in `utils.js`
            this.rcolumns.move(indexInColumns, index);
          }
        }
      }

      this.roles=[];
      this.roles = roles;
      this.isLoading = false;
    },
  },
  beforeDestroy() {
    clearTimeout(this.timeoutID);
  },
  async mounted() {
    await this.fetchRoles();
    await this.fetchPermissions();
    this.timeoutID = setTimeout(async () => {
      await this.updatePermissions();
    }, 5000);
  },
  components: { ModifiableTable, CreateRole, ConfirmModal },
};
</script>
<style scoped>
.tg > .mdi:before,
.mdi-set {
  color: grey;
  font-size: 40px !important;
}
</style>
