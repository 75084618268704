<template>
  <div class="is-fullwidth is-flex is-flex-direction-column is-flex-grow-2">
    <div class="tabs is-left is-full is-size-7">
      <ul>
        <li
          class="mx-0 mr-4"
          @click="updateActiveTab('realtime_data')"
          :class="{ 'is-active': activeTab === 'realtime_data' }"
        >
          <a>
            <span class="icon is-small"
              ><i class="mdi mdi-timer-play-outline" aria-hidden="true"></i
            ></span>
            <span>Real Time Data</span>
          </a>
        </li>
        <li
          v-if="showTrafficTab"
          class="mx-0 mr-4"
          @click="updateActiveTab('traffic')"
          :class="{ 'is-active': activeTab === 'traffic' }"
        >
          <a>
            <span class="icon is-small"
              ><i class="mdi mdi-finance" aria-hidden="true"></i
            ></span>
            <span>RF Traffic</span>
          </a>
        </li>
        <li
          class="mx-0 mr-4"
          @click="updateActiveTab('alarms')"
          :class="{ 'is-active': activeTab === 'alarms' }"
        >
          <a>
            <span class="icon is-small"
              ><i class="mdi mdi-alarm-light" aria-hidden="true"></i
            ></span>
            <span>Alarms</span>
          </a>
        </li>
        <li
          class="mx-0 mr-4"
          @click="updateActiveTab('details')"
          :class="{ 'is-active': activeTab === 'details' }"
        >
          <a>
            <span class="icon is-small"
              ><i class="mdi mdi-information-outline" aria-hidden="true"></i
            ></span>
            <span>Details</span>
          </a>
        </li>
      </ul>
    </div>
    <div class="is-flex-grow-2 py-4">
      <LiveDataView v-show="activeTab === 'realtime_data'" />
      <RFTraffic v-show="activeTab === 'traffic'" />
      <Alarms v-show="activeTab === 'alarms'" />
      <SiteDetailsView v-show="activeTab === 'details'" />
    </div>
  </div>
</template>

<script>
import { useUserStore } from "@/store";
import LiveDataView from "./LiveDataView.vue";
import SiteDetailsView from "@/containers/Sites/Site/SiteDetailsView.vue";
import RFTraffic from "./RFTraffic.vue";
import Alarms from "./Alarms.vue";
import { snakeToStartCase, API } from "@/utils";

export default {
  props: {
    name: String,
  },
  setup() {
    const store = useUserStore();
    return { store };
  },
  async created() {
    this.isLoading = true;
    let response = await API.get(`/site/${this.$route.params.uuid}`);
    if (!response.error.exists) {
      this.showTrafficTab = response.data.trafficable;
    }
    this.store.updateCurrentSite(response.data.name);
    this.store.updateShowSites(true);
    this.store.updateTopBar(
      `/ Sites / ${response.data.name} / ${snakeToStartCase(this.activeTab)}`
    );
    // Get status from user preferences
    this.activeTab = this.store.preferences.sitePage;
  },
  data() {
    return {
      showTrafficTab: false,
      activeTab: "details",
    };
  },
  methods: {
    updateActiveTab(selected) {
      this.activeTab = selected;
      this.store.updateTopBar(
        `/ Sites / ${this.store.currentSite} / ${snakeToStartCase(
          this.activeTab
        )}`
      );
    },
  },

  beforeDestroy() {
    this.store.updateShowSites(false);
  },

  components: { Alarms, SiteDetailsView, LiveDataView, RFTraffic },
};
</script>

<style scoped>
.tabs:not(:last-child) {
  /* eslint-disable-line no-mixed-spaces-and-tabs */
  margin-bottom: 0rem !important;
  /** eslint-disable-line no-mixed-spaces-and-tabs **/
}

.table {
  overflow-y: scroll;
}

a {
  color: #4a4a4a !important;
}
</style>
