<template>
  <div class="is-fullwidth is-flex is-flex-direction-column is-flex-grow-2">
    <div class="tabs is-left is-full is-size-7">
      <ul>
        <li
          class="mx-0 mr-4"
          @click="updateActiveTab('map_view')"
          :class="{ 'is-active': activeTab === 'map_view' }"
        >
          <a>
            <span class="icon is-small"
              ><i class="mdi mdi-earth" aria-hidden="true"></i
            ></span>
            <span>Map View</span>
          </a>
        </li>
        <li
          class="mx-0 mr-4"
          @click="updateActiveTab('table_view')"
          :class="{ 'is-active': activeTab === 'table_view' }"
        >
          <a>
            <span class="icon is-small"
              ><i class="mdi mdi-dots-grid" aria-hidden="true"></i
            ></span>
            <span>Tabular View</span>
          </a>
        </li>
        <!-- <li
          class="mx-0 mr-4"
          @click="updateActiveTab('statistics')"
          :class="{ 'is-active': activeTab === 'statistics' }"
        >
          <a>
            <span class="icon is-small"
              ><i class="mdi mdi-chart-gantt" aria-hidden="true"></i
            ></span>
            <span>Statistics</span>
          </a>
        </li> -->
      </ul>
    </div>
    <div class="is-flex-grow-2 py-4">
      <MapView v-if="activeTab === 'map_view'" />
      <TabularView v-if="activeTab === 'table_view'" />
      <StatisticsView v-if="activeTab === 'statistics'" />
    </div>
  </div>
</template>

<script>
import MapView from "./MapView.vue";
import TabularView from "./TabularView.vue";
import StatisticsView from "./StatisticsView.vue";
import { useUserStore } from "@/store";
import { snakeToStartCase } from "@/utils";

const module = "/ Sites";

export default {
  data() {
    return {
      activeTab: "table_view",
    };
  },
  setup() {
    const store = useUserStore();
    return { store };
  },
  created() {
    // Get status from user preferences
    this.activeTab = this.store.preferences.sitesPage;    
    this.store.updateTopBar(`${module} / ${snakeToStartCase(this.activeTab)}`);
  },
  methods: {
    updateActiveTab(selected) {
      this.activeTab = selected;
      this.store.updateTopBar(
        `${module} / ${snakeToStartCase(this.activeTab)}`
      );
    },
  },
  components: { MapView, StatisticsView, TabularView },
};
</script>

<style scoped>
.tabs:not(:last-child) {
  /* eslint-disable-line no-mixed-spaces-and-tabs */
  margin-bottom: 0rem !important;
  /** eslint-disable-line no-mixed-spaces-and-tabs **/
}

.table {
  overflow-y: scroll;
}

a {
  color: #4a4a4a !important;
}
</style>
