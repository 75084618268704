<template>
  <div>
    <div v-if="siteNotDeployed">
      <div class="is-flex-grow-2">
        <div class="card">
          <header class="card-header is-danger p-2">
            <p class="card-header-title is-size-6">Undeployed Site</p>
            <button class="card-header-icon" aria-label="more options">
              <span class="icon">
                <i
                  class="mdi mdi-information-outline mdi-40px"
                  aria-hidden="true"
                ></i>
              </span>
            </button>
          </header>
          <div
            class="card-content is-flex is-align-items-center is-justify-content-center"
            style="height: 500px"
          >
            <div class="is-flex is-flex-direction-column pb-6">
              <div
                class="is-flex is-align-items-center is-justify-content-center"
              >
                <div class="is-flex">
                  <p class="is-align-self-center tg">
                    <i
                      class="mdi mdi-earth-remove mdi-40px"
                      aria-hidden="true"
                    ></i>
                  </p>
                </div>
              </div>
              <p class="is-size-6 has-text-grey">
                This site has not yet been deployed. Generate and run a
                deployment script to deploy it.
              </p>
            </div>
          </div>
        </div>

        <!-- Metadata -->
        <div class="is-size-7 mt-5 has-text-grey has-text-centered">
          Last updated: Just now.
        </div>
      </div>
    </div>

    <div v-else>
      <!-- Control/Power Indicators -->
      <div
        v-if="!isLoading"
        class="pt-1 pb-1 is-flex is-justify-content-space-between"
      >
        <b-field>
          <b-radio-button
            class="rbut"
            v-if="powerIndicator"
            v-model="powerIndicator"
            :native-value="true"
            type="is-light is-success"
          >
            <b-icon icon="power-plug"></b-icon>
            <span>Power Supply Present</span>
          </b-radio-button>

          <b-radio-button
            class="rbut"
            v-model="powerIndicator"
            v-else
            :native-value="false"
            type="is-light is-danger"
          >
            <b-icon icon="power-plug-off"></b-icon>
            <span>Power Supply Lost</span>
          </b-radio-button>
          <b-radio-button
            v-if="signalIndicator"
            class="rbut"
            v-model="signalIndicator"
            :native-value="true"
            type="is-light is-success"
          >
            <b-icon icon="wifi"></b-icon>
            <span class="is-size-6">Control Signal Present</span>
          </b-radio-button>

          <b-radio-button
            v-else
            class="rbut"
            v-model="signalIndicator"
            :native-value="false"
            type="is-light is-warning"
          >
            <b-icon icon="wifi-off"></b-icon>
            <span class="is-size-6">Control Signal Lost</span>
          </b-radio-button>
        </b-field>
        <b-field>
          <b-radio-button
            v-model="cardView"
            :native-value="false"
            type="is-dark is-light"
          >
            <b-icon icon="table"></b-icon>
            <span>Table View</span>
          </b-radio-button>

          <b-radio-button
            v-model="cardView"
            :native-value="true"
            type="is-dark is-light"
          >
            <b-icon icon="select"></b-icon>
            <span>Card View</span>
          </b-radio-button>
        </b-field>
      </div>

      <!-- Control/Power messages -->
      <div v-show="!powerIndicator" :class="!signalIndicator ? 'pb-2' : 'pb-4'">
        <b-message type="is-danger" has-icon aria-close-label="Close message">
          <span class="pl-2"
            >The site has lost power supply! Also check the alarms tab for any
            active alarms.</span
          >
        </b-message>
      </div>
      <div v-show="!signalIndicator" class="pb-4">
        <b-message type="is-warning" has-icon aria-close-label="Close message">
          <span class="pl-2"
            >The controller signaling this site has lost internet access and so,
            real-time data is unavailable. Last Control Signal ping was on
            {{ lastControlSignalPing }}.</span
          >
        </b-message>
      </div>

      <!-- Table view -->
      <div v-show="!cardView" class="columns">
        <div class="column">
          <ModifiableTable
            :modifiable="false"
            :deletable="false"
            :tableColumns="columns"
            :tableData="parameters"
          />
        </div>
      </div>

      <!-- Card view -->
      <div v-show="cardView" class="columns is-flex-wrap-wrap">
        <div class="column">
          <LiveDataCard :parameters="col1" />
        </div>
        <div class="column">
          <LiveDataCard :parameters="col2" />
        </div>
        <div class="column">
          <LiveDataCard :parameters="col3" />
        </div>

        <div class="column">
          <LiveDataCard :parameters="col4" />
        </div>
        <div class="column">
          <LiveDataCard :parameters="col5" />
        </div>
      </div>

      <!-- Time ping -->
      <div
        v-show="!isLoading"
        class="is-size-7 has-text-grey has-text-centered"
      >
        Last updated: {{ lastPing || "Just now" }}.
      </div>
    </div>

    <b-loading
      :is-full-page="false"
      v-model="isLoading"
      :can-cancel="true"
    ></b-loading>
  </div>
</template>

<script>
import { useUserStore } from "@/store";
import { API, APIMultiRequests, snakeToStartCase } from "@/utils";
import LiveDataCard from "./LiveDataCard.vue";
import ModifiableTable from "@/components/ModifiableTable.vue";

const EXCLUDE_PARAMS = ["uuid", "name", "is_live", "time_stamp", "is_powered"];
export default {
  setup() {
    const store = useUserStore();
    return { store };
  },
  data() {
    return {
      col1: [],
      col2: [],
      col3: [],
      col4: [],
      col5: [],
      units: [],
      columns: [],
      parameters: [],
      thresholds: [],

      lastPing: "",
      pingIntervalId: "",
      lastControlSignalPing: "",

      cardView: false,
      isLoading: false,
      siteNotDeployed: false,
      powerIndicator: !false,
      signalIndicator: !false,
    };
  },

  methods: {
    clearParameters() {
      this.col1 = [];
      this.col2 = [];
      this.col3 = [];
      this.col4 = [];
      this.col5 = [];
      this.parameters = [];
    },
    processPing(parameters) {
      this.clearParameters();

      // API would have this in the ping
      // data if the site is not deploued
      if (parameters.siteUndeployed) {
        this.siteNotDeployed = true;
        this.isLoading = false;
        return;
      }

      this.siteNotDeployed = false;

      // Set indicator status of devices here
      this.lastControlSignalPing = parameters.time_stamp;
      this.powerIndicator = parameters.is_powered;
      this.signalIndicator = parameters.is_live;

      this.parameters = Object.entries(parameters)
        .filter((k) => !EXCLUDE_PARAMS.includes(k[0]))
        .map((k) => {
          let icon;
          let name = k[0];
          let value = k[1];
          let upper = `${name}_upper`;
          let lower = `${name}_lower`;
          let unit = this.units[name] && this.units[name].replace("*", "°");

          let status =
            this.thresholds[lower] < value && value < this.thresholds[upper]
              ? "is-no-alarm"
              : "is-alarmed";

          if (name.includes("temperature")) {
            icon = "thermometer-low";
          } else if (name.includes("frequency")) {
            icon = "sine-wave";
          } else if (name.includes("pv")) {
            icon = "solar-power-variant-outline";
          } else if (name.includes("solar")) {
            icon = "solar-power-variant-outline";
          } else if (name.includes("grid")) {
            icon = "transmission-tower";
          } else if (name.includes("hrs")) {
            icon = "timer-sand";
          } else if (name.includes("current")) {
            icon = "current-dc";
          } else if (name.includes("signal")) {
            icon = "signal-cellular-1";
          } else if (name.includes("voltage")) {
            icon = "lightning-bolt-outline";
          } else if (name.includes("power")) {
            icon = "meter-electric-outline";
          } else if (name.includes("battery")) {
            icon = "car-battery";
          } else if (name.includes("capacity")) {
            icon = "percent";
          } else if (name.includes("percent")) {
            icon = "percent";
          } else if (name.includes("ratio")) {
            icon = "division";
          } else {
            icon = "tune-variant";
            // icon = "square-medium-outline";
          }
          return {
            field: name,
            name: snakeToStartCase(name),
            value: value !== null ? value : "-1",
            status: status,
            unit: unit,
            icon: icon,
            lower_threshold: this.thresholds[lower],
            upper_threshold: this.thresholds[upper],
          };
        });

      // Split into columns, too
      this.parameters.forEach((p, index) => {
        if ((index + 1) % 5 === 1) {
          this.col1.push(p);
        } else if ((index + 1) % 5 === 2) {
          this.col2.push(p);
        } else if ((index + 1) % 5 === 3) {
          this.col3.push(p);
        } else if ((index + 1) % 5 === 4) {
          this.col4.push(p);
        } else if ((index + 1) % 5 === 0) {
          this.col5.push(p);
        }
      });

      // Populate columns only once
      if (this.columns.length === 0) {
        this.columns =
          this.parameters.length !== 0
            ? Object.entries(this.parameters[0])
                .filter((c) => !["field", "status"].includes(c[0]))
                .map((c) => {
                  return {
                    field: c[0],
                    centered: !["icon", "name"].includes(c[0]),
                    visible: true,
                    label:
                      c[0] === "name"
                        ? "Parameter Name"
                        : c[0] === "value"
                        ? "Parameter Value"
                        : snakeToStartCase(c[0]),
                  };
                })
            : [];

        // Order columns in expected view
        // `move` is added to Array.prototype in `utils.js`
        this.columns.move(3, 0);
        this.columns.move(4, 2);
        this.columns.move(5, 4);
      }
    },
  },
  async created() {
    this.isLoading = true;
    this.cardView = true;

    this.pingIntervalId = setInterval(async () => {
      let response = await API.get(`/site/${this.$route.params.uuid}/ping`);
      this.lastPing = new Date().toLocaleString();
      if (response.code === 200 && !response.error.exists) {
        this.processPing(response.data);
      }
    }, 7000);

    const parameterRequests = [
      { mode: "get", url: `/site/${this.$route.params.uuid}/ping` },
      { mode: "get", url: `/site/${this.$route.params.uuid}/units` },
      { mode: "get", url: `/alarm-threshold/${this.$route.params.uuid}` },
    ];

    // Make all requests in parallel
    let [parameters, units, thresholds] = await APIMultiRequests(
      parameterRequests
    );

    // Populate in state
    if (!parameters.error.exists) {
      // Order matters
      this.store.updateCurrentSite(parameters.data.name);
      this.store.updateTopBar(
        `/ Sites / ${parameters.data.name} / ${snakeToStartCase(
          "realtime_data"
        )}`
      );

      // Do these last
      this.units = units.data;
      this.thresholds = thresholds.data;
      this.processPing(parameters.data);
    }
    this.isLoading = false;
  },

  beforeDestroy() {
    clearInterval(this.pingIntervalId);
  },

  components: { LiveDataCard, ModifiableTable },
};
</script>
<style scoped>
.tg > .mdi:before,
.mdi-set {
  color: grey;
  font-size: 40px !important;
}
</style>
