<template>
  <div>
    <div class="columns">
      <div class="column">
        <div class="card">
          <div class="card-content p-5">
            <div class="content is-flex is-justify-content-space-evenly">
              <div
                class="is-flex is-flex-direction-column has-text-grey is-justify-content-center"
              >
                <div
                  style="font-size: 80px; line-height: 80px"
                  class="is-align-self-center"
                >
                  60
                </div>
                <div class="is-align-self-center">Total Number of Sites</div>
              </div>
              <div class="is-align-self-center">
                <i class="mdi mdi-sigma card-icon"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="column">
        <div class="card">
          <div class="card-content p-5">
            <div class="content is-flex is-justify-content-space-evenly">
              <div
                class="is-flex is-flex-direction-column has-text-grey is-justify-content-center"
              >
                <div
                  style="font-size: 80px; line-height: 80px"
                  class="is-align-self-center"
                >
                  60
                </div>
                <div class="is-align-self-center">Live Sites</div>
              </div>
              <div class="is-align-self-center">
                <i class="mdi mdi-access-point-network card-icon"></i>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="column">
        <div class="card">
          <div class="card-content p-5">
            <div class="content is-flex is-justify-content-space-evenly">
              <div
                class="is-flex is-flex-direction-column has-text-grey is-justify-content-center"
              >
                <div
                  style="font-size: 80px; line-height: 80px"
                  class="is-align-self-center"
                >
                  60
                </div>
                <div class="is-align-self-center">Off Sites</div>
              </div>
              <div class="is-align-self-center">
                <i class="mdi mdi-power-plug-off card-icon"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>
<style>
.card-icon::before {
  font-size: 50px !important;
}
</style>
