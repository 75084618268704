<template>
  <div class="is-flex is-flex-direction-column">
    <b-modal v-model="showColumns" :width="750" scroll="keep">
      <div class="card">
        <header class="card-header">
          <p class="card-header-title">Column Display Configuration</p>
          <button class="card-header-icon" aria-label="more options">
            <span class="icon">
              <i class="mdi mdi-view-column" aria-hidden="true"></i>
            </span>
          </button>
        </header>
        <div class="card-content">
          <div class="is-flex is-flex-wrap-wrap">
            <b-field
              :key="index"
              v-for="(column, index) in tableColumns"
              class="px-3"
            >
              <b-checkbox v-model="column.visible">
                {{ column.label }}
              </b-checkbox>
            </b-field>
          </div>
        </div>
      </div>
    </b-modal>

    <div style="overflow-x: auto" class="card">
      <header v-show="showHeader" class="card-header is-danger px-2">
        <p class="card-header-title is-size-6 is-flex">
          <b-field>
            <b-input
              style="width: 30vw"
              class="is-fullwidth"
              icon="magnify"
              :icon-right="search ? 'close-circle' : ''"
              icon-right-clickable
              @icon-right-click="search = ''"
              placeholder="Filter by name, or other values..."
              v-model="search"
            ></b-input>
          </b-field>
        </p>

        <p v-if="modifiable" class="is-align-self-center pr-4">
          <b-button
            :disabled="!rowSelected"
            :title="`${
              rowSelected ? 'Edit selected row' : 'First select a row to edit'
            }`"
            @click="$emit('editRowData', selectedRow)"
            aria-label="edit"
            type="is-dark"
            outlined
            icon-left="pencil"
          >
          </b-button>
        </p>
        <p v-if="deletable" class="is-align-self-center pr-4">
          <b-button
            :disabled="!rowSelected"
            :title="`${
              rowSelected
                ? 'Delete selected row'
                : 'First select a row to delete'
            }`"
            @click="$emit('deleteRowData', selectedRow)"
            aria-label="delete"
            type="is-danger"
            outlined
            icon-left="trash-can-outline"
          >
          </b-button>
        </p>

        <p
          v-if="modifiable || deletable"
          class="is-align-self-center demarcator"
        ></p>

        <p class="is-align-self-center pr-4">
          <b-button
            outlined
            type="is-dark"
            icon-left="view-column"
            label="Columns"
            class=""
            @click="showColumns = !showColumns"
          ></b-button>
        </p>
      </header>

      <div class="card-content p-4">
        <b-table
          style="color: grey"
          class="has-text-grey"
          :data="renderedData.length === 0 ? [] : renderedData"
          :loading="isLoading"
          :hoverable="true"
          :striped="true"
          :selected.sync="selectedRow"
          default-sort-direction="desc"
          :paginated="renderedData.length > itemsPerPage - 1"
          :per-page="itemsPerPage"
          :current-page.sync="currentPage"
          :pagination-simple="false"
          pagination-position="bottom"
        >
          <b-table-column
            :key="index"
            v-for="(column, index) in tableColumns"
            cell-class="tdd"
            :centered="column.centered"
            :visible="column.visible"
            :field="column.field"
            :label="column.label"
            :numeric="column.numeric"
            :sortable="column.sortable"
            :width="column.width"
            v-slot="props"
          >
            <!-- Route to component and pass params -->
            <router-link
              class="is-capitalized"
              v-if="column.routerable"
              :to="{
                name: props.row['routerableParams'][column.field]['routerTo'],
                params: props.row['routerableParams'][column.field],
              }"
            >
              {{ snakeToStartCase(props.row[column.field]) }}
            </router-link>

            <span class="py-4" v-else>
              <!-- Icons -->
              <span
                v-if="column.field === 'icon'"
                :class="props.row['status']"
                class="is-capitalized py-4 status-icon"
              >
                <i
                  :class="`mdi-${props.row[column.field]}`"
                  class="mdi features-item-icon"
                >
                </i>
              </span>

              <!-- Dates -->
              <span
                v-else-if="
                  [
                    'timestamp',
                    'time_stamp',
                    'registered_at',
                    'date_deployed',
                    'date_registered',
                  ].includes(column.field)
                "
                class="is-capitalized py-4"
              >
                {{ props.row[column.field] || "N/A" }}
                <!-- {{ formatDate(props.row[column.field]) }} -->
              </span>

              <!-- Colors -->
              <span v-else-if="column.field === 'color'">
                <b-colorpicker
                  disabled
                  expanded
                  :value="props.row[column.field]"
                />
              </span>

              <!-- Non-list items to be displayed as tags -->
              <span v-else-if="column.field === 'tag'">
                <b-tag class="mr-2">{{ props.row.name }}</b-tag>
              </span>

              <!-- Non-list items to be displayed as tags -->
              <span v-else-if="column.field === 'tagValue'">
                <b-tag class="mr-2">{{
                  snakeToStartCase(props.row.value)
                }}</b-tag>
              </span>

              <!-- List items to be displayed as tags -->
              <span
                v-else-if="column.field === 'tagList'"
                class="is-align-content-center"
              >
                <span
                  :key="index"
                  v-for="(tagListItem, index) in props.row.tagListItems"
                >
                  <b-tag :type="tagListItem.type ? tagListItem.type : 'is-normal'" class="mr-2">{{ tagListItem.name }}</b-tag>
                </span>
              </span>

              <!-- Boolean Items to be displayed -->
              <span
                v-else-if="column.field === 'boolean'"
                class="is-align-content-center is-capitalized"
              >
                <b-field>
                  <b-tag
                    :type="`${
                      props.row['enabled'] ? 'is-success' : 'is-danger'
                    }`"
                    :icon="`${props.row['enabled'] ? 'check' : 'close'}`"
                    >{{
                      props.row["enabled"] ? "Active" : "Deactivated"
                    }}</b-tag
                  >
                </b-field>
              </span>

              <!-- [TODO] This is a bad hack to display the user date in logs as not capitalized -->
              <span class="" v-else-if="column.field === 'do-no-cap'">
                {{ props.row["user"] }}
              </span>

              <span class="" v-else-if="column.field === 'description'">
                {{ snakeToStartCase(props.row["description"]) }}
              </span>

              <span
                class="is-align-content-center"
                v-else-if="column.field === 'boolButton'"
              >
                <b-button
                  type="is-link"
                  icon-left="close"
                  :label="props.row['enabled'] === true ? 'Disable' : 'Enable'"
                ></b-button>
              </span>

              <!-- Every other type / Default -->
              <span class="is-capitalized" v-else>
                {{
                  [null, undefined].includes(props.row[column.field])
                    ? "N/A"
                    : snakeToStartCase(props.row[column.field])
                }}
              </span>
            </span>
          </b-table-column>

          <template #empty>
            <div class="p-3 has-text-centered">
              <span v-show="!isLoading">No records to display.</span>
            </div>
          </template>
        </b-table>
      </div>
    </div>
  </div>
</template>

<script>
import { snakeToStartCase } from "@/utils";
import { useUserStore } from "@/store";
// import moment from "moment";

export default {
  props: {
    tableData: Array,
    tableColumns: Array,
    perPage: {
      default: 25,
      type: Number,
    },
    showHeader: {
      default: true,
      type: Boolean,
    },
    modifiable: {
      default: true,
      type: Boolean,
    },
    deletable: {
      default: true,
      type: Boolean,
    },
  },
  setup() {
    const store = useUserStore();
    return { store };
  },
  watch: {
    tableData(ntd) {
      this.renderedData = ntd;
      this.isLoading = false;
      // this.selectedRow = {};
      this.rowSelected = false;
    },
    selectedRow(data) {
      this.rowSelected = data !== {};
    },
    // whenever question changes, this function will run
    search(query) {
      this.renderedData = this.tableData;
      if (query.length >= 3) {
        this.isLoading = true;
        this.rowSelected = false;

        setTimeout(() => {
          let filterResult = [];

          for (const item of this.tableData) {
            // Search the values
            for (const s of Object.values(item)) {
              if (
                JSON.stringify(s)
                  .toLocaleLowerCase()
                  .includes(query.toLocaleLowerCase()) &&
                !filterResult.includes(item)
              ) {
                filterResult.push(item);
              }
            }
          }
          this.renderedData = filterResult;
          this.isLoading = false;
        }, 400);
      }
    },
  },
  methods: {
    // formatDate(datetime) {
    //   if (datetime) {
    //     return moment(datetime).format("MMMM Do YYYY, HH:mm:ss");
    //   }
    //   return "N/A";
    // },

    snakeToStartCase(str) {
      return snakeToStartCase(str);
    },
  },
  mounted() {
    this.isLoading = true;
    this.itemsPerPage = this.store.preferences.perPage || this.perPage;
  },
  data() {
    return {
      search: "",
      currentPage: 1,
      itemsPerPage: 25,
      selectedRow: {},
      isLoading: false,
      renderedData: [],
      rowSelected: false,
      showColumns: false,
    };
  },
};
</script>
<style scoped>
.status-icon-green {
  color: green;
}

.card-content > .table td,
.table th {
  padding-top: 0.8em !important;
  padding-bottom: 0.8em !important;
}

.status-icon-red {
  color: red;
}

.status-icon > .mdi:before {
  font-size: 24px !important;
}
.demarcator {
  width: 18px;
  border-color: #f5f7fb;
  border-left: solid;
  border-width: 1px;
  height: 30px;
}
</style>
