var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"is-fullwidth is-flex is-flex-direction-row is-align-items-center is-justify-content-space-between py-5 px-5"},[_c('div',{staticClass:"is-flex is-flex-direction-column"},[_c('div',[_vm._m(0),_c('span',{staticClass:"ml-1 is-size-5 is-capitalized"},[_vm._v(_vm._s(_vm.store.topBarText))])]),(_vm.store.showSites)?_c('div',[(!_vm.navigating)?_c('div',[_c('div',[_c('a',{staticClass:"is-size-7 is-flex is-align-items-end",staticStyle:{"cursor":"pointer"},on:{"click":_vm.handleSwitchClick}},[_vm._v(" "+_vm._s(!_vm.showSelect ? 'Switch Site' : 'Cancel')+" "),_c('b-icon',{staticClass:"pl-2",attrs:{"icon":_vm.showSelect ? 'close' : 'chevron-down',"size":"is-small"}})],1)]),(_vm.showSelect)?_c('div',[_c('SearcheableSelect',{attrs:{"size":"is-small","focusOnMount":true,"placeholder":"","dataSource":_vm.sites},on:{"blurred":_vm.handleBlurred,"selected":function (r) { return _vm.handleSelected(r); }}})],1):_vm._e()]):_c('div',{staticClass:"is-size-7"},[_vm._v(" Navigating... ")])]):_vm._e()]),_c('div',{staticClass:"is-justify-content-center is-flex"},[_c('div',{staticClass:"is-flex is-align-items-center"},[_c('b-field',{staticClass:"control"},[_c('b-input',{staticClass:"mm",attrs:{"type":"text","icon":"magnify","placeholder":"Search for something..."}})],1)],1),_c('div',{staticClass:"pl-5 pt-1 is-flex is-align-items-center"},[_c('b-dropdown',{staticStyle:{"cursor":"pointer"},attrs:{"position":"is-bottom-left","append-to-body":"","aria-role":"list"},scopedSlots:_vm._u([{key:"trigger",fn:function(ref){
var active = ref.active;
return [_c('div',{staticClass:"is-flex is-align-items-center"},[_c('p',{staticStyle:{"font-size":"1.3rem"}},[_c('span',{staticClass:"is-align-self-center fi fi-gb mr-1",attrs:{"icon":active}}),_c('span',{staticClass:"is-size-7"},[_vm._v("EN")])])])]}}])},[_c('b-dropdown-item',{attrs:{"aria-role":"listitem"}},[_c('p',{staticStyle:{"font-size":"1.3rem"}},[_c('span',{staticClass:"is-align-self-center fi fi-fr mr-1"}),_c('span',{staticClass:"is-size-7"},[_vm._v("FR")])])]),_c('b-dropdown-item',{attrs:{"aria-role":"listitem"}},[_c('p',{staticStyle:{"font-size":"1.3rem"}},[_c('span',{staticClass:"is-align-self-center fi fi-de mr-1"}),_c('span',{staticClass:"is-size-7"},[_vm._v("DE")])])]),_c('b-dropdown-item',{attrs:{"aria-role":"listitem"}},[_c('p',{staticStyle:{"font-size":"1.3rem"}},[_c('span',{staticClass:"is-align-self-center fi fi-pr mr-1"}),_c('span',{staticClass:"is-size-7"},[_vm._v("PR")])])])],1)],1),_c('div',{staticClass:"pl-5 is-flex is-flex-direction-column is-justify-content-center"},[_c('b-dropdown',{attrs:{"triggers":['hover'],"position":"is-bottom-left","aria-role":"list"},scopedSlots:_vm._u([{key:"trigger",fn:function(ref){
var hover = ref.hover;
return [_c('div',{staticClass:"is-clickable circleBase type1"},[_c('b-image',{attrs:{"src":_vm.useGravatar
                ? _vm.store.userData.gravatar
                : require('@/assets/mp.jpg'),"icon-right":hover,"alt":"Avatar","rounded":true}})],1)]}}])},[_c('div',{staticClass:"dditem py-2",staticStyle:{"width":"240px"},attrs:{"aria-role":"listitem"}},[_c('p',{staticClass:"has-text-grey has-text-weight-bold"},[_vm._v(" "+_vm._s(_vm.store.username)+" ")]),_c('p',{staticClass:"has-text-grey",staticStyle:{"font-size":"13px"}},[_vm._v(" "+_vm._s(_vm.store.userData.email)+" ")])]),_c('hr',{staticClass:"my-1"}),_c('b-dropdown-item',{attrs:{"aria-role":"listitem"},on:{"click":function($event){return _vm.$router.push({
              path: '/us/profile',
              query: { tab: 'preferences' },
            })}}},[_c('p',[_vm._v("Edit Preferences")])]),_c('b-dropdown-item',{attrs:{"aria-role":"listitem"},on:{"click":function($event){return _vm.$router.push({
              path: '/us/profile',
              query: { tab: 'account' },
            })}}},[_c('p',[_vm._v("My Account")])]),_c('b-dropdown-item',{attrs:{"aria-role":"listitem"},on:{"click":function($event){_vm.logoutClicked = true}}},[_vm._v("Log Out")])],1)],1)])]),_c('ConfirmModal',{attrs:{"show":_vm.logoutClicked,"header":"Logout","content":"Are you sure you want to log out?"},on:{"response":_vm.logoutHandler}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('i',{staticClass:"is-clickable mdi mdi-36px mdi-home features-item-icon"},[_c('span',{staticClass:"status"})])}]

export { render, staticRenderFns }