<template>
  <div>
    <div class="card">
      <header class="card-header is-danger p-2">
        <p class="card-header-title is-size-6">
          API Services
          <span class="ml-1 has-text-grey">({{ data.length }}/9)</span>
        </p>
        <button class="card-header-icon" aria-label="more options">
          <span class="icon">
            <i class="mdi mdi-api mdi-40px" aria-hidden="true"></i>
          </span>
        </button>
      </header>
      <b-message
        v-if="data.length !== 0 && data.length < 9"
        style="margin-bottom: 0"
        type="is-danger"
        has-icon
        aria-close-label="Close message"
      >
        API Services are not running as expected.
        <span class="has-text-weight-bold">{{ data.length }}/9</span> services
        are running.
      </b-message>
      <ModifiableTable
        :showHeader="true"
        :tableColumns="columns"
        :tableData="data"
        :modifiable="false"
        :deletable="false"
      />
    </div>

    <br />

    <div class="card">
      <header class="card-header is-danger p-2">
        <p class="card-header-title is-size-6">
          Database Services
          <span class="ml-1 has-text-grey">({{ databaseData.length }}/9)</span>
        </p>
        <button class="card-header-icon" aria-label="more options">
          <span class="icon">
            <i class="mdi mdi-database mdi-40px" aria-hidden="true"></i>
          </span>
        </button>
      </header>
      <b-message
        v-if="data.length !== 0 && databaseData.length < 9"
        style="margin-bottom: 0"
        type="is-danger"
        has-icon
        aria-close-label="Close message"
      >
        Database Services are not running as expected.
        <span class="has-text-weight-bold">{{ databaseData.length }}/9</span>
        services are running.
      </b-message>
      <ModifiableTable
        :showHeader="true"
        :tableColumns="columns"
        :tableData="databaseData"
        :modifiable="false"
        :deletable="false"
      />
    </div>

    <!-- Metadata -->
    <div
      v-if="this.data.length !== 0"
      class="is-size-7 mt-5 has-text-grey has-text-centered"
    >
      Last updated: {{ lastPing || "Just now" }}.
    </div>
  </div>
</template>

<script>
import ModifiableTable from "@/components/ModifiableTable.vue";
import { API, snakeToStartCase } from "@/utils";

const VISIBLE_FIELDS = [
  "no",
  "name",
  "cpu_usage",
  "memory_usage",
  "memory_percentage",
  "network_io",
  "disk_io",
];
export default {
  data() {
    return {
      data: [],
      columns: [],
      databaseData: [],
      lastPing: "",
      pingIntervalId: "",
    };
  },

  methods: {
    processPing(data) {
      this.data = [];
      this.databaseData = [];

      // DB Server
      this.databaseData = data.database.map((d, index) => {
        return {
          ...d,
          no: index + 1,
        };
      });

      // API server
      this.data = data.api.map((d, index) => {
        return {
          ...d,
          no: index + 1,
        };
      });

      if (this.columns.length === 0 && this.data.length !== 0) {
        this.columns = Object.keys(this.data[0]).map((c) => {
          return {
            field: c,
            c,
            sortable: ["no", "cpu_usage", "memory_percentage", "disk_io"].includes(c),
            centered: !["name"].includes(c),
            label:
              c === "network_io"
                ? "Network IO"
                : c === "disk_io"
                ? "Disk IO"
                : c === "name"
                ? "Service Name"
                : snakeToStartCase(c),
            visible: VISIBLE_FIELDS.includes(c),
          };
        });
        // Order columns in expected view
        for (let index = 0; index < VISIBLE_FIELDS.length; index++) {
          let indexInColumns = this.columns
            .map((c) => c.field)
            .indexOf(VISIBLE_FIELDS[index]);
          // `move` is added to Array.prototype in `utils.js`
          this.columns.move(indexInColumns, index);
        }
      }
    },
  },

  async mounted() {
    let response = await API.get("/reports/server");
    if (!response || response.code !== 200) {
      this.isLoading = false;
      return;
    }

    this.pingIntervalId = setInterval(async () => {
      let response = await API.get("/reports/server");
      this.lastPing = new Date().toLocaleString();
      if (response.code === 200 && !response.error.exists) {
        if (response.data.api.length > 0) {
          this.processPing(response.data);
        }
      }
    }, 5000);

    this.processPing(response.data);
  },

  beforeDestroy() {
    clearInterval(this.pingIntervalId);
    this.data = [];
    this.databaseData = [];
  },
  components: { ModifiableTable },
};
</script>

<style scoped>
.tg > .mdi:before,
.mdi-set {
  color: grey;
  font-size: 40px !important;
}
</style>
