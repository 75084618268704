<template>
  <div class="is-flex">
    <ConfirmModal
      header="Logout"
      content="Are you sure you want to log out?"
      @response="(r) => modalResponseHandler(r)"
      :class="{ 'is-hidden': !logoutClicked }"
      :show="logoutClicked"
    />
    <div
      :class="navbarCollapsed ? 'collapse' : null"
      class="side-bar is-flex is-flex-direction-column is-justify-content-space-between"
    >
      <div class="logo-name-wrapper is-justify-content-space-between">
        <div class="logo-name">
          <img
            src="../assets/images/zysod/zysod.png"
            class="logo"
            alt="logo app"
            srcset=""
          />
        </div>
        <span
          @click="toggleNavbar()"
          class="is-align-self-center icon is-clickable"
          aria-hidden=""
          :title="navbarCollapsed ? 'Expand Menu' : 'Collapse Menu'"
          ><i
            style="font-size: 16px"
            :style="navbarCollapsed ? 'padding-right: 1.8rem;' + '' : ''"
            :class="
              navbarCollapsed
                ? 'mdi-chevron-double-right'
                : 'mdi-chevron-double-left'
            "
            class="mdi features-item-icon"
          ></i
        ></span>
      </div>

      <div>
        <ul
          @mouseover="handlerMouseHover"
          @mouseout="handleMouseOut"
          class="features-list"
        >
          <router-link
            v-if="canAccessTab('dashboard')"
            :to="{ name: 'dashboard' }"
          >
            <li
              @click="activeTab = 'dashboard'"
              :class="{ tabactive: activeTab === 'dashboard' }"
              class="features-item is-flex"
            >
              <i
                :style="activeTab === 'dashboard' ? 'color: white' : ''"
                class="mdi mdi-view-dashboard features-item-icon"
                ><span class="status"></span
              ></i>
              <span class="features-item-text">Home</span>
            </li>
          </router-link>
          <router-link v-if="canAccessTab('sites')" :to="{ name: 'sites' }">
            <li
              @click="activeTab = 'sites'"
              :class="{ tabactive: activeTab === 'sites' }"
              class="features-item is-flex"
            >
              <i
                :style="activeTab === 'sites' ? 'color: white' : ''"
                class="mdi mdi-earth-arrow-right features-item-icon"
                ><span class="status"></span
              ></i>
              <span class="features-item-text">Sites</span>
            </li>
          </router-link>

          <router-link v-if="canAccessTab('tickets')" :to="{ name: 'tickets' }">
            <li
              @click="activeTab = 'tickets'"
              :class="{ tabactive: activeTab === 'tickets' }"
              class="features-item is-flex"
            >
              <i
                :style="activeTab === 'tickets' ? 'color: white' : ''"
                class="mdi mdi-tag-plus features-item-icon"
                ><span class="status"></span
              ></i>
              <span class="features-item-text">Tickets</span>
            </li>
          </router-link>

          <router-link v-if="canAccessTab('admin')" :to="{ name: 'admin' }">
            <li
              @click="activeTab = 'admin'"
              :class="{ tabactive: activeTab === 'admin' }"
              class="features-item is-flex"
            >
              <i
                :style="activeTab === 'admin' ? 'color: white' : ''"
                class="mdi mdi-security features-item-icon"
                ><span class="status"></span
              ></i>
              <span class="features-item-text">Admin</span>
            </li>
          </router-link>

          <router-link
            v-if="canAccessTab('monitoring')"
            :to="{ name: 'monitoring' }"
          >
            <li
              @click="activeTab = 'monitoring'"
              :class="{ tabactive: activeTab === 'monitoring' }"
              class="features-item is-flex"
            >
              <i
                :style="activeTab === 'monitoring' ? 'color: white' : ''"
                class="mdi mdi-server-security features-item-icon"
                ><span class="status"></span
              ></i>
              <span class="features-item-text">Monitoring</span>
            </li>
          </router-link>

          <router-link
            v-if="canAccessTab('deployments')"
            :to="{ name: 'deployments' }"
          >
            <li
              @click="activeTab = 'deployments'"
              :class="{ tabactive: activeTab === 'deployments' }"
              class="features-item is-flex"
            >
              <i
                :style="activeTab === 'deployments' ? 'color: white' : ''"
                class="mdi mdi-fridge-industrial features-item-icon"
                ><span class="status"></span
              ></i>
              <span class="features-item-text">Deployments</span>
            </li>
          </router-link>
        </ul>

        <ul
          @mouseover="handlerMouseHover"
          @mouseout="handleMouseOut"
          class="features-list mt-5"
        >
          <!-- <router-link :to="{ name: 'search' }">
            <li
              @click="activeTab = 'search'"
              :class="{ tabactive: activeTab === 'search' }"
              class="features-item is-flex active pt-3"
            >
              <i class="mdi mdi-magnify features-item-icon"
                ><span class="status"></span
              ></i>
              <span class="features-item-text">Search</span>
            </li>
          </router-link> -->

          <router-link v-if="canAccessTab('profile')" :to="{ name: 'profile' }">
            <li
              @click="activeTab = 'profile'"
              :class="{ tabactive: activeTab === 'profile' }"
              class="features-item is-flex"
            >
              <i
                :style="activeTab === 'profile' ? 'color: white' : ''"
                class="mdi mdi-account-settings features-item-icon"
                ><span class="status"></span
              ></i>
              <span class="features-item-text">Profile</span>
            </li>
          </router-link>
<!-- 
          <router-link
            v-if="canAccessTab('notifications')"
            :to="{ name: 'notifications' }"
          >
            <li
              @click="activeTab = 'notifications'"
              :class="{ tabactive: activeTab === 'notifications' }"
              class="features-item is-flex"
            >
              <i
                :style="activeTab === 'notifications' ? 'color: white' : ''"
                class="mdi mdi-bell features-item-icon"
                ><span class="status"></span
              ></i>
              <span class="features-item-text"
                >Notifications
                <span v-if="!navbarCollapsed" class="pl-2"
                  ><b-tag type="is-danger" size="is-small" rounded
                    >2</b-tag
                  ></span
                >
              </span>
            </li>
          </router-link> -->
        </ul>
      </div>

      <div class="is-size-6">
        <li @click="logoutHandler" class="features-item is-flex mb-0 mt-5">
          <i class="mdi mdi-logout features-item-icon"
            ><span class="status"></span
          ></i>
          <span class="features-item-text">Logout</span>
        </li>
      </div>
    </div>
  </div>
</template>

<script>
import { useUserStore } from "@/store";
import { API, permitNavigation } from "@/utils";
import ConfirmModal from "@/components/ConfirmModal.vue";

export default {
  setup() {
    const store = useUserStore();
    return { store };
  },
  watch: {
    $route(to) {
      this.activeTab = this.getActiveTab(to.path);
    },
  },
  data() {
    return {
      activeTab: "dashboard",
      navbarCollapsed: true,
      logoutClicked: false,
      previousNavbarState: false,
    };
  },
  created() {
    this.activeTab = this.getActiveTab(this.$route.fullPath);
    // Get status from user preferences
    this.navbarCollapsed = this.store.preferences.navbar === "collapsed";
  },
  methods: {
    toggleNavbar() {
      this.navbarCollapsed = !this.navbarCollapsed;
    },

    canAccessTab(tab) {
      return permitNavigation(tab, this.store.userRoles);
    },

    getActiveTab(routeFullPath) {
      let fullPath = routeFullPath.split("?")[0].split("/");
      let fullPathLength = fullPath.length;
      const tabIndex =
        fullPathLength > 3 ? fullPathLength - 2 : fullPathLength - 1;

      let activeTab = fullPath[tabIndex];
      activeTab = ["", " ", "us"].includes(activeTab) ? "home" : activeTab;
      return activeTab;
    },
    logoutHandler() {
      this.logoutClicked = true;
    },
    handlerMouseHover() {
      this.previousNavbarState = this.navbarCollapsed;
      this.navbarCollapsed ? (this.navbarCollapsed = false) : null;
    },
    handleMouseOut() {
      // Leave it as you met it
      this.navbarCollapsed = this.previousNavbarState;
    },
    async modalResponseHandler(shouldLogout) {
      if (shouldLogout) {
        let response = await API.get("/logout");
        if (response.error.exists) {
          this.$buefy.snackbar.open({
            message: response.error.message,
            type: "is-danger",
            position: "is-top",
          });
        } else {
          this.store.reset();
          this.$buefy.snackbar.open({
            message: response.data,
            position: "is-top",
          });
          this.$router.push("/authwall");
        }
      }
      this.logoutClicked = false;
    },
  },
  components: { ConfirmModal },
};
</script>

<style scoped>
img {
  height: 2.5rem;
}

.side-bar {
  width: 13rem;
  height: 100%;
  padding: 2.1rem 0.8rem;
  background-color: #17171e;
  /** position: fixed; **/
  transition: all 0.1s ease;
  top: 0;
}

.logo-name-wrapper {
  position: relative;
  margin-bottom: 2.1rem;
  display: flex;
  font-size: 1.2rem;
}

.logo-name {
  display: flex;
  align-items: center;
}

.logo-name__name {
  margin-left: 0.9rem;
  white-space: nowrap;
}

.logo-name__button {
  position: absolute;
  top: 50%;
  right: 0;
  font-size: 1.8rem;
  transform: translateY(-50%);
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.logo-name__icon {
  font-size: 1.8rem;
  color: var(--grey-color);
}

.message {
  background-color: var(--dark-grey-color);
  display: flex;
  align-items: center;
  padding: 1.55rem 0 1.55rem 1.2rem;
  border-radius: 0.4rem;
  margin-bottom: 3.6rem;
  cursor: pointer;
  position: relative;
}

.message-icon {
  font-size: 2rem;
  transform: translateX(3rem);
  transition: all 0.5s ease;
}

.message-text {
  margin-left: 1.1rem;
  font-size: 1.4rem;
  white-space: nowrap;
  transform: translateX(3rem);
  transition: all 0.5s ease;
}

.features-list:not(:last-child) {
  padding-bottom: 1rem;
  border-bottom: 1px solid #292932;
  list-style: none;
}

.features-item {
  display: flex;
  align-items: center;
  position: relative;
  padding: 0.3rem 0.2rem;
  margin-bottom: 1.1rem;
  color: #b5b5be;
  cursor: pointer;
  transition: 0.5s ease;
}

.features-item:hover {
  color: inherit;
  transition: 0.3s ease;
}

.features-item:hover::before {
  opacity: 1;
  visibility: unset;
}

.tabactive::before {
  opacity: 1;
  visibility: unset;
  transition: 0.5s ease;
  content: "";
  position: absolute;
  left: -1.4rem;
  height: 100%;
  border-left: 4px solid white;
}

.tabactive {
  font-weight: bold;
  color: white;
}

.features-item-icon {
  font-size: 1.8rem;
  color: #b5b5be;
  display: flex;
}

.features-item-text {
  margin-left: 1.2rem;
  transition: opacity 0.6s ease;
  opacity: 1;
}

.mdi:before,
.mdi-set {
  display: inline-block;
  font: normal normal normal 24px/1 "Material Design Icons";
  text-rendering: auto;
  line-height: inherit;
  font-size: 22px !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: inherit;
}

.side-bar.collapse .message:hover .tooltip,
.features-item:hover .tooltip {
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.3s ease;
  opacity: 1;
  height: 100%;
}

.side-bar.collapse {
  width: 4.6rem;
  padding: inherit 0.8rem;
}

.side-bar.collapse .tooltip {
  display: block;
}

.side-bar.collapse .logo-name,
.side-bar.collapse .category-header,
.side-bar.collapse .chat-header,
.side-bar.collapse .chat-new-btn-text,
.side-bar.collapse .features-item-text,
.side-bar.collapse .inbox-number,
.side-bar.collapse .category-item-text,
.side-bar.collapse .category-item-number,
.side-bar.collapse .message-text,
.side-bar.collapse .chat-item-name,
.side-bar.collapse .chat-item-number,
.side-bar.collapse .chat-item-status {
  opacity: 0;
  pointer-events: none;
}

.side-bar.collapse .message-text {
  opacity: 0;
  pointer-events: none;
  transform: translateX(0);
}

.side-bar.collapse .message-icon {
  transform: translateX(0);
}

.side-bar.collapse .logo-name__button {
  right: 0.5rem;
}

.side-bar.collapse .inbox-icon .status,
.side-bar.collapse .chat-item-avatar-wrapper::before {
  opacity: 1;
}

.mdi:before {
  display: inline-block;
  font: normal normal normal 24px/1 "Material Design Icons";
  text-rendering: auto;
  line-height: inherit;
  font-size: 21px !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: inherit;
}
</style>
