<template>
  <div class="main is-flex is-flex-direction-column is-justify-content-space-between">

    <!-- Main top bar -->
    <Topbar />

    <!-- Component/Route should be passed here -->
    <router-view></router-view>

    <!-- Footer -->
    <Footer />

  </div>
</template>

<script>
import Topbar from '../components/Topbar.vue'
import Footer from '../components/Footer.vue'
export default {
  data() {
    return {
      activeTab: "map",
    }
  },
  methods: {
    updateActiveTab(selected) {
      this.activeTab = selected;
    }
  },
  components: { Topbar, Footer }
}
</script>

<style scoped>
.main {
  flex: 1 1 auto;
  height: 100vh;
}
</style>