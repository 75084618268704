<template>
  <div class="is-flex-grow-2">
    <div class="card">
      <header class="card-header is-danger p-2">
        <p class="card-header-title is-size-6">Traffic Data</p>
        <button class="card-header-icon" aria-label="more options">
          <span class="icon">
            <i class="mdi mdi-information-outline mdi-40px" aria-hidden="true"></i>
          </span>
        </button>
      </header>
      <div
        class="card-content is-flex is-align-items-center is-justify-content-center"
        style="height: 500px"
      >
        <div class="is-flex is-flex-direction-column pb-6">
          <div class="is-flex is-align-items-center is-justify-content-center">
            <div class="is-flex">
              <p class="is-align-self-center tg">
                <i
                  class="mdi mdi-finance mdi-40px"
                  aria-hidden="true"
                ></i>
              </p>
            </div>
          </div>
          <p class="is-size-6 has-text-grey">
            RF Traffic data for this site is currently unavailable.
          </p>
        </div>
      </div>
    </div>

    <!-- Metadata -->
    <div class="is-size-7 mt-5 has-text-grey has-text-centered">
      Last updated: Just now.
    </div>
  </div>
</template>

<script>
import { useUserStore } from "@/store";
// import { API, snakeToStartCase } from "@/utils";

export default {
  setup() {
    const store = useUserStore();
    return { store };
  },
  data() {
    return {
      site: {},
      isLoading: false,
    };
  },
};
</script>
<style scoped>
.tg > .mdi:before,
.mdi-set {
  color: grey;
  font-size: 40px !important;
}
</style>
