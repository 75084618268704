<template>
  <div class="columns">
    <div class="column is-4">
      <div class="card">
        <header
          class="card-header is-align-items-center py-2 is-danger pl-2 pr-5"
        >
          <p
            class="card-header-title has-text-weight-bold is-size-6 is-flex is-align-content-center"
          >
            Accessible Devices<span class="ml-1 has-text-grey">({{ sites.length }})</span>
          </p>
          <p>
            <b-button
              outlined
              type="is-dark"
              icon-left="refresh"
              label="Refresh"
              @click="fetchSites"
            ></b-button>
          </p>
        </header>
        <div class="card-content my-2 py-3 px-3">
          <b-loading
            :is-full-page="false"
            v-model="isLoading"
            :can-cancel="false"
          ></b-loading>
          <div
            class="is-flex is-flex-direction-column m-5 pb-6 pt-5 is-justify-content-center is-align-items-center"
            v-if="sites.length === 0"
          >
            <p class="is-align-self-center tg">
              <i class="mdi mdi-lan-disconnect mdi-40px" aria-hidden="true"></i>
            </p>
            <p class="is-size-6 pb-5 has-text-grey">
              No devices are live that can be remotely accessed
            </p>
          </div>
          <ul
            v-else
            style="max-height: 80vh; min-height: 20vh; overflow-y: auto"
            class="is-flex is-flex-direction-column"
          >
            <li
              class="py-4 px-3 list-separated-item"
              v-for="site in sites"
              :key="site.site_id"
            >
              <div
                class="is-flex has-text-grey is-align-self-center is-justify-content-space-between"
              >
                <div class="is-flex is-flex-direction-column">
                  <router-link
                    class="is-capitalized is-size-6"
                    :to="{
                      name: 'site',
                      params: { name: site.name, uuid: site.site_id },
                    }"
                  >
                    {{ site.name }}
                  </router-link>

                  <div
                    class="is-clickable is-flex is-align-content-center is-size-7"
                  >
                    <span
                      ><i
                        :class="{
                          'mdi-fridge-industrial-outline':
                            site.device_type === 'david' ? true : false,
                          'mdi-toggle-switch-variant-off':
                            site.device_type === 'power_device' ? true : false,
                        }"
                        class="mdi"
                        aria-hidden="true"
                      ></i>
                    </span>
                    <span class="is-capitalized is-align-self-center">{{
                      snakeToStartCase(site.device_type)
                    }}</span>
                  </div>
                </div>
                <div class="is-align-self-center">
                  <b-button
                    @click="viewHandler(site)"
                    icon-left="eye-arrow-right"
                    type="is-info is-light"
                    inverted
                  >
                    <span class="is-size-7">View</span>
                  </b-button>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div
      v-if="currentSite === ''"
      class="column pt6 is-flex is-flex-grow-1 is-justify-content-center is-align-items-center"
    >
      <div class="is-flex is-align-items-center is-justify-content-center">
        <div class="is-flex is-flex-direction-column">
          <div class="is-flex is-align-items-center is-justify-content-center">
            <div class="is-flex">
              <p class="is-align-self-center tg">
                <!-- <i class="mdi mdi-robot mdi-40px" aria-hidden="true"></i> -->
                <i
                  class="mdi mdi-lan mdi-40px"
                  aria-hidden="true"
                ></i>
              </p>
            </div>
          </div>
          <p class="is-size-5 has-text-grey">
            Remotely access and control the deployed devices
          </p>
        </div>
      </div>
    </div>

    <AccessView
      :siteName="currentSite"
      :siteDetails="currentSiteDetails"
      v-else
    />
  </div>
</template>

<script>
import { useUserStore } from "@/store";
import AccessView from "./AccessView.vue";
import { API, snakeToStartCase } from "@/utils";

export default {
  setup() {
    const store = useUserStore();
    return { store };
  },
  data() {
    return {
      sites: [],

      currentSite: "",
      isLoading: false,
      currentSiteDetails: {},
    };
  },
  methods: {
    snakeToStartCase(input) {
      return snakeToStartCase(input);
    },

    viewHandler(site) {
      this.currentSite = site.name;
      this.currentSiteDetails = site;
    },

    async fetchSites() {
      this.isLoading = true;
      let response = await API.get("/remote-access-ports/active");
      if (
        response.code === 200 &&
        !response.error.exists &&
        response.data.length !== 0
      ) {
        this.sites = response.data;
      }
      this.isLoading = false;
    },
  },
  created() {
    this.fetchSites();
  },
  components: { AccessView },
};
</script>

<style scoped>
.columns {
  height: 100%;
}

.list-separated-item {
  border-top: 1px solid rgba(0, 40, 100, 0.12);
}

.list-separated-item:not(.is-selected):nth-child(even) {
  background-color: rgb(17 127 241 / 2%) !important;
}

.list-separated-item:first-child {
  padding-top: 0;
  border-top: none;
}
.tg > .mdi:before,
.mdi-set {
  color: grey;
  font-size: 40px !important;
}

span > .mdi:before,
.mdi-set {
  font-size: 14px !important;
}
</style>
