<template>
  <div class="columns">
    <div class="column is-4">
      <div style="overflow-x: auto" class="card">
        <header class="card-header is-danger p-2">
          <p class="card-header-title is-size-6">Deployment Details</p>
          <button class="card-header-icon" aria-label="more options">
            <span class="icon">
              <i
                class="mdi mdi-information-outline mdi-40px"
                aria-hidden="true"
              ></i>
            </span>
          </button>
        </header>
        <div class="card-content p-4">
          <b-table
            class="has-text-grey"
            :striped="true"
            :hoverable="true"
            :loading="isLoading"
            :data="data"
            :columns="columns"
          >
          </b-table>
        </div>
      </div>
    </div>
    <div class="column is-8">
      <l-map style="height: 100%; z-index: 1" :zoom="zoom" :center="center">
        <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
        <l-marker ref="marker" :lat-lng="latLng">
          <l-popup ref="popup">
            <span class="is-capitalized">
              {{ site.name }}
            </span>
          </l-popup>
        </l-marker>
      </l-map>
    </div>
  </div>
</template>

<script>
import { useUserStore } from "@/store";
import { LMap, LTileLayer, LMarker, LPopup } from "vue2-leaflet";
import { Icon } from "leaflet";
import { API, snakeToStartCase } from "@/utils";

delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

export default {
  components: {
    LMap,
    LTileLayer,
    LPopup,
    LMarker,
  },
  setup() {
    const store = useUserStore();
    return { store };
  },
  data() {
    return {
      site: {},
      isLoading: false,
      columns: [
        { field: "key", label: "Detail" },
        { field: "value", label: "Value" },
      ],
      data: [],
      latLng: [0, 0],
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      attribution:
        '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      zoom: 6.4,
      center: [6.72234, 6.33355],
    };
  },
  mounted() {
    this.$nextTick(() => {
      setTimeout(() => {
        if (this.$refs.marker) {
          this.$refs.marker.mapObject.openPopup();
        }
      }, 1000);
    });
  },
  async created() {
    this.isLoading = true;
    let response = await API.get(`/site/${this.$route.params.uuid}`);
    if (!response.error.exists) {
      this.site = response.data;
      this.latLng = [this.site.latitude, this.site.longitude];

      this.data = Object.keys(this.site)
        .filter((v) => !v.endsWith("_id") && !v.endsWith("uuid") && !['alarms_enabled', 'trafficable'].includes(v))
        .map((k) => {
          let detail = this.site[k];
          if (detail && typeof detail === "object") {
            detail = detail.name;
          }
          if (!detail) detail = "N/A";
          return {
            key:
              k === "pd_type"
                ? "Power Device Type"
                : k === "number"
                ? "Site Number"
                : snakeToStartCase(k),
            value: snakeToStartCase(detail),
          };
        });

      this.store.updateCurrentSite(this.site.name);
    }
    this.isLoading = false;
  },
};
</script>
<style scoped>
.columns {
  height: 100%;
}
</style>
