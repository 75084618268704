<template>
  <div class="columns">
    <div class="column is-3">
      <div class="card">
        <header class="card-header is-danger p-2">
          <p class="card-header-title is-size-6">Deployment Configuration</p>
          <button class="card-header-icon" aria-label="more options">
            <span class="icon">
              <i class="mdi mdi-cog mdi-40px" aria-hidden="true"></i>
            </span>
          </button>
        </header>
        <div class="card-content py-3 px-0">
          <ul
            style="max-height: 80vh; min-height: 20vh; overflow-y: auto"
            class="is-flex is-flex-direction-column features-list"
          >
            <li
              @click="activeTab = 'templates'"
              :class="{ tabactive: activeTab === 'templates' }"
              class="py-4 mx-0 px-3 list-separated-item"
            >
              <div class="is-clickable is-flex is-align-content-center">
                <i class="mdi mdi-window-shutter-cog features-item-icon pl-2"
                  ><span class="status"></span
                ></i>
                <span class="features-item-text is-align-self-center pl-2"
                  >Alarm Templates</span
                >
              </div>
            </li>
            <li
              @click="activeTab = 'thresholds'"
              :class="{ tabactive: activeTab === 'thresholds' }"
              class="py-4 mx-0 px-3 list-separated-item"
            >
              <div class="is-clickable is-flex is-align-content-center">
                <i class="mdi mdi-bell-cog features-item-icon pl-2"
                  ><span class="status"></span
                ></i>
                <span class="features-item-text is-align-self-center pl-2"
                  >Site Alarm Thresholds</span
                >
              </div>
            </li>
            <li
              @click="activeTab = 'scripts'"
              :class="{ tabactive: activeTab === 'scripts' }"
              class="py-4 mx-0 px-3 list-separated-item"
            >
              <div class="is-clickable is-flex is-align-content-center">
                <i class="mdi mdi-folder-cog features-item-icon pl-2"
                  ><span class="status"></span
                ></i>
                <span class="features-item-text is-align-self-center pl-2"
                  >Deployment Scripts</span
                >
              </div>
            </li>
            <li
              @click="activeTab = 'server'"
              :class="{ tabactive: activeTab === 'server' }"
              class="py-4 mx-0 px-3 list-separated-item"
            >
              <div class="is-clickable is-flex is-align-content-center">
                <i class="mdi mdi-wrench-cog features-item-icon pl-2"
                  ><span class="status"></span
                ></i>
                <span class="features-item-text is-align-self-center pl-2"
                  >Server Configurations</span
                >
              </div>
            </li>
            <li
              @click="activeTab = 'database'"
              :class="{ tabactive: activeTab === 'database' }"
              class="py-4 mx-0 px-3 list-separated-item"
            >
              <div class="is-clickable is-flex is-align-content-center">
                <i class="mdi mdi-database-cog features-item-icon pl-2"
                  ><span class="status"></span
                ></i>
                <span class="features-item-text is-align-self-center pl-2"
                  >Database Snapshots</span
                >
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <AlarmTemplates v-show="activeTab === 'templates'" />
    <AlarmThresholds v-show="activeTab === 'thresholds'" />
    <DeploymentScripts v-show="activeTab === 'scripts'" />
    <ServerConfiguration v-show="activeTab === 'server'" />
    <DatabaseConfiguration v-if="activeTab === 'database'" />
  </div>
</template>

<script>
import { useUserStore } from "@/store";
import AlarmTemplates from "./AlarmTemplates.vue";
import AlarmThresholds from "./AlarmThresholds.vue";
import DeploymentScripts from "./DeploymentScripts.vue";
import ServerConfiguration from "./ServerConfiguration.vue";
import DatabaseConfiguration from "./DatabaseConfiguration.vue";

export default {
  setup() {
    const store = useUserStore();
    return { store };
  },
  data() {
    return {
      activeTab: "templates",
      searchTerm: "",
      isLoading: false,
      isEditingSite: false,
      currentEditingSiteUuid: "",
      currentDeletingSiteUuid: "",
      showDeleteSiteModal: false,
      showRegisterSiteModal: false,
      deleteSiteConfirmAgainst: "",
    };
  },
  components: {
    AlarmThresholds,
    DeploymentScripts,
    AlarmTemplates,
    ServerConfiguration,
    DatabaseConfiguration
},
};
</script>

<style scoped>
.tabactive {
  background-color: #f5f7fb !important;
  border-left: 5px solid #467fcf !important;
}
.list-separated-item {
  border-top: 1px solid rgba(0, 40, 100, 0.12);
  cursor: pointer;
}

.list-separated-item:first-child {
  padding-top: 0;
  border-top: none;
}
</style>
