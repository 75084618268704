<template>
  <!-- Permisions Table -->
  <div class="columns">
    <div class="column">
      <div class="card">
        <header class="card-header is-danger p-2">
          <p class="card-header-title is-size-6">User Action Logs<span class="ml-1 has-text-grey">({{ data.length }})</span></p>

          <p class="control">
            <b-datepicker
              v-model="startDate"
              icon="calendar-today"
              position="is-bottom-left"
              placeholder="Choose start date"
            >
            </b-datepicker>
          </p>
          <p class="control pl-3">
            <b-datepicker
              v-model="endDate"
              icon="calendar-today"
              position="is-bottom-left"
              placeholder="Choose end date"
            >
            </b-datepicker>
          </p>
        </header>

        <ModifiableTable
          :modifiable="false"
          :deletable="false"
          :showHeader="true"
          :tableData="data"
          :tableColumns="columns"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ModifiableTable from "@/components/ModifiableTable.vue";
import { API, snakeToStartCase } from "@/utils";
// import moment from 'moment';
// import { DEFAULT_UI_FORMAT } from "@/constants";
// The order of these fields matter
// as the table uses it as the order
// of the columns displayed. All later
// visible fields are rendered after
const visibleFields = [
  "no",
  "time_stamp",
  "action",
  "resource",
  "desc",
  "user",
  //   "data",
];

export default {
  components: {
    ModifiableTable,
  },
  data() {
    const max = new Date();
    max.setDate(max.getDate());

    const min = new Date();
    min.setDate(max.getDate() - 7);

    return {
      data: [],
      columns: [],
      startDate: min,
      endDate: max,
    };
  },
  methods: {},

  async mounted() {
    let response = await API.get("/action-logs");
    if (!response.error.exists) {
      this.data = response.data.items.map((d, index) => {
        return {
          ...d,
          no: index + 1,
          // desc: "Triggered By",
        };
      });

      if (this.columns.length === 0) {
        this.columns = Object.keys(this.data[0])
          .filter((c) => !["uuid"].includes(c))
          .map((c) => {
            return {
              visible: c !== "data",
              sortable: c === "no",
              centered: c !== "time_stamp",
              label:
                c === "desc"
                  ? ""
                  : c === "user"
                  ? "Triggered By"
                  : snakeToStartCase(c),
              field: c === "user" ? "do-no-cap" : c,
            };
          });

        for (let index = 0; index < visibleFields.length; index++) {
          let indexInColumns = this.columns
            .map((c) => c.field)
            .indexOf(visibleFields[index]);
          this.columns.move(indexInColumns, index);
        }
      }
    }
  },
};
</script>
