<template>
  <div
    style="min-height: 40px; background-color: #f5f7fb"
    class="card is-size-7 px-5 has-text-grey is-fullwidth is-align-items-center is-justify-content-space-between is-flex"
  >
    <footer class="is-fullwidth is-align-self-center is-align-items-center">
      <div class="has-text-centered">
        <p>
          Network Operations and Control. Ⓒ {{ currentYear }}, Zysod Communications Limited.
        </p>
      </div>
    </footer>
    <footer class="is-fullwidth is-align-items-space-evenly is-flex">
      <div class="has-text-centered">
        <p>
          <a href="https://www.zysod.net/privacy" target="_blank"
            >Privacy Policy</a
          >
        </p>
      </div>
      <div class="has-text-centered pl-4">
        <p>|</p>
      </div>
      <div class="has-text-centered is-clickable pl-4">
        <p>
          <a href="https://www.zysod.net/privacy" target="_blank"
            >Cookie Policy</a
          >
        </p>
      </div>
      <!-- <div class="has-text-centered pl-4">
        <p>|</p>
      </div>
      <div class="has-text-centered pl-4">
        <p>Hash: e3br5yury78sdd</p>
      </div> -->
    </footer>
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentYear: new Date().getFullYear(),
    };
  },
};
</script>

<style scoped></style>
