<template>
  <div class="is-fullwidth is-flex is-flex-direction-column is-flex-grow-2">
    <div class="tabs is-left is-full is-size-7">
      <ul>
        <li
          class="mx-0 mr-4"
          @click="updateActiveTab('companies')"
          :class="{ 'is-active': activeTab === 'companies' }"
        >
          <a>
            <span class="icon is-small"
              ><i class="mdi mdi-domain" aria-hidden="true"></i
            ></span>
            <span>Companies</span>
          </a>
        </li>
        <li
          class="mx-0 mr-4"
          @click="updateActiveTab('users')"
          :class="{ 'is-active': activeTab === 'users' }"
        >
          <a>
            <span class="icon is-small"
              ><i class="mdi mdi-account-multiple" aria-hidden="true"></i
            ></span>
            <span>Users</span>
          </a>
        </li>
        <li
          class="mx-0 mr-4"
          @click="updateActiveTab('access_control')"
          :class="{ 'is-active': activeTab === 'access_control' }"
        >
          <a>
            <span class="icon is-small"
              ><i class="mdi mdi-shield-lock" aria-hidden="true"></i
            ></span>
            <span>Access Control</span>
          </a>
        </li>
        <li
          class="mx-0 mr-4"
          @click="updateActiveTab('networks')"
          :class="{ 'is-active': activeTab === 'networks' }"
        >
          <a>
            <span class="icon is-small"
              ><i class="mdi mdi-signal-variant" aria-hidden="true"></i
            ></span>
            <span>Networks</span>
          </a>
        </li>
      </ul>
    </div>
    <div class="is-flex-grow-2 py-4">
      <Companies v-if="activeTab === 'companies'" />
      <Users v-if="activeTab === 'users'" />
      <Networks v-if="activeTab === 'networks'" />
      <Acl v-if="activeTab === 'access_control'" />
    </div>
  </div>
</template>

<script>
import { useUserStore } from "@/store";
import { permitNavigation, snakeToStartCase } from "@/utils";
import Acl from "./Acl/Acl.vue";
import Companies from "./Companies/Companies.vue";
import Networks from "./Networks/Networks.vue";
import Users from "./Users/Users.vue";

const module = "/ Admin";

// import Sites from "./Sites/Devices.vue";
// import Configuration from "./Configuration/Configuration.vue";
// import RemoteAccess from "./RemoteAccess/RemoteAccess.vue";

export default {
  setup() {
    const store = useUserStore();
    return { store };
  },
  data() {
    return {
      activeTab: "users",
    };
  },
  created() {
    if (!permitNavigation("admin", this.store.userRoles)) {
      this.$router.push("/us/profile");
      return;
    }
    // Get status from user preferences
    this.activeTab = this.store.preferences.adminPage;
    this.store.updateTopBar(`${module} / ${snakeToStartCase(this.activeTab)}`);
  },
  methods: {
    updateActiveTab(selected) {
      this.activeTab = selected;
      this.store.updateTopBar(
        `${module} / ${snakeToStartCase(this.activeTab)}`
      );
    },
  },
  components: { Companies, Users, Networks, Acl },
};
</script>

<style scoped>
.tabs:not(:last-child) {
  /* eslint-disable-line no-mixed-spaces-and-tabs */
  margin-bottom: 0rem !important;
  /** eslint-disable-line no-mixed-spaces-and-tabs **/
}

.table {
  overflow-y: scroll;
}

a {
  color: #4a4a4a !important;
}
</style>
