<template>
  <section>
    <b-modal width="25vw" :active="show">
      <div class="card">
        <header class="card-header">
          <p class="card-header-title is-size-6 is-capitalized">
            {{ header }}
          </p>
        </header>
        <div class="card-content">
          <div class="content">
            {{ confirmContent }}
          </div>
          <div v-if="showNameConfirmation" class="content">
            <b-field label="Confirm Deletion">
              <b-input type="text" v-model="nameConfirmation" icon="alert"
                :placeholder="`Type ${confirmAgainst.toLocaleUpperCase()} in CAPS to confirm`">
              </b-input>
            </b-field>
          </div>
        </div>
        <footer class="card-footer">
          <b-button @click="confirmHandler(false)" type="is-light"
            class="is-radiusless card-footer-item">Cancel</b-button>
          <b-button @click="confirmHandler(true)" type="is-link" class="is-radiusless card-footer-item">Confirm</b-button>
        </footer>
      </div>
    </b-modal>
  </section>
</template>

<script>
export default {
  props: {
    show: {
      default: false,
      type: Boolean
    },
    header: String,
    content: String,
    placeholder: String,
    confirmAgainst: String,
  },
  data() {
    return {
      showNameConfirmation: false,
      nameConfirmation: '',
      confirmContent: '',
    }
  },
  mounted() {
    this.confirmContent = this.content;
  },
  methods:
  {
    confirmHandler(response) {
      if (!response) {
        this.resetState();
        this.$emit('response', response);
        return
      }
      // User has already confirmed
      if (this.showNameConfirmation) {
        // Input should be in upper case
        if (this.nameConfirmation === this.confirmAgainst.toLocaleUpperCase()) {
          this.$emit('response', response);
          this.resetState();
        }
        else {
          this.$buefy.snackbar.open({
            message: "Validation failed for the characters typed in",
            position: "is-top",
            type: "is-danger"
          })
        }
      }
      // User has just now confirmed
      else {
        this.showNameConfirmation = true;
        this.confirmContent = "You need to confirm before proceeding."
      }

    },
    resetState() {
      this.confirmContent = this.content;
      this.nameConfirmation = '';
      this.showNameConfirmation = false;
    }
  }
}
</script>