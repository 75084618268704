<template>
  <div>
    <apexchart
      :height="500"
      type="line"
      :options="options"
      :series="series"
    ></apexchart>
  </div>
</template>

<script>
export default {
  props: {
    categories: {
      type: Array,
      default: () => [],
    },
    series: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    categories(nc) {
      this.options = {
        ...this.options,
        xaxis: {
          categories: nc,
          title: {
            text: "Timestamp",
          },
        },
      };
    },
  },
  data() {
    return {
      options: {
        chart: {
          id: "site-alarm-data",
        },
        annotations: {
          yaxis: [
            {
              y: 7,
              y2: 10,
              borderColor: "#000",
              fillColor: "#fe3e19",
              opacity: 0.2,
              label: {
                borderColor: "#333",
                style: {
                  fontSize: "10px",
                  color: "#333",
                  background: "#FEB019",
                },
                text: "Alarm Trigger Range",
              },
            },
          ],
        },
        xaxis: {
          categories: this.categories,
          title: {
            text: "Timestamp",
          },
        },
        dataLabels: {
          enabled: false,
        },
        markers: {
          size: 4,

          shape: "circle",
          radius: 2,
        },
        stroke: {
          show: true,
          curve: "straight",
          width: 3,
          dashArray: 0,
        },
        yaxis: {
          title: {
            text: "Trigger Frequency",
          },
          max: 11,
          min: -2,
        },
      },
    };
  },
};
</script>
<style scoped>
.tg > .mdi:before,
.mdi-set {
  color: grey;
  font-size: 40px !important;
}
</style>
