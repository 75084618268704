<template>
  <section>
    <b-modal :width="768" v-if="show" :active="show">
      <div class="column">
        <div class="card">
          <header class="card-header">
            <p
              class="card-header-title is-capitalzed is-size-6 my-2 has-text-weight-bold"
            >
              Edit Access Control for {{ user.first_name || user.email }} {{ user.last_name }}
            </p>
            <button class="card-header-icon" aria-label="more options">
              <span class="icon">
                <i
                  class="mdi mdi-information-outline mdi-40px"
                  aria-hidden="true"
                ></i>
              </span>
            </button>
          </header>

          <div>
            <b-message v-if="errorMessage != ''" type="is-danger" has-icon>
              {{ errorMessage }}
            </b-message>
          </div>

          <div class="card-content pb-1">
            <b-loading
              :is-full-page="false"
              v-model="isLoading"
              :can-cancel="true"
            ></b-loading>

            <div class="content pb-5">
              <b-field
                label="Enter or remove the desired roles for the user (maximum of three)"
              >
                <b-taginput
                  v-model="userRoles"
                  :data="filteredRoles"
                  autocomplete
                  :openOnFocus="true"
                  :allowNew="false"
                  field="name"
                  icon="label"
                  type="is-dark"
                  maxtags="3"
                  placeholder="Add a new role for the user"
                  @typing="getFilteredRoles"
                >
                </b-taginput>
              </b-field>
              <pre
                style="max-height: 400px"
              ><b>ACL: &nbsp;</b>{{ userRoles.map(r => {
              return {description: r.description, permissions: `Permitted to perform ${r.permissions.length} actions on the NMS`}
            }) }}</pre>
            </div>
          </div>

          <footer class="card-footer">
            <b-button
              style="height: 50px"
              @click="$emit('response', false)"
              type="is-light"
              class="is-radiusless card-footer-item"
              >Cancel</b-button
            >
            <b-button
              style="height: 50px"
              :loading="confirmLoading"
              @click="confirmHandler"
              type="is-link"
              class="is-radiusless card-footer-item"
              >Save</b-button
            >
          </footer>
        </div>
      </div>
    </b-modal>
  </section>
</template>

<script>
import { API, snakeToStartCase } from "@/utils";

export default {
  props: {
    show: {
      default: false,
      type: Boolean,
    },
    user: Object,
  },

  data() {
    return {
      isLoading: false,
      confirmLoading: false,
      enabled: true,

      errorMessage: "",

      name: "",
      roles: [],
      userRoles: [],
      filteredRoles: [],
    };
  },
  watch: {
    userRoles() {
      if (this.userRoles.filter((r) => r.name === "User").length === 0) {
        this.errorMessage =
          "All users require the User role for basic account access";
      } else {
        this.errorMessage = "";
      }
    },
  },
  async mounted() {
    await this.fetchRoles();
    // Populate the field with the user's current roles
    this.userRoles = this.roles.filter((r) =>
      this.user.role_groups.find((rg) => r.name === snakeToStartCase(rg.name))
    );
  },
  methods: {
    async fetchRoles() {
      this.isLoading = true;
      let response = await API.get("/roles");
      if (![200].includes(response.code)) {
        this.errorMessage = response.error.message;
        this.isLoading = false;
        return;
      }
      let roles = response.data.items
        // Cannot change role to client roles
        .filter((r) => !["mno_user", "third_party_user"].includes(r.name))
        .map((r) => {
          return { ...r, name: snakeToStartCase(r.name) };
        });
      this.roles = roles;
      this.filteredRoles = roles;
      this.isLoading = false;
    },
    getFilteredRoles(text) {
      this.filteredRoles = this.roles.filter((role) => {
        return (
          role.name.toString().toLowerCase().indexOf(text.toLowerCase()) >= 0
        );
      });
    },
    async confirmHandler() {
      this.confirmLoading = true;
      let error = this.errorMessage;

      let validSelection = this.roles.filter((r) =>
        this.userRoles.map((ur) => ur.uuid).includes(r.uuid)
      );

      if (validSelection.length <= 0) {
        error = "The selected roles are invalid";
      }

      if (error !== "") {
        this.errorMessage = error;
        this.confirmLoading = false;
        return;
      }

      this.confirmLoading = false;
      const requiredRoles = this.userRoles.map((r) => r.uuid);
      let response = await API.put(`/user/${this.user.uuid}`, {
        data: { changes: [{ name: "role_groups", value: requiredRoles }] },
      });
      this.confirmLoading = false;

      if (response.code === 200 && !response.error.exists) {
        this.$emit("response", true);
      } else {
        this.errorMessage = response.error.message;
      }
    },
  },
};
</script>
<style scoped>
.field {
  margin-bottom: 1.4rem !important;
}
</style>
