<template>
  <div>
    <div class="columns">
      <div class="column is-12">
        <div class="card">
          <header class="card-header is-danger p-2">
            <p class="card-header-title is-size-6">Registered Users <span class="ml-1 has-text-grey">({{ data.length }})</span></p>
            <p class="is-flex is-align-items-center">
              <b-button
                @click="registerClicked = !registerClicked"
                class="mr-3 px-5"
                aria-label="add user"
                :type="`${
                  !registerClicked ? 'is-info is-light' : 'is-light is-danger'
                }`"
                outlined
                :icon-left="registerClicked ? 'cancel' : 'account-plus'"
              >
                {{ registerClicked ? "Cancel" : "Register User" }}
              </b-button>
            </p>
          </header>
          <b-loading
            :is-full-page="false"
            v-model="isLoading"
            :can-cancel="true"
          ></b-loading>
          <div
            v-if="!dataPresent"
            class="card-content is-flex is-align-items-center is-justify-content-center"
            style="height: 500px"
          >
            <div v-if="!isLoading" class="is-flex is-flex-direction-column">
              <div
                class="is-flex is-align-items-center is-justify-content-center"
              >
                <div class="is-flex">
                  <p class="is-align-self-center tg">
                    <i
                      class="mdi mdi-account-remove-outline mdi-40px"
                      aria-hidden="true"
                    ></i>
                  </p>
                </div>
              </div>
              <p class="is-size-6 has-text-grey">
                No users have been registered.
              </p>
            </div>
          </div>
          <div v-show="dataPresent">
            <UsersTable
              :showHeader="true"
              :tableData="data"
              @deleteUser="(r) => deleteUser(r)"
              @roleUpdated="fetchUsers()"
              @activateOrDeactivate="(r) => activateOrDeactivate(r)"
              @resendRegistrationEmail="
                (email) => resendRegistrationEmail(email)
              "
            />
          </div>
        </div>
      </div>
    </div>

    <div v-if="!aboutToEdit"></div>

    <RegisterUser
      @response="(r) => registerUserHandler(r)"
      :show="registerClicked"
      v-if="registerClicked"
    />
  </div>
</template>

<script>
import UsersTable from "./UsersTable.vue";
import { useUserStore } from "@/store";
import { API } from "@/utils";
import RegisterUser from "./RegisterUser.vue";

export default {
  setup() {
    const store = useUserStore();
    return { store };
  },
  data() {
    return {
      data: [],
      dataPresent: false,
      editClicked: false,
      registerClicked: false,
      columns: [],
      isLoading: false,
      aboutToEdit: false,
      aboutToEditData: {},
      aboutToDelete: false,
      aboutToDeleteData: {},
    };
  },
  methods: {
    async resendRegistrationEmail(email) {
      let response = await API.post("/tasks", {
        data: {
          request_type: "",
          task_type: "resend_registration_email",
          request_body: {
            email: email,
          },
        },
      });

      if (response.error.exists || response.code !== 200) {
        this.$buefy.snackbar.open({
          message: response.error.message,
          type: "is-danger",
          pauseOnHover: false,
          position: "is-top",
        });
      } else {
        this.$buefy.snackbar.open({
          message: response.data,
          type: "is-success",
          pauseOnHover: false,
          position: "is-bottom",
        });
      }
    },

    async activateOrDeactivate(data) {
      let response = await API.put(`/user/${data.uuid}`, {
        data: {
          changes: [{ name: "is_active", value: !data.status }],
        },
      });

      if (response.error.exists || response.code !== 200) {
        this.$buefy.snackbar.open({
          message: response.error.message,
          type: "is-danger",
          pauseOnHover: false,
          position: "is-top",
        });
      } else {
        this.$buefy.snackbar.open({
          message: "User successfully updated",
          type: "is-success",
          pauseOnHover: false,
          position: "is-bottom",
        });
        this.fetchUsers();
      }
    },

    registerUserHandler(successful) {
      if (successful) {
        this.registerClicked = !successful;
        this.$buefy.snackbar.open({
          message: "User successfully created",
          type: "is-success",
          pauseOnHover: false,
          position: "is-bottom",
        });
        this.fetchUsers();
      } else {
        this.registerClicked = successful;
      }
    },

    async deleteUser(uuid) {
      // Delete user request
      let response = await API.delete(`/user/${uuid}`);
      if (response.error.exists || response.code !== 200) {
        this.$buefy.snackbar.open({
          message: response.error.message,
          type: "is-danger",
          pauseOnHover: false,
          position: "is-top",
        });
      } else {
        this.$buefy.snackbar.open({
          message: "User successfully deleted",
          type: "is-success",
          pauseOnHover: false,
          position: "is-bottom",
        });
        this.fetchUsers();
      }
    },

    async fetchUsers() {
      this.isLoading = true;
      this.data = [];

      let response = await API.get("/users");
      this.dataPresent = response.data.items_count > 0;
      if (!response.error.exists && this.dataPresent) {
        this.data = response.data.items.map((c, index) => {
          return {
            ...c,
            no: index + 1,
          };
        });
      }

      this.isLoading = false;
    },
  },

  async mounted() {
    this.siteId = this.$route.params.uuid;
    await this.fetchUsers();
  },

  components: {
    UsersTable,
    RegisterUser,
  },
};
</script>
<style scoped>
.tg > .mdi:before,
.mdi-set {
  color: grey;
  font-size: 40px !important;
}
</style>
