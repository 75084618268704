<template>
  <div class="column">
    <div class="card">
      <header class="card-header is-danger p-2">
        <p class="card-header-title has-text-weight-normal is-size-6">
          Configure the settings for the NMS
        </p>
      </header>
      <b-loading
        :is-full-page="false"
        v-model="isLoading"
        :can-cancel="true"
      ></b-loading>
      <div>
        <TableWithEditModal
          :showHeader="true"
          :deletable="false"
          :tableData="data"
          :tableColumns="columns"
          @editRowData="(r) => editHandler(r)"
        />
      </div>
    </div>
    <EditServerConfiguration
      v-if="aboutToEdit"
      :show="aboutToEdit"
      :uuid="aboutToEditData.uuid"
      @response="(r) => editServerConfigurationHandler(r)"
    />
  </div>
</template>

<script>
import TableWithEditModal from "@/components/ModifiableTable.vue";
import { API, snakeToStartCase } from "@/utils";
import EditServerConfiguration from "./EditServerConfiguration.vue";

const EXCLUDE_PARAMS = ["uuid"];
const visibleFields = ["no", "name", "value", "description"];

export default {
  data() {
    return {
      data: [],
      dataPresent: false,
      columns: [],
      isLoading: false,
      aboutToEdit: false,
      aboutToEditData: {},
    };
  },
  methods: {
    snakeToStartCase(v) {
      return snakeToStartCase(v);
    },
    editServerConfigurationHandler(successful) {
      if (successful) {
        this.aboutToEditData = {};
        this.aboutToEdit = !successful;
        this.$buefy.snackbar.open({
          message: "Configuration successfully updated",
          type: "is-success",
          pauseOnHover: false,
          position: "is-bottom",
        });
        this.fetchConfig();
      } else {
        this.aboutToEdit = successful;
      }
    },
    editHandler(rowData) {
      this.aboutToEditData = rowData;
      this.aboutToEdit = true;
    },

    async fetchConfig() {
      this.isLoading = true;
      this.data = [];

      let response = await API.get("/configurations");
      this.dataPresent = response.data.items_count > 0;
      if (!response.error.exists && this.dataPresent) {
        this.data = response.data.items.map((c, index) => {
          return {
            ...c,
            no: index + 1,
          };
        });

        this.columns = this.dataPresent
          ? Object.keys(this.data[0])
              .filter((c) => !EXCLUDE_PARAMS.includes(c))
              .map((c) => {
                return {
                  field: c === "value" ? "tagValue" : c,
                  sortable: c === "no",
                  visible: c !== "description",
                  centered: c === "value",
                  label: snakeToStartCase(c),
                };
              })
          : [];

        // Order columns in expected view
        for (let index = 0; index < visibleFields.length; index++) {
          let indexInColumns = this.columns
            .map((c) => c.field)
            .indexOf(visibleFields[index]);

          // `move` is added to Array.prototype in `utils.js`
          this.columns.move(indexInColumns, index);
        }
      }

      this.isLoading = false;
    },
  },

  async mounted() {
    await this.fetchConfig();
  },

  components: {
    TableWithEditModal,
    EditServerConfiguration,
  },
};
</script>
<style scoped>
.tg > .mdi:before,
.mdi-set {
  color: grey;
  font-size: 40px !important;
}
</style>
