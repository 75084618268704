<template>
  <l-map style="height: 100%; z-index: 1" :zoom="zoom" :center="center">
    <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
    <l-marker
      :key="site.uuid"
      v-for="site in sites"
      :lat-lng="[site.latitude, site.longitude]"
    >
      <l-popup>
        <span class="is-capitalized">
          <router-link
            :to="{ name: 'site', params: { uuid: site.uuid, name: site.name } }"
          >
            {{ site.name }}
          </router-link>
        </span>
      </l-popup>
    </l-marker>
  </l-map>
</template>

<script>
import { LMap, LTileLayer, LMarker, LPopup } from "vue2-leaflet";
import { Icon } from "leaflet";
import { API } from "@/utils";

delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});
export default {
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
  },
  data() {
    return {
      sites: [],
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      attribution:
        '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      zoom: 6.4,
      center: [9.72234, 7.33355],
    };
  },
  async created() {
    let response = await API.get("/sites");
    if (!response.error.exists) {
      this.sites = response.data.items;
    }
  },
};
</script>
