<template>
  <b-autocomplete ref="autocomplete" :title="title" :disabled="disabled" :icon-right="iconRight" v-model="searched"
    :data="filteredDataSource" expanded :field="field" :icon="icon" :clearable="clearable" append-to-body
    :custom-formatter="formatter" :openOnFocus="true" :placeholder="placeholder" :size="size" @select="d => selectedHandler(d)"
    @focus="(d) => focusHandler(d)" @blur="v => inputHandler(v)">
    <template #empty>No results found</template>
  </b-autocomplete>
</template>

<script>
import { snakeToStartCase } from '@/utils';

export default {

  props: {
    dataSource: {
      default: () => [],
      type: Array
    },
    placeholder: {
      default: "Select an item",
      type: String
    },
    focusOnMount: {
      default: false,
      type: Boolean
    },
    disabled: {
      default: false,
      type: Boolean
    },
    size: {
      default: "",
      type: String
    },
    title: {
      default: "",
      type: String
    },
    defaultChoice: {
      default: () => { },
      type: Object
    },
    field: {
      type: String,
      default: "name"
    },
    clearable: {
      type: Boolean,
      default: true
    },
    icon: {
      type: String,
      default: ""
    },
    iconRight: {
      type: String,
      default: ""
    },
    formatter: {
      type: Function,
      default: (s) => snakeToStartCase(s.name)
    },
  },
  data() {
    return {
      searched: "",
      reset: false,
    };
  },

  mounted() {
    if (this.focusOnMount) {
      this.$refs.autocomplete.focus();
    }
  },

  watch: {
    defaultChoice(v) {
      let shouldSet = typeof v === "object" ? Object.keys(v).length > 0 : v.length;
      if (shouldSet) {
        this.$refs.autocomplete.setSelected(v);
      }
    }
  },

  methods: {
    selectedHandler(choice) {
      if (choice) {
        this.$emit("selected", choice)
      }
    },
    inputHandler(v) {
      if (v) {
        this.$emit("blurred")
      }
    },
    focusHandler() {
      // When focused, ensure
      // the dropdown shows all
      // possible options for easy
      // selection
      // if (e.isTrusted) {
      //   if (this.searched !== "") {
      //     this.reset = true;
      //   }
      //   else {
      //     this.reset = false;
      //   }
      // }
    }
  },

  computed: {
    filteredDataSource() {
      if (this.disabled || this.dataSource.length <= 0) {
        return []
      }

      if (this.reset) {
        return this.dataSource;
      }

      let sourceType = typeof this.dataSource[0];
      return this.dataSource.filter(d => {
        // TODO this should filter across all keys
        let f = sourceType === "string" ? d : d[this.field];
        return (
          f.toString()
            .toLowerCase()
            .indexOf(this.searched.toLowerCase()) >= 0
        );
      });
    },
  },
};
</script>

<style scoped></style>
