<template>
  <div class="is-fullhd">
    <NmsHome v-if="isAuthenticated" />
  </div>
</template>

<script>
import { useUserStore } from "@/store";
import NmsHome from "./NmsHome.vue";
import { API } from "@/utils";

export default {
  name: "NmsApp",
  components: {
    NmsHome,
  },
  data() {
    return {
      isAuthenticated: false,
    };
  },
  async created() {
    // Check token in local storage
    let response = await API.get("/profile");

    if (!response || response.code !== 200) {
      this.isAuthenticated = false;
      if (this.$route.name !== "authwall");
      {
        this.$router.push({ name: "authwall" });
      }
      this.$buefy.snackbar.open({
        message: "You need to login before accessing that page",
        position: "is-top",
      });
      return;
    }

    // Profile has been gotten
    this.store.updateUserDetails(response.data);
    this.store.updateAuth(true);
    this.isAuthenticated = true;
    this.$router.push("/us/dashboard");
  },

  setup() {
    const store = useUserStore();
    return { store };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

.is-vcentered {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  /* if an only child */
  flex-direction: column;
  justify-content: center;
}
</style>
