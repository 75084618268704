<template>
  <div class="column">
    <ConfirmModal :show="showConfirmModal" header="Generate Database Snapshot"
      @response="(c) => generateConfirmModalResponse(c)"
      content="This will generate a database snapshot for the selected sites and within the duration selected. Are you sure you want to continue?" />

    <div class="card">
      <header class="card-header is-danger p-2">
        <p class="card-header-title has-text-weight-normal is-size-6">
          Extract snapshots from the Data Warehouse
        </p>
        <p class="is-align-self-center pr-4">
          <b-button outlined :type="creatingSnapshot ? 'is-danger' : 'is-dark'" title="Create a new database snapshot"
            :icon-left="creatingSnapshot ? 'close' : 'plus'"
            :label="creatingSnapshot ? 'Back to Snapshots' : 'Create Snapshot'" @click="handleCreateSnapshot"></b-button>
        </p>
      </header>
      <b-loading :is-full-page="false" v-model="isLoading" :can-cancel="true"></b-loading>
      <div>
        <div v-if="creatingSnapshot">
          <div class="pt-5 is-flex is-justify-content-center is-align-items-center">
            <div class="is-centered">
              <b-datepicker icon="calendar" placeholder="Start date" v-model="startDate" :min-date="minDate"
                :date-formatter="(date) => new Intl.DateTimeFormat('en-GB', {
                  dateStyle: 'short',
                  timeStyle: 'long',
                  timeZone: 'UTC',
                }).format(date)">
              </b-datepicker>

            </div>
            <div class="px-4">───</div>
            <div class="is-centered">
              <b-datepicker icon="calendar" :min-date="minDate" placeholder="End date" v-model="endDate" :date-formatter="(date) => new Intl.DateTimeFormat('en-GB', {
                dateStyle: 'short',
                timeStyle: 'long',
                timeZone: 'UTC',
              }).format(date)">
              </b-datepicker>
            </div>
          </div>

          <div>
            <div class="p-3">
              <div style="min-height: 20vh; overflow: auto">
                <hr v-show="sites.length !== 0" />
                <div v-if="sites.length !== 0" class="card-content pt-3">
                  <div class="is-flex is-flex-wrap-wrap is-justify-content-space-between ml-6">
                    <div v-for="site in sites" :key="site.uuid" class="sitebox">
                      <b-field>
                        <b-checkbox v-model="selectedSites" :native-value="site.name">
                          {{ site.displayName }}
                        </b-checkbox>
                      </b-field>
                    </div>
                  </div>
                </div>
                <div class="is-flex is-justify-content-center">
                  <div class="py-3 px-2" v-if="selectedSites.length !== 0">
                    <b-button :loading="generatingSnapsot" style="height: 50px; width: 30vw;" type="is-link"
                      icon-left="database-plus" @click="showConfirmModal = true;">Generate for {{
                        selectedSites.length }}
                      {{
                        selectedSites.length === 1 ? "site" : "sites"
                      }}</b-button>
                  </div>
                </div>

              </div>
            </div>

          </div>
        </div>

        <div v-else>
          <div v-if="tasks.length !== 0">
            <div class="card-content p-4 pb-5">
              <b-message class="mb-3" v-if="currentTaskID !== ''" :duration="8000" type="is-success" has-icon>
                Snapshot task with ID {{ currentTaskID }} has been started successfuly. Downloading will be enabled once it is
                complete.</b-message>
              <b-table style="color: grey" class="has-text-grey" :data="tasks.length === 0 ? [] : tasks" :hoverable="true"
                :striped="true">
                <b-table-column cell-class="tdd" width="40" field="no" label="No" sortable v-slot="props">
                  <span class="py-4">
                    <span class="is-capitalized">
                      {{ snakeToStartCase(props.row.no) }}
                    </span>
                  </span>
                </b-table-column>

                <b-table-column centered cell-class="tdd" width="250" label="Task ID" field="task_id" v-slot="props">
                  <span class="py-4">
                    <span class="is-capitalized">
                      {{ props.row.task_id }}
                    </span>
                  </span>
                </b-table-column>

                <b-table-column centered cell-class="tdd" field="status" label="Status" v-slot="props">
                  <span class="py-4 is-capitalized">
                    {{ props.row.status }}
                  </span>
                </b-table-column>

                <b-table-column centered cell-class="tdd" field="output" label="Output" v-slot="props">
                  <span class="py-4">
                    {{ props.row.output }}
                  </span>
                </b-table-column>

                <b-table-column centered cell-class="tdd" width="200" field="status" label="" v-slot="props">
                  <span class="py-4">
                    <b-button outlined style="height: 26px;" type="is-dark" title="Download the database snapshot"
                      :disabled="props.row.url === ''" icon-left="download" label="Download"
                      @click="downloadSnapshot(props.row.url)"></b-button>
                  </span>
                </b-table-column>

                <template #empty>
                  <div class="p-3 has-text-centered">
                    <span v-show="!isLoading">No records to display.</span>
                  </div>
                </template>
              </b-table>
            </div>
          </div>
          <div v-else class="is-flex is-justify-content-center is-align-items-center" style="min-height: 300px">
            <div class="is-flex is-flex-direction-column">
              <div class="is-flex is-align-items-center is-justify-content-center">
                <div class="is-flex">
                  <p class="is-align-self-center tg">
                    <i class="mdi mdi-database mdi-40px" aria-hidden="true"></i>
                  </p>
                </div>
              </div>
              <p class="is-size-6 pb-5 has-text-grey">
                No database snapshots have been created. Create one using the above button.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ConfirmModal from "@/components/ConfirmModal.vue";
import { API, snakeToStartCase } from "@/utils";

const days = 24 * 60 * 60 * 1000;
const today = new Date();
today.setHours(0, 0, 0);

const sevenDaysAgo = new Date(Date.now() - 7 * days)
sevenDaysAgo.setHours(0, 0, 0);

export default {
  data() {
    return {
      sites: [],
      selectedSites: [],
      tasks: [],
      taskColumns: [],
      endDate: today,
      startDate: sevenDaysAgo,
      minDate: new Date("2023/07/01"),
      creatingSnapshot: false,
      isLoading: false,
      currentTaskID: "",
      generatingSnapsot: false,
      showConfirmModal: false,
    };
  },
  watch: {
    startDate(n) {
      if (n >= this.endDate) {
        let newEndDate = new Date;
        newEndDate.setDate(n.getDate() + 1);
        newEndDate.setHours(0, 0, 0);
        this.endDate = newEndDate;
      }
    },
    endDate(n) {
      if (n <= this.startDate) {
        let newStartDate = new Date();
        newStartDate.setDate(n.getDate() - 1);
        newStartDate.setHours(0, 0, 0);
        this.startDate = newStartDate;
      }
    }
  },
  methods: {
    handleCreateSnapshot() {
      this.creatingSnapshot = !this.creatingSnapshot;
      if (!this.creatingSnapshot) {
        this.selectedSites = [];
      }
    },
    async fetchSites() {
      this.isLoading = true;
      let response = await API.get("/sites", {
        data: { device_type: "david" }
      });
      if (!response.error.exists) {
        this.sites = response.data.items.map((s) => {
          return {
            ...s,
            displayName: snakeToStartCase(s.name),
          };
        });
      }
      this.isLoading = false;
    },
    downloadSnapshot(url) {
      window.location.href = url;
    },
    snakeToStartCase(v) {
      return snakeToStartCase(v);
    },
    async generateConfirmModalResponse(confirmed) {
      this.currentTaskID = "";
      this.showConfirmModal = false;
      if (!confirmed) {
        return;
      }
      this.generatingSnapsot = true;
      let response = await API.post("/tasks", {
        data: {
          request_type: "",
          task_type: "generate_db",
          request_body: {
            sites: this.selectedSites,
            start_date: this.startDate.toISOString().slice(0, 10),
            end_date: this.endDate.toISOString().slice(0, 10),
          },
        },
      });
      if (response.code === 200 && !response.error.exists) {
        await this.fetchSnapshotTasks();
        this.selectedSites = [];
        this.currentTaskID = response.data.task_id;
      }
      this.creatingSnapshot = false;
      this.generatingSnapsot = false;
    },
    async fetchSnapshotTasks() {
      let response = await API.get("/tasks/snapshots");
      if (response.code === 200 && !response.error.exists) {
        this.tasks = response.data.map((task, index) => {
          return {
            ...task,
            no: index + 1
          };
        });
      }
    }
  },
  async mounted() {
    this.isLoading = true;
    await this.fetchSnapshotTasks();
    await this.fetchSites();
    this.isLoading = false;
  },
  components: { ConfirmModal }
};
</script>
<style scoped>
.tabs:not(:last-child) {
  /* eslint-disable-line no-mixed-spaces-and-tabs */
  margin-bottom: 0rem !important;
  /** eslint-disable-line no-mixed-spaces-and-tabs **/
}

.tg>.mdi:before,
.mdi-set {
  color: grey;
  font-size: 40px !important;
}

.sitebox {
  width: 160px;
  height: 35px;
}
</style>
