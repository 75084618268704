<template>
  <div>
    <section
      v-if="canReset"
      class="section is-flex is-flex-direction-column is-align-items-center is-align-content-center is-justify-content-center"
    >
      <img class="mb-5" alt="Vue logo" src="@/assets/images/zysod/zysod.png" />

      <Notification
        v-if="showErrorNotification"
        @x-clicked="showErrorNotification = false"
        :text="errorMessage"
      />

      <div class="card is-align-self-center">
        <header class="card-header p-2">
          <p class="card-header-title is-size-6">Reset your Password</p>
          <button class="card-header-icon" aria-label="more options">
            <span class="icon">
              <i class="mdi mdi-lock-reset mdi-40px" aria-hidden="true"></i>
            </span>
          </button>
        </header>

        <div class="card-content">
          <b-field class="is-size-7 mb-4" label="Email Address">
            <b-input disabled v-model="email" type="email" icon="at"> </b-input>
          </b-field>
          <b-field label="Your password" class="mb-4">
            <b-input
              required
              type="password"
              password-reveal
              onkeydown="return /[0-9a-zA-Z.,@&_-$%(){}\[\]:;]/i.test(event.key)"
              v-model="password"
              placeholder="A secure password"
              icon="lock"
            >
            </b-input>
          </b-field>
          <b-field label="Your password, again" class="mb-4">
            <b-input
              required
              type="password"
              onkeydown="return /[0-9a-zA-Z.,@&_-$%(){}\[\]:;]/i.test(event.key)"
              password-reveal
              v-model="password2"
              placeholder="The same secure password"
              icon="lock"
            >
            </b-input>
          </b-field>
          <b-field class="pt-4 pb-2">
            <b-button
              class="vbtn"
              expanded
              type="is-info"
              :loading="buttonLoading"
              @click="resetHandler"
              >Reset Password</b-button
            >
          </b-field>
        </div>
      </div>
    </section>

    <NotFound v-else />
  </div>
</template>

<script>
import { API } from "@/utils";
import Notification from "@/components/Notification.vue";
import NotFound from "@/components/NotFound.vue";

export default {
  components: {
    Notification,
    NotFound
},
  data() {
    return {
      email: "",
      password: "",
      password2: "",
      errorMessage: "",

      buttonLoading: false,
      showErrorNotification: false,
      canReset: false,
      accountData: {},
      token: "",
      uuid: "",
    };
  },
  async created() {
    this.accountCreated = false;
    this.canReset = false;
    let token = this.$router.currentRoute.query.token;

    if (!token) {
      return;
    }

    // Check token from query params
    this.token = token;
    let response = await API.get(`/authwall/reset?token=${this.token}`);

    // Bad token
    if (!response || response.code === 400) {
      this.canReset = false;
      return;
    }

    // Register user
    if (response.code === 200) {
      this.canReset = true;
      this.email = response.data.email;
    }
  },
  methods: {
    toggleLoading() {
      this.buttonLoading = !this.buttonLoading;
    },

    async resetHandler() {
      let error = "";
      this.errorMessage = "";
      this.showErrorNotification = false;
      this.toggleLoading();

      if (this.password.length < 5 || this.password2.length < 5) {
        error = "The passwords should be at least six characters";
      } else if (this.password !== this.password2) {
        error = "The passwords do not match.";
      }

      if (error !== "") {
        this.errorMessage = error;
        this.showErrorNotification = true;
        this.toggleLoading();
        return;
      }

      let response = await API.post(`/authwall/reset?token=${this.token}`, {
        data: {
          password: this.password.trim(),
        },
      });

      if (response.code !== 200 || response.error.exists) {
        this.errorMessage = response.error.message;
        this.showErrorNotification = true;
        this.toggleLoading();
        return;
      }

      // Redirect to login
      this.$router.push("/authwall");
      this.$buefy.snackbar.open({
        message:
          "Your password has been successfully updated. You can log in now.",
        type: "is-success",
        pauseOnHover: false,
        position: "is-top",
        duration: 10000,
      });
    },
  },
};
</script>

<style scoped>
.section {
  height: 100vh;
}

img {
  height: 3.5rem;
}
.vbtn {
  background-color: #2d73d4;
  color: white;
  text-transform: uppercase;
  height: 55px;
  font-weight: bold;
  font-size: 0.86rem;
}
.card {
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 5%);
  margin-bottom: 1.5rem;
  width: 20vw;
  min-width: 22rem;
}

.tg > .mdi:before,
.mdi-set {
  color: grey;
  font-size: 40px !important;
}
</style>
