// import the fingerprintjs opensource library
import FingerprintJS from '@fingerprintjs/fingerprintjs';

export const API_BASE_URL = `${window.location.protocol}//134.209.28.241` //'http://127.0.0.1:5555';
// export const API_BASE_URL = `${window.location.protocol}//nms.zysod.net` //'http://127.0.0.1:5555';
export const REMOTE_SET_COMMANDS = ["set_parameter"] //, "add_daily_cron_job", "remove_daily_cron_job"];
export const DAVID_COMMANDS = ["get_alarms", "get_frequencies", "set_parameter", "get_parameter_settings", "disable_rf_switches", "enable_rf_switches", "get_working_parameters"];

// Server DateTime util class equivalent
export const DEFAULT_FORMAT = "YYYY-MM-DD HH:mm:ss.SSSSSSZ"
export const DEFAULT_SQLITE_FORMAT = "YYYY-MM-DD HH:mm:ss"
export const DEFAULT_UI_FORMAT = "MMM Do YYYY. HH:mm:ss ZZZ" // May 14th 2023. 18:30:24 UTC

export const DAVID_PARAMETERS = [
    "gsm_rf_switch",
    "dcs_rf_switch",
    "wcdma_rf_switch",
    "lte_rf_switch",
    "gsm_uplink_attentuation",
    "gsm_downlink_attentuation",
    "dcs_uplink_attentuation",
    "dcs_downlink_attentuation",
    "wcdma_uplink_attentuation",
    "wcdma_downlink_attentuation",
    "lte_uplink_attentuation",
    "lte_downlink_attentuation",
    "downlink_vswr_threshold",
    "pa_temp_threshold",
    "downlink_input_power_low_threshold",
    "downlink_input_power_high_threshold",
    "downlink_output_power_low_threshold",
    "downlink_output_power_high_threshold",
    "self_excitation_threshold"
]

export const ALL_DEFAULT_ROLES = [
    "engineer",
    "support",
    "super_admin",
    "admin",
    "spectator",
    "mno_user",
    "user",
    "third_party_user",
];

export const getFingerprintHash = async () => {
    const fp = await FingerprintJS.load();
    const { visitorId } = await fp.get();
    return visitorId;
};

let fp;
(async () => {
    fp = await getFingerprintHash();
    await localStorage.setItem('fpHash', fp);
})();
