var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-4"},[_c('div',{staticClass:"card"},[_c('header',{staticClass:"card-header is-align-items-center py-2 is-danger pl-2 pr-5"},[_c('p',{staticClass:"card-header-title has-text-weight-bold is-size-6 is-flex is-align-content-center"},[_vm._v(" Accessible Devices"),_c('span',{staticClass:"ml-1 has-text-grey"},[_vm._v("("+_vm._s(_vm.sites.length)+")")])]),_c('p',[_c('b-button',{attrs:{"outlined":"","type":"is-dark","icon-left":"refresh","label":"Refresh"},on:{"click":_vm.fetchSites}})],1)]),_c('div',{staticClass:"card-content my-2 py-3 px-3"},[_c('b-loading',{attrs:{"is-full-page":false,"can-cancel":false},model:{value:(_vm.isLoading),callback:function ($$v) {_vm.isLoading=$$v},expression:"isLoading"}}),(_vm.sites.length === 0)?_c('div',{staticClass:"is-flex is-flex-direction-column m-5 pb-6 pt-5 is-justify-content-center is-align-items-center"},[_vm._m(0),_c('p',{staticClass:"is-size-6 pb-5 has-text-grey"},[_vm._v(" No devices are live that can be remotely accessed ")])]):_c('ul',{staticClass:"is-flex is-flex-direction-column",staticStyle:{"max-height":"80vh","min-height":"20vh","overflow-y":"auto"}},_vm._l((_vm.sites),function(site){return _c('li',{key:site.site_id,staticClass:"py-4 px-3 list-separated-item"},[_c('div',{staticClass:"is-flex has-text-grey is-align-self-center is-justify-content-space-between"},[_c('div',{staticClass:"is-flex is-flex-direction-column"},[_c('router-link',{staticClass:"is-capitalized is-size-6",attrs:{"to":{
                    name: 'site',
                    params: { name: site.name, uuid: site.site_id },
                  }}},[_vm._v(" "+_vm._s(site.name)+" ")]),_c('div',{staticClass:"is-clickable is-flex is-align-content-center is-size-7"},[_c('span',[_c('i',{staticClass:"mdi",class:{
                        'mdi-fridge-industrial-outline':
                          site.device_type === 'david' ? true : false,
                        'mdi-toggle-switch-variant-off':
                          site.device_type === 'power_device' ? true : false,
                      },attrs:{"aria-hidden":"true"}})]),_c('span',{staticClass:"is-capitalized is-align-self-center"},[_vm._v(_vm._s(_vm.snakeToStartCase(site.device_type)))])])],1),_c('div',{staticClass:"is-align-self-center"},[_c('b-button',{attrs:{"icon-left":"eye-arrow-right","type":"is-info is-light","inverted":""},on:{"click":function($event){return _vm.viewHandler(site)}}},[_c('span',{staticClass:"is-size-7"},[_vm._v("View")])])],1)])])}),0)],1)])]),(_vm.currentSite === '')?_c('div',{staticClass:"column pt6 is-flex is-flex-grow-1 is-justify-content-center is-align-items-center"},[_vm._m(1)]):_c('AccessView',{attrs:{"siteName":_vm.currentSite,"siteDetails":_vm.currentSiteDetails}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',{staticClass:"is-align-self-center tg"},[_c('i',{staticClass:"mdi mdi-lan-disconnect mdi-40px",attrs:{"aria-hidden":"true"}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"is-flex is-align-items-center is-justify-content-center"},[_c('div',{staticClass:"is-flex is-flex-direction-column"},[_c('div',{staticClass:"is-flex is-align-items-center is-justify-content-center"},[_c('div',{staticClass:"is-flex"},[_c('p',{staticClass:"is-align-self-center tg"},[_c('i',{staticClass:"mdi mdi-lan mdi-40px",attrs:{"aria-hidden":"true"}})])])]),_c('p',{staticClass:"is-size-5 has-text-grey"},[_vm._v(" Remotely access and control the deployed devices ")])])])}]

export { render, staticRenderFns }