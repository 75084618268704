<template>
  <section>
    <article class="notification p-3 is-danger is-light is-flex is-justify-content-space-between">
      <div class="media">
        <div class="media-left"><span class="icon is-medium ml-0" aria-hidden=""><i
              class="mdi mdi-alert-circle mdi-40px"></i></span>
        </div>
        <div class="media-content ml-1 is-size-6"> {{ text }} </div>
      </div><!---->
      <span @click="$emit('x-clicked')" class="is-align-self-center icon is-medium is-clickable" aria-hidden=""><i
              class="mdi mdi-close mdi-40px"></i></span>
    </article>
</section>
</template>

<script>

export default {
  props: {
    type: {
      type: String,
      default: "is-danger"
    },
    icon: {
      type: String,
      default: "alert"
    },
    text: String,
  }
}
</script>
<style scoped>
.icon.is-large {
    height: 2rem;
    width: 2rem;
}
.notification {
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 5%);
  /* position: relative; */
  margin-bottom: 1.5rem;
  display: flex;
  width: 20vw;
  min-width: 22rem;
}

.notification>.delete {
  align-self: center;
  display: flex;
  position: sticky;
  right: 0.5rem;
}
</style>