<template>
  <div class="is-fullwidth is-flex is-flex-direction-column is-flex-grow-2">
    <div class="tabs is-left is-full is-size-7 mb-0">
      <ul>
        <li
          class="mx-0 mr-4"
          @click="updateActiveTab('logs')"
          :class="{ 'is-active': activeTab === 'logs' }"
        >
          <a>
            <span class="icon is-small"
              ><i class="mdi mdi-file-document-outline" aria-hidden="true"></i
            ></span>
            <span>Logs</span>
          </a>
        </li>
        <li
          class="mx-0 mr-4"
          @click="updateActiveTab('server_health')"
          :class="{ 'is-active': activeTab === 'server_health' }"
        >
          <a>
            <span class="icon is-small"
              ><i class="mdi mdi-server" aria-hidden="true"></i
            ></span>
            <span>Server Health</span>
          </a>
        </li>
      </ul>
    </div>

    <div class="is-flex-grow-2 py-4">
      <UserLog v-if="activeTab === 'logs'" />
      <ServerHealth v-if="activeTab === 'server_health'" />
    </div>
  </div>
</template>

<script>
import { useUserStore } from "@/store";
import UserLog from "./UserLog.vue";
import { snakeToStartCase, permitNavigation } from "@/utils";
import ServerHealth from "./ServerHealth.vue";
const module = "/ Monitoring";

export default {
  setup() {
    const store = useUserStore();
    return { store };
  },
  created() {
    if (!permitNavigation("monitoring", this.store.userRoles)) {
      this.$router.push("/us/profile");
      return;
    }
  },
  data() {
    return {
      lastPing: "",
      activeTab: "logs",
    };
  },
  methods: {
    updateActiveTab(selected) {
      this.activeTab = selected;
      this.store.updateTopBar(
        `${module} / ${snakeToStartCase(this.activeTab)}`
      );
    },
  },
  async mounted() {
    this.activeTab = this.store.preferences.monitoringPage;
    this.store.updateTopBar(`${module} / ${snakeToStartCase(this.activeTab)}`);
  },
  components: { UserLog, ServerHealth },
};
</script>
<style scoped>
.tg > .mdi:before,
.mdi-set {
  color: grey;
  font-size: 40px !important;
}
</style>
