<template>
  <section>
    <b-modal :width="768" v-if="show" :active="show">
      <div class="column">
        <div class="card">
          <header class="card-header">
            <p class="card-header-title is-size-6 my-2 has-text-weight-bold">
              Register a new Network
            </p>
            <button class="card-header-icon" aria-label="more options">
              <span class="icon">
                <i class="mdi mdi-plus mdi-40px" aria-hidden="true"></i>
              </span>
            </button>
          </header>
          <div>
            <b-message v-if="errorMessage != ''" type="is-danger" has-icon>
              {{ errorMessage }}
            </b-message>
          </div>
          <div class="card-content pb-1">
            <div class="content">
              <div class="columns pb-5">
                <div class="column">
                  <b-field class="is-capitalized" label="Network Name">
                    <b-input
                      icon="label"
                      expanded
                      v-model="name"
                      placeholder="e.g. Safaricom"
                    ></b-input>
                  </b-field>
                  <b-field class="is-capitalized" label="Gsm Uplink">
                    <b-input
                      expanded
                      icon="numeric"
                      type="number"
                      step="any"
                      v-model="gsmUplink"
                      placeholder="e.g. 1234.5"
                    ></b-input>
                    <p class="control">
                      <span class="button is-static">Hz</span>
                    </p>
                  </b-field>
                  <b-field class="is-capitalized" label="Dcs Uplink">
                    <b-input
                      expanded
                      icon="numeric"
                      type="number"
                      step="any"
                      v-model="dcsUplink"
                      placeholder="e.g. 1234.5"
                    ></b-input>
                    <p class="control">
                      <span class="button is-static">Hz</span>
                    </p>
                  </b-field>
                  <b-field class="is-capitalized" label="Wcdma Uplink">
                    <b-input
                      expanded
                      icon="numeric"
                      type="number"
                      step="any"
                      v-model="wcdmaUplink"
                      placeholder="e.g. 1234.5"
                    ></b-input>
                    <p class="control">
                      <span class="button is-static">Hz</span>
                    </p>
                  </b-field>
                  <b-field class="is-capitalized" label="lte Uplink">
                    <b-input
                      expanded
                      icon="numeric"
                      type="number"
                      step="any"
                      v-model="lteUplink"
                      placeholder="e.g. 1234.5"
                    ></b-input>
                    <p class="control">
                      <span class="button is-static">Hz</span>
                    </p>
                  </b-field>
                </div>

                <div class="column">
                  <b-field label="Marker Color">
                    <b-colorpicker expanded v-model="color" />
                  </b-field>
                  <b-field class="is-capitalized" label="gsm Downlink">
                    <b-input
                      icon="numeric"
                      type="number"
                      step="any"
                      expanded
                      v-model="gsmDownlink"
                      placeholder="e.g. 1234.5"
                    ></b-input>
                    <p class="control">
                      <span class="button is-static">Hz</span>
                    </p>
                  </b-field>
                  <b-field class="is-capitalized" label="dcs Downlink">
                    <b-input
                      icon="numeric"
                      type="number"
                      step="any"
                      expanded
                      v-model="dcsDownlink"
                      placeholder="e.g. 1234.5"
                    ></b-input>
                    <p class="control">
                      <span class="button is-static">Hz</span>
                    </p>
                  </b-field>
                  <b-field class="is-capitalized" label="wcdma Downlink">
                    <b-input
                      icon="numeric"
                      type="number"
                      step="any"
                      expanded
                      v-model="wcdmaDownlink"
                      placeholder="e.g. 1234.5"
                    ></b-input>
                    <p class="control">
                      <span class="button is-static">Hz</span>
                    </p>
                  </b-field>
                  <b-field class="is-capitalized" label="lte Downlink">
                    <b-input
                      icon="numeric"
                      type="number"
                      step="any"
                      expanded
                      v-model="lteDownlink"
                      placeholder="e.g. 1234.5"
                    ></b-input>
                    <p class="control">
                      <span class="button is-static">Hz</span>
                    </p>
                  </b-field>
                </div>
              </div>
            </div>
          </div>
          <footer class="card-footer">
            <b-button
              style="height: 50px"
              @click="$emit('response', false)"
              type="is-light"
              class="is-radiusless card-footer-item"
              >Cancel</b-button
            >
            <b-button
              :loading="confirmLoading"
              style="height: 50px"
              @click="confirmHandler"
              type="is-link"
              class="is-radiusless card-footer-item"
              >Save</b-button
            >
          </footer>
        </div>
      </div>
    </b-modal>
  </section>
</template>

<script>
import { API } from "@/utils";
export default {
  props: {
    show: {
      default: false,
      type: Boolean,
    },
  },

  data() {
    return {
      confirmLoading: false,

      errorMessage: "",

      name: "",
      color: "",
      gsmUplink: 0,
      dcsUplink: 0,
      wcdmaUplink: 0,
      lteUplink: 0,
      gsmDownlink: 0,
      dcsDownlink: 0,
      wcdmaDownlink: 0,
      lteDownlink: 0,
    };
  },
  methods: {
    async registerNetwork(networkData) {
      let response = await API.post("/networks", {
        data: networkData,
      });
      if (![200, 201].includes(response.code)) {
        this.errorMessage = response.error.message;
        return false;
      }
      return true;
    },
    async confirmHandler() {
      let error = "";
      this.errorMessage = "";
      this.confirmLoading = true;

      let name = this.name.trim();
      let color = this.color.toString();

      if (name.length < 3 || name.length > 30) {
        error = "The network name must be between 3 and 30 characters";
      } else if (!color) {
        error = "The network needs to be associated with a marker color";
      }
      for (const val of [
        this.gsmDownlink,
        this.gsmUplink,
        this.dcsDownlink,
        this.dcsDownlink,
        this.wcdmaDownlink,
        this.wcdmaUplink,
        this.lteDownlink,
        this.lteUplink,
      ]) {
        if (val <= 0) {
          error = "Frequency values must be greater than 0";
          break;
        }
      }
      if (error !== "") {
        this.errorMessage = error;
        this.confirmLoading = false;
        return;
      }

      const networkData = {
        name: name,
        color: color,
        gsm_dl_frequency: this.gsmDownlink,
        dcs_dl_frequency: this.dcsDownlink,
        wcdma_dl_frequency: this.wcdmaDownlink,
        lte_dl_frequency: this.lteDownlink,
        gsm_ul_frequency: this.gsmUplink,
        dcs_ul_frequency: this.dcsUplink,
        wcdma_ul_frequency: this.wcdmaUplink,
        lte_ul_frequency: this.lteUplink,
      };

      let successful = await this.registerNetwork(networkData);
      this.confirmLoading = false;
      if (successful) {
        this.$emit("response", successful);
      }
    },
  },
};
</script>
