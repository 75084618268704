<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
// import NmsApp from './containers/NmsApp.vue';

export default {
  name: "App",
};
</script>

<style>
#app {
  font-family: Roboto, "Noto Sans", "Source Sans Pro", Avenir, Helvetica,
    -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial,
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  background-color: #e3e8f4;
  height: 100vh;
  color: #495057;
  font-size: 0.937rem;
}
#app
  > div
  > div
  > section
  > div
  > div.animation-content.modal-content
  > button {
  display: none;
}
.dropdown-menu {
  min-width: 8rem !important;
}
.is-large.modal-close,
.is-large.delete {
  display: none !important;
}
.loading-overlay.is-full-page .loading-icon:after {
    top: calc(50% - 2.5em);
    left: calc(50% - 2.5em);
    width: 2em;
    height: 2em;
}
.loading-icon:after {
    width: 2em !important;
    height: 2em !important;
}
html {
  overflow: hidden !important; /* Hide scrollbars */
}
.datepicker .datepicker-table .datepicker-body .datepicker-cell.is-selected {
    background-color: #3c74c3 !important;
    color: #fff !important;
}
a {
  color: #467fcf !important;
}
a.dropdown-item,
.dropdown .dropdown-menu .has-link a,
button.dropdown-item {
  color: unset !important;
}
a:hover {
  color: #236bcf !important;
}
.slider > .b-slider .b-slider-track {
  background: #f14668 !important;
}
.message-body {
  padding: 0.21em 1em !important;
}
.label {
  font-size: 0.9rem !important;
}
.button.is-loading::after {
  font-size: 20px;
}
.mdi-24px.mdi:before {
  font-size: 20px !important;
}

.tdd {
  line-height: 26px;
  cursor: pointer;
}

.field-label {
  margin-right: 0rem !important;
  text-align: left !important;
}
.is-link {
  background-color: #3c74c3 !important;
  color: white;
}
.is-no-alarm {
  color: rgb(7, 150, 7);
  font-weight: 700;
}
.tag:not(body).is-danger {
  background-color: #de0b35 !important;
}
.table tr.is-selected {
  background-color: #dce6ef !important;
  color: black !important;
}
.is-alarmed {
  color: red;
  font-weight: 700;
}
.is-alert {
  color: red;
}
.is-zlue {
  color: #467fcf;
}
.message.is-warning {
  background-color: #fff4d4 !important;
}
.is-20 {
  font-size: 20px;
}
.pagination-link.is-current {
  background-color: #236bcf !important;
  color: white !important;
}
.is-14 {
  font-size: 14px;
}
.rbut > .button[disabled] {
  opacity: 1 !important;
}
.table.is-striped tbody tr:not(.is-selected):nth-child(even) {
  background-color: rgb(17 127 241 / 2%) !important;
}
.card-header {
  background-color: #f5f7fb !important;
}
.leaflet-container a.leaflet-popup-close-button {
  display: none !important;
}

.leaflet-popup-content {
  text-align: center !important;
}
.message-body {
  border-style:none !important;
}
.media-content {
  -ms-flex-preferred-size: auto;
  flex-basis: auto;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  align-self: center;
  flex-grow: 1;
  -ms-flex-negative: 1;
  flex-shrink: 1;
  text-align: inherit;
}
.button.is-link:hover {
  background-color: #1c74f0 !important;
}
.media-left {
  margin-right: 0rem !important;
  align-self: center;
}

.mdi:before,
.mdi-set {
  display: inline-block;
  font: normal normal normal 24px/1 "Material Design Icons";
  text-rendering: auto;
  line-height: inherit;
  font-size: 21px !important ;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.control > .mm > .input {
  height: 38px !important;
}

.tabs a {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #236bcf;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-bottom: -1px;
  padding: 0.5em 1em;
  vertical-align: top;
}
.tabs li.is-active a {
  border-bottom-width: 4px !important;

  border-bottom-color: #236bcf !important;
  color: #236bcf !important;
}
</style>
