<template>
  <div class="is-fullwidth is-flex is-flex-direction-column is-flex-grow-2">
    <div class="tabs is-left is-full is-size-7">
      <ul>
        <li
          class="mx-0 mr-4"
          @click="updateActiveTab('account')"
          :class="{ 'is-active': activeTab === 'account' }"
        >
          <a>
            <span class="icon is-small"
              ><i class="mdi mdi-account" aria-hidden="true"></i
            ></span>
            <span>Account</span>
          </a>
        </li>
        <li
          class="mx-0 mr-4"
          @click="updateActiveTab('preferences')"
          :class="{ 'is-active': activeTab === 'preferences' }"
        >
          <a>
            <span class="icon is-small"
              ><i class="mdi mdi-tune-vertical" aria-hidden="true"></i
            ></span>
            <span>Preferences</span>
          </a>
        </li>
      </ul>
    </div>
    <div class="is-flex-grow-2 py-4">
      <div v-if="activeTab === 'account'">
        <div
          class="is-flex is-align-content-center is-justify-content-center py-5"
        >
          <!-- Profile Pic -->
          <div class="is-3 pb-4 pr-6">
            <div class="is-flex">
              <div class="circleBase type3">
                <b-image
                  :rounded="true"
                  :src="
                    useGravatar
                      ? store.userData.gravatar
                      : require('@/assets/mp.jpg')
                  "
                  alt="Avatar"
                ></b-image>
              </div>
            </div>
          </div>

          <div class="mt-2 pt-5">
            <section
              class="is-flex is-justify-content-space-between is-flex-direction-column"
            >
              <content class="is-flex is-flex-direction-column">
                <span class="is-size-4 has-text-weight-bold">
                  {{ store.username }}
                </span>
                <span>
                  {{ store.userData.email }}
                </span>
              </content>
              <div class="is-flex is-justify-content-left pt-5 pb-2">
                <!-- <content class="is-capitalized">
                  <b-field label="Status">
                    <b-tag type="is-success">ACTIVE</b-tag>
                  </b-field>
                </content> -->
                <content class="is-capitalized">
                  <b-field label="Roles">
                    <b-tag
                      class="mr-1"
                      :key="index"
                      v-for="(role, index) in store.userRoles"
                      type="is-dark"
                      >{{ snakeToStartCase(role) }}</b-tag
                    >
                  </b-field>
                </content>
              </div>
            </section>
          </div>
        </div>

        <div class="card">
          <div class="card-content p-6">
            <div class="columns">
              <div class="column">
                <p class="control">
                  <b-field label="Unique Identifier">
                    <b-tag>{{ store.userData.uuid }}</b-tag>
                  </b-field>
                </p>

                <hr />
                <p class="control">
                  <b-field label="Company">
                    <b-tag>{{ store.userData.company.name }}</b-tag>
                  </b-field>
                </p>
                <hr class="pb-0 mb-0" />
              </div>
              <div class="column">
                <p class="control">
                  <b-field label="Member Since">
                    <b-tag>{{ store.userData.registered_at }}</b-tag>
                  </b-field>
                </p>
                <hr />
                <p class="control">
                  <b-field label="Company Identifier">
                    <b-tag>{{ store.userData.company.identifier }}</b-tag>
                  </b-field>
                </p>
                <hr class="pb-0 mb-0" />
              </div>
            </div>

            <p class="is-size-6 has-text-weight-bold pb-3">Permissions</p>
            <div class="content is-flex is-flex-wrap-wrap">
              <b-field :key="index" v-for="(permission, index) in permissions">
                <b-tag>{{ permission.name }}</b-tag>
              </b-field>
            </div>
            <hr class="pb-0 mb-0" />

            <div class="columns">
              <div class="column">
                <p class="is-size-6 has-text-weight-bold pt-4 pb-2">Account</p>
                <p class="control">
                  <b-field>
                    <b-button
                      style="height: 40px"
                      @click="readyToDelete = true"
                      type="is-danger"
                      icon-left="trash-can-outline"
                      >Delete my Account</b-button
                    >
                  </b-field>
                </p>
              </div>
              <div class="column"></div>
            </div>
          </div>
        </div>
      </div>

      <!-- Preferences -->
      <div v-if="activeTab === 'preferences'">
        <div class="column p-0 is-8">
          <div class="card">
            <div>
              <b-message v-if="errorMessage !== ''" type="is-danger" has-icon>
                {{ errorMessage }}
              </b-message>
            </div>
            <div class="card-content p-6">
              <div class="content">
                <div>
                  <p class="control">
                    <b-field
                      horizontal
                      label="Use Profile Gravatar"
                      message="Set how your profile picture is displayed"
                    >
                      <b-select v-model="useGravatar">
                        <option :value="true">Yes</option>
                        <option :value="false">No</option>
                      </b-select>

                      <!-- <b-radio-button
                      v-model="useGravatar"
                      :native-value="true"
                      type="is-info is-light is-outlined"
                    >
                      <span>Yes</span>
                    </b-radio-button>
                    <b-radio-button
                      v-model="useGravatar"
                      :native-value="false"
                      type="is-info is-light is-outlined"
                    >
                      <span>No</span>
                    </b-radio-button> -->
                    </b-field>
                  </p>
                </div>
                <div>
                  <hr />
                  <p class="control">
                    <b-field
                      horizontal
                      label="Navbar"
                      message="Set the default navigation bar status"
                    >
                      <b-select v-model="navbar">
                        <option value="expanded">Expanded</option>
                        <option value="collapsed">Collapsed</option>
                      </b-select>
                    </b-field>
                  </p>
                </div>
                <div>
                  <hr />
                  <p class="control">
                    <b-field
                      horizontal
                      label="Items per page"
                      message="Set the default number of items displayed on a table"
                    >
                      <b-select v-model="perPage">
                        <option value="10">10</option>
                        <option value="15">15</option>
                        <option value="20">20</option>
                        <option value="25">25</option>
                        <option value="30">30</option>
                        <option value="50">50</option>
                      </b-select>
                    </b-field>
                  </p>
                </div>
                <div>
                  <hr />
                  <p v-if="canAccessTab('tickets')" class="control">
                    <b-field
                      horizontal
                      label="Tickets Page"
                      message="Set the default tab on the Tickets page"
                    >
                      <b-select v-model="ticketsPage">
                        <option value="table_view">Table View</option>
                        <option value="statistics">Statistics</option>
                      </b-select>
                    </b-field>
                  </p>
                </div>
                <div>
                  <hr />
                  <p class="control">
                    <b-field
                      horizontal
                      label="Sites Page"
                      message="Set the default tab on the Sites page"
                    >
                      <b-select v-model="sitesPage">
                        <option value="map_view">World View</option>
                        <option value="table_view">Table View</option>
                        <option value="statistics">Statistics</option>
                      </b-select>
                    </b-field>
                  </p>
                </div>
                <div v-if="canAccessTab('admin')" class="control">
                  <hr />
                  <p>
                    <b-field
                      horizontal
                      label="Admin Page"
                      message="Set the default tab on the Admin page"
                    >
                      <b-select v-model="adminPage">
                        <option value="users">Users</option>
                        <option value="companies">Companies</option>
                        <option value="access_control">Access Control</option>
                        <option value="network">Networks</option>
                      </b-select>
                    </b-field>
                  </p>
                </div>
                <hr />
                <p class="control">
                  <b-field
                    horizontal
                    label="Site Page"
                    message="Set the default tab on the Site page"
                  >
                    <b-select v-model="sitePage">
                      <option value="details">Details</option>
                      <option value="realtime_data">Real Time Data</option>
                      <option value="alarms">Alarms</option>
                    </b-select>
                  </b-field>
                </p>

                <div v-if="canAccessTab('deployments')" class="control">
                  <hr />
                  <p>
                    <b-field
                      horizontal
                      label="Deployments Page"
                      message="Set the default tab on the Deployments page"
                    >
                      <b-select v-model="deploymentsPage">
                        <option value="sites">Deployments</option>
                        <option value="configuration">
                          Deployment Configuration
                        </option>
                        <option value="remote_access">Remote Access</option>
                      </b-select>
                    </b-field>
                  </p>
                </div>
                <div v-if="canAccessTab('monitoring')">
                  <hr />
                  <p class="control">
                    <b-field
                      horizontal
                      label="Monitoring Page"
                      message="Set the default tab on the Monitoring page"
                    >
                      <b-select v-model="monitoringPage">
                        <option value="logs">Logs</option>
                        <option value="server_health">Server Health</option>
                      </b-select>
                    </b-field>
                  </p>
                </div>
                <div>
                  <hr />
                  <p class="control">
                    <b-field
                      horizontal
                      label="Profile Page"
                      message="Set the default tab on the Profile page"
                    >
                      <b-select v-model="profilePage">
                        <option value="account">Account</option>
                        <option value="preferences">Preferences</option>
                      </b-select>
                    </b-field>
                  </p>
                </div>
              </div>
              <footer class="pt-4 card-footer">
                <b-button
                  style="height: 45px; width=250px"
                  :loading="confirmLoading"
                  @click="updatePreferencesHandler"
                  type="is-link"
                  icon-left="content-save-check-outline"
                  >Update Preferences</b-button
                >
              </footer>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ConfirmModalStepped
      :show="readyToDelete"
      header="Delete Account"
      content="Are you sure you want to delete your account? This action cannot be undone."
      :confirmAgainst="store.userData.uuid"
      @response="deleteAccountHandler"
    />
  </div>
</template>

<script>
import ConfirmModalStepped from "@/components/ConfirmModalStepped.vue";
import { useUserStore } from "@/store";
import { API, snakeToStartCase, permitNavigation } from "@/utils";

const module = "/ Profile";

export default {
  data() {
    return {
      activeTab: "account",
      confirmLoading: false,
      errorMessage: "",
      navbar: "collapsed",
      perPage: 25,
      useGravatar: false,
      readyToDelete: false,
      sitesPage: "map_view",
      sitePage: "alarms",
      ticketsPage: "statistics",
      monitoringPage: "logs",
      adminPage: "access_control",
      deploymentsPage: "devices",
      profilePage: "account",
      permissions: [],
    };
  },
  setup() {
    const store = useUserStore();
    return { store };
  },
  async created() {
    // Get status from user preferences
    this.activeTab = this.store.preferences.profilePage;
    this.store.updateTopBar(`${module} / ${snakeToStartCase(this.activeTab)}`);
    this.updatePreferences();
    if (Object.keys(this.$route.query).length > 0) {
      this.activeTab = this.$route.query.tab;
    }
    let roles = this.store.userData.role_groups.filter(
      (r) => r.name !== "user"
    ); // Skip user role

    let response = await API.get(`role/${roles[0].id}`);
    if (response.code === 200 && !response.error.exists) {
      this.permissions = response.data.permissions;
    }
  },
  methods: {
    canAccessTab(tab) {
      return permitNavigation(tab, this.store.userRoles);
    },
    snakeToStartCase(v) {
      return snakeToStartCase(v);
    },
    deleteAccountHandler(confirmed) {
      if (confirmed) {
        // delete API request
      }
      this.readyToDelete = false;
    },
    updateActiveTab(selected) {
      this.activeTab = selected;
      this.store.updateTopBar(
        `${module} / ${snakeToStartCase(this.activeTab)}`
      );
    },
    updatePreferences() {
      this.navbar = this.store.preferences.navbar;
      this.sitePage = this.store.preferences.sitePage;
      this.sitesPage = this.store.preferences.sitesPage;
      this.adminPage = this.store.preferences.adminPage;
      this.useGravatar = this.store.preferences.useGravatar;
      this.deploymentsPage = this.store.preferences.deploymentsPage;
      this.ticketsPage = this.store.preferences.ticketsPage;
      this.monitoringPage = this.store.preferences.monitoringPage;
      this.perPage = this.store.preferences.perPage;
      this.profilePage = this.store.preferences.profilePage;
    },
    async updatePreferencesHandler() {
      this.errorMessage = "";
      let uiPreferences = {};
      this.confirmLoading = true;
      uiPreferences["navbar"] = this.navbar;
      uiPreferences["sitesPage"] = this.sitesPage;
      uiPreferences["sitePage"] = this.sitePage;
      uiPreferences["useGravatar"] = this.useGravatar;
      uiPreferences["adminPage"] = this.adminPage;
      uiPreferences["deploymentsPage"] = this.deploymentsPage;
      uiPreferences["profilePage"] = this.profilePage;
      uiPreferences["ticketsPage"] = this.ticketsPage;
      uiPreferences["monitoringPage"] = this.monitoringPage;
      uiPreferences["perPage"] = this.perPage;

      // Stringify for saving
      uiPreferences = JSON.stringify(uiPreferences);
      if (uiPreferences === JSON.stringify(this.store.preferences)) {
        this.$buefy.snackbar.open({
          message: "No changes were made",
          type: "is-info",
          pauseOnHover: false,
          position: "is-top",
        });
        this.confirmLoading = false;
        return;
      }

      this.permissions = []; // reset permisisons
      let response = await API.put(`/user/${this.store.userData.uuid}`, {
        data: {
          changes: [{ name: "ui_preferences", value: uiPreferences }],
        },
      });
      if (response.code === 200 && !response.error.exists) {
        this.store.updatePreferences(uiPreferences);
        this.$buefy.snackbar.open({
          message: "Preferences updated successfully",
          type: "is-success",
          pauseOnHover: false,
          position: "is-top",
        });
        setTimeout(() => {
          window.location.reload();
        }, 400);
      } else {
        this.errorMessage = response.error.message;
      }
      this.confirmLoading = false;
    },
  },
  components: { ConfirmModalStepped },
};
</script>

<style scoped>
.tabs:not(:last-child) {
  /* eslint-disable-line no-mixed-spaces-and-tabs */
  margin-bottom: 0rem !important;
  /** eslint-disable-line no-mixed-spaces-and-tabs **/
}

.table {
  overflow-y: scroll;
}
.circleBase {
  border-radius: 50%;
}
.type3 {
  width: 200px;
  height: 200px;
  background: #4a4a4a;
}
a {
  color: #4a4a4a !important;
}
</style>
