<template>
  <div>
    <b-loading
      :is-full-page="true"
      v-model="isLoading"
      :can-cancel="true"
    ></b-loading>

      <!-- Active alarm Data -->
      <div class="columns">
        <!-- Alarm Table -->
        <div class="column">
          <b-message
            class="mb-4"
            v-if="!alarmsPresent && showNoConfig && !isLoading"
            type="is-warning"
            has-icon
            aria-close-label="Threshold message"
          >
            <span class="pl-2"
              >The alarm thresholds for this site have not been configured. Alarms
              will not show until the thresholds have been configured.</span
            >
          </b-message>

          <div class="card">
            <header class="card-header is-danger p-2">
              <p class="card-header-title is-size-6">
                Active alarms
                <span class="ml-1 has-text-grey">({{ data.length }})</span>
              </p>
              <button class="card-header-icon" aria-label="more options">
                <span class="icon">
                  <i
                    class="mdi mdi-alarm-light-outline mdi-40px"
                    aria-hidden="true"
                  ></i>
                </span>
              </button>
            </header>
            <div
              v-if="!alarmsPresent"
              class="card-content is-flex is-align-items-center is-justify-content-center"
              style="height: 500px"
            >
              <div v-if="!isLoading" class="is-flex is-flex-direction-column pb-6">
                <div
                  class="is-flex is-align-items-center is-justify-content-center"
                >
                  <div class="is-flex">
                    <p class="is-align-self-center tg">
                      <i
                        class="mdi mdi-bell-check-outline mdi-40px"
                        aria-hidden="true"
                      ></i>
                    </p>
                  </div>
                </div>
                <p class="is-size-6 has-text-grey">
                  No alarms have been triggered for this site.
                </p>
              </div>
            </div>
            <div v-show="alarmsPresent">
              <ModifiableTable
                :showHeader="false"
                :tableData="data"
                :tableColumns="columns"
              />
            </div>
          </div>
        </div>
      </div>

      <!-- Alarms Graph -->
      <div v-show="alarmsPresent" class="columns">
        <div class="column">
          <div style="overflow: auto" class="card">
            <header class="card-header is-danger p-2">
              <p class="card-header-title is-size-6">Alarm Timeline</p>

              <button
                @click="alarmHidden = !alarmHidden"
                class="card-header-icon"
                aria-label="more options"
              >
                <span class="icon">
                  <i
                    :class="alarmHidden ? 'mdi-eye' : 'mdi-eye-off'"
                    class="mdi pr-2 mdi-40px"
                    aria-hidden="true"
                  ></i>
                </span>

                {{ alarmHidden ? "Show" : "Hide" }}
              </button>
            </header>
            <div v-show="!alarmHidden" class="card-content p-4">
              <Chart :series="series" :categories="alarmTimes" />
            </div>
          </div>
        </div>
      </div>

      <!-- Inactive alarm Data -->
      <div v-show="alarmsPresent" class="columns">
        <!-- Alarm Table -->
        <div class="column">
          <div class="card">
            <header class="card-header is-danger p-2">
              <p class="card-header-title is-size-6">
                Inactive alarms
                <span class="has-text-grey ml-1"
                  >({{ inactiveData.length }})</span
                >
              </p>
              <button class="card-header-icon" aria-label="more options">
                <span class="icon">
                  <i
                    class="mdi mdi-alarm-light-off-outline mdi-40px"
                    aria-hidden="true"
                  ></i>
                </span>
              </button>
            </header>

            <div>
              <ModifiableTable
                :showHeader="false"
                :tableData="inactiveData"
                :tableColumns="columns"
              />
            </div>
          </div>
        </div>
      </div>
    
    <!-- Metadata -->
    <div v-show="!isLoading" class="is-size-7 has-text-grey has-text-centered">
      Last updated: {{ lastPing || "Just now" }}.
    </div>
  </div>
</template>

<script>
import Chart from "./Chart.vue";
import { useUserStore } from "@/store";
import { API, snakeToStartCase } from "@/utils";
import { DEFAULT_UI_FORMAT } from "@/constants";
import ModifiableTable from "@/components/ModifiableTable.vue";
import moment from "moment";

const EXCLUDE_PARAMS = [
  "uuid",
  "enabled",
  "site_id",
  "company_id",
  "threshold_name",
  "status",
  "is_active",
];

const MAX_TIMESTAMPS_TO_SHOW = 6;
const visibleFields = [
  "no",
  "name",
  "value",
  "operator",
  "threshold_value",
  "unit",
  "time_stamp",
  "frequency",
  "icon",
];

export default {
  setup() {
    const store = useUserStore();
    return { store };
  },
  data() {
    return {
      data: [], // active alarms
      columns: [],
      inactiveData: [], // inactive alarms

      series: [], // yaxis
      alarmTimes: [], // xaxis

      isLoading: true,
      alarmHidden: true,
      siteId: "",
      lastPing: "",
      alarmsPresent: false,
      showNoConfig: false,
    };
  },
  methods: {
    chartify() {
      let alarmTimes = this.alarmTimes.slice(-(MAX_TIMESTAMPS_TO_SHOW - 1));

      this.alarmTimes = [];
      let series = [];
      let minTs = null; // Minimum timestamp. The lowest of a set of time series

      [...this.data, ...this.inactiveData].forEach((d) => {
        let timestamp = moment(d.time_stamp, DEFAULT_UI_FORMAT).toDate();
        let tsLocal = timestamp.toLocaleString();

        let name = snakeToStartCase(d.name);
        let frequency = d.frequency;

        // Don't want the chart to be showing a lot of 0-freqs
        if (frequency !== 0) {
          let existingAlarm = this.series.find((x) => x.name === name);

          // If the alarm is already displayed
          if (existingAlarm) {
            // Get last five
            let alarmData = existingAlarm["data"].slice(
              -(MAX_TIMESTAMPS_TO_SHOW - 1)
            );

            if (minTs && timestamp - minTs < 0) {
              alarmData.unshift(frequency);
            } else {
              alarmData.push(frequency);
            }

            series.push({
              name: name,
              data: alarmData,
            });
          }
        }

        // Entirely new alarm
        else {
          series.push({
            name: name,
            data: [frequency],
          });
        }

        if (minTs && timestamp - minTs < 0) {
          alarmTimes.unshift(tsLocal);
        } else {
          alarmTimes.push(tsLocal);
        }
        minTs = timestamp;
      });

      this.alarmTimes = alarmTimes;
      this.series = series;
    },

    processAlarms(alarmData) {
      // Reset
      this.data = [];
      this.inactiveData = [];

      alarmData.forEach((i, index) => {
        i.is_active === true
          ? this.data.push({
              ...i,
              no: index + 1,
              icon: "alarm-light",
              status: "is-alarmed",
            })
          : this.inactiveData.push({
              ...i,
              no: index + 1,
              icon: "alarm-light-off",
              status: "is-no-alarm",
            });
      });
      this.chartify();
    },

    async fetchAlarms() {
      this.isLoading = true;
      let response = await API.get(`/alarms?site_id=${this.siteId}`);
      if (!response.error.exists && response.data.items_count) {
        this.alarmsPresent = response.data.items_count > 0;

        // Meat of the processing
        this.processAlarms(response.data.items);

        let colConfig = // Use whichever has data
          this.data.length === 0 ? this.inactiveData[0] : this.data[0];

        // Compute columns only once
        if (this.columns.length === 0) {
          this.columns = Object.keys(colConfig)
            .filter((c) => !EXCLUDE_PARAMS.includes(c))
            .map((c) => {
              return {
                field: c,
                sortable: ["frequency", "name", "time_stamp"].includes(c),
                centered: !c.includes("name"),
                label:
                  c === "name"
                    ? "Alarm Parameter"
                    : c === "operator"
                    ? ""
                    : c === "icon"
                    ? ""
                    : c === "time_stamp"
                    ? "Last Occurrence"
                    : c === "value"
                    ? "Parameter Value"
                    : snakeToStartCase(c),
              };
            });

          // Order columns in expected view
          for (let index = 0; index < visibleFields.length; index++) {
            let indexInColumns = this.columns
              .map((c) => c.field)
              .indexOf(visibleFields[index]);

            // `move` is added to Array.prototype in `utils.js`
            this.columns.move(indexInColumns, index);
          }
        }
      }
      this.isLoading = false;
    },
  },

  async mounted() {
    this.siteId = this.$route.params.uuid;
    let response = await API.get(`/alarm-threshold/${this.siteId}`);

    if (response.code === 200 && !response.error.exists) {
      let thresholdValues = Object.values(response.data);
      if (thresholdValues.includes(null)) {
        this.showNoConfig = true;
      }
    }
    // This will happen in the case that a site's
    // initial details are modified.
    else if (response.code === 404) {
      // Try to reset it. This is best-effort scenario
      await API.get(`/alarm-threshold/${this.siteId}/reset`);
      this.showNoConfig = true;
    }

    await this.fetchAlarms();

    this.pingIntervalId = setInterval(async () => {
      let response = await API.get(`/alarms?site_id=${this.siteId}`);
      this.lastPing = new Date().toLocaleString();
      if (response.code === 200 && !response.error.exists) {
        this.alarmsPresent = response.data.items_count > 0;
        if (this.alarmsPresent) {
          this.processAlarms(response.data.items);
        }
      }
    }, 7000);
  },

  beforeDestroy() {
    this.columns = [];
    this.data = [];
    this.inactiveData = [];
    this.series = [];
    this.alarmTimes = [];
    clearInterval(this.pingIntervalId);
  },

  components: { Chart, ModifiableTable },
};
</script>
<style scoped>
.tg > .mdi:before,
.mdi-set {
  color: grey;
  font-size: 40px !important;
}
</style>
