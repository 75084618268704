import axios from 'axios';
import { API_ERRORS } from './errors';
import { SnackbarProgrammatic as Snackbar } from 'buefy'
import { API_BASE_URL, ALL_DEFAULT_ROLES, getFingerprintHash } from './constants';

import { router } from './main'

axios.defaults.crossDomain = true;
axios.defaults.withCredentials = true;

axios.defaults.baseURL = `${API_BASE_URL}/api/rest`;
axios.defaults.headers.post['Content-Type'] = 'application/json';

// axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
// const store = useUserStore();

axios.interceptors.request.use(request => {
    // console.log(request);
    // Edit request config
    return request;
}, error => {
    // console.log(error)
    return Promise.reject(error);
});

axios.interceptors.response.use(response => {
    // console.log(response);
    // Edit response config
    return response;
}, error => {
    // console.log('error', error);
    if (error.response.data.error.message in API_ERRORS) {
        error.response.data.error.message = API_ERRORS[error.response.data.error.message];
    }
    if (error.response.data.code === 401) {
        if (router.currentRoute.path !== "/authwall") {
            Snackbar.open({
                message: error.response.data.error.message ? error.response.data.error.message : 'Your previous session has expired. You need to login again to continue',
                type: 'is-warning',
                duration: 5000,
                position: 'is-top',
            })
            localStorage.clear();
            router.push("/authwall");
        }
    }
    if (error.response.data.code === 403) {
        Snackbar.open({
            message: error.response.data.error.message,
            type: 'is-danger',
            indefinite: true,
            position: 'is-top',
        })
    }
    // if (error.response.data.code === 500) {
    //     console.log("In here");
    //     Snackbar.open({
    //         message: error.response.data.error.message,
    //         type: 'is-danger',
    //         indefinite: true,
    //         position: 'is-top',
    //     })
    // }
    return Promise.reject(error.response);
});


const __API = async (opts) => {
    let token = fetchBearerToken();
    let fingerprint = await getFingerPrint();
    let config = {
        url: opts.url,
        data: opts.json,
        method: opts.mode,
        headers: { 'Authorization': `Bearer ${token}`, 'X-ID-Fingerprint-Hash': fingerprint }
    }
    // Payload is params for GET and same for POST
    return await axios(config)
        .then(r => r.data)
        .catch(e => e.data);
}

const fetchBearerToken = () => {
    return localStorage.getItem('nmsToken') || '';
}
const getFingerPrint = async () => {
    let fp = localStorage.getItem('fpHash');
    if (!fp) {
        fp = await getFingerprintHash();
        await localStorage.setItem('fpHash', fp);
    }
    return fp;
}

const createAxiosConfig = async (method, url, opts) => {

    let config = {};
    let fingerprint = await getFingerPrint();

    config['url'] = url;
    config['method'] = method;

    if (opts && opts.data) {
        const key = ['get', 'delete'].includes(method) ? 'params' : 'data';
        config[key] = opts.data;
    }

    config['headers'] = { 'Authorization': `Bearer ${fetchBearerToken()}`, 'X-ID-Fingerprint-Hash': fingerprint };

    return config
}
export const API = {
    post: async (url, opts) => {
        return await axios(await createAxiosConfig('post', url, opts))
            .then(r => r.data)
            .catch(e => e.data);
    },
    get: async (url, opts) => {
        return await axios(await createAxiosConfig('get', url, opts))
            .then(r => r.data)
            .catch(e => e.data);
    },
    put: async (url, opts) => {
        return await axios(await createAxiosConfig('put', url, opts))
            .then(r => r.data)
            .catch(e => e.data);
    },
    delete: async (url, opts) => {
        return await axios(await createAxiosConfig('delete', url, opts))
            .then(r => r.data)
            .catch(e => e.data);
    },
}

export const APIMultiRequests = async (requests) => {
    // Payload is params for GET and same for POST
    return await Promise.all(requests.map(request => __API(request)))
        .then(r => r)
        .catch(e => e);
}


export const snakeToStartCase = (str) => {
    return typeof str === 'string' ? str.split('_').map(s => s.charAt(0).toUpperCase() + s.slice(1)).join(' ') : str
}
export const capitalize = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
}
export const moveElementInArray = (array, from, to) => {
    array.splice(to, 0, array.splice(from, 1)[0]);
};
export const validateEmail = (email) => {
    return String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
};

export { API_BASE_URL };

Array.prototype.move = function (from, to) {
    this.splice(to, 0, this.splice(from, 1)[0]);
};

String.prototype.isAlphaNumeric = function () {

    var regExp = /^[A-Za-z0-9]+$/;
    return isNaN(this.charAt(0)) && (this.match(regExp));
};

Array.prototype.lastElement = function () {
    return this[this.length - 1];
};


/**
 * Adds numbering to an API response
 * @param {Array[Object]} items
 * The array of objects from the API
 * @param {Array} source 
 * The data source to eventually assign the API response to
 */
export const numberize = (items, source) => {
    const sourceLength = source.length;

    for (const [index, item] of items.entries()) {
        source.push({ no: sourceLength + index + 1, ...item });
    }
}

/**
 * 
 * @param {String} url 
 * The base url to fetch from
 * @param {String} uuidAfter 
 * The uuid to pass as query parameters
 * @param {Array} source 
 * The data source to eventually assign the API response to
 * @returns {Boolean}
 * Whether the API response is the last
 */
export const fetchNextSetOfData = async (url, uuidAfter, source) => {
    let response = await API.get(`/${url}?uuid_after=${uuidAfter}`);
    if (!response.error.exists && response.data.items_count) {
        if (response.data.items_count == response.data.items_per_page) {
            numberize(response.data.items, source);
            return { newUuidAfter: response.data.uuid_after, allIsFetched: false };
        }
        numberize(response.data.items, source);
        return { newUuidAfter: '', allIsFetched: true };
    } else {
        return { newUuidAfter: '', allIsFetched: true };
    }
}


export const permitNavigation = (destination, roles) => {
    let tabRoles = [];
    if (destination === "profile") {
        tabRoles = ALL_DEFAULT_ROLES;
    } else if (destination === "notifications") {
        tabRoles = ALL_DEFAULT_ROLES;
    } else if (destination === "dashboard") {
        tabRoles = ALL_DEFAULT_ROLES;
    } else if (destination === "sites") {
        tabRoles = ALL_DEFAULT_ROLES;
    } else if (destination === "tickets") {
        tabRoles = ALL_DEFAULT_ROLES.filter((r) => !["spectator", "user"].includes(r));
    } else if (destination === "deployments") {
        tabRoles = ALL_DEFAULT_ROLES.filter(
            (r) => !["support", "spectator", "user"].includes(r)
        );
    } else if (destination === "admin") {
        tabRoles = ALL_DEFAULT_ROLES.filter(
            (r) => !["engineer", "support", "spectator", "user"].includes(r)
        );
    } else if (destination === "monitoring") {
        tabRoles = ALL_DEFAULT_ROLES.filter(
            (r) =>
                !["admin", "engineer", "support", "spectator", "user"].includes(r)
        );
    }

    return tabRoles.some((r) => roles.includes(r));
}

export function downloadFile(content, mimeType, filename) {
    const a = document.createElement('a') // Create "a" element
    const blob = new Blob([content], { type: mimeType }) // Create a blob (file-like object)
    const url = URL.createObjectURL(blob) // Create an object URL from blob
    a.setAttribute('href', url) // Set "a" element link
    a.setAttribute('download', filename) // Set download filename
    a.click() // Start downloading
}