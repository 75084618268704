<template>
  <div>
    <header class="card-header is-danger p-2">
      <p class="card-header-title is-size-6">
        Registered Sites
        <span class="ml-1 has-text-grey">({{ data.length }})</span>
      </p>
      <button class="card-header-icon" aria-label="more options">
        <span class="icon">
          <i class="mdi mdi-earth mdi-40px" aria-hidden="true"></i>
        </span>
      </button>
    </header>
    <ModifiableTable
      :tableData="data"
      :tableColumns="columns"
      :modifiable="false"
      :showHeader="true"
      :deletable="false"
    />
  </div>
</template>

<script>
import ModifiableTable from "@/components/ModifiableTable.vue";
import { API, snakeToStartCase, numberize, fetchNextSetOfData } from "@/utils";

const sortableFields = [
  "no",
  "id",
  "name",
  "date_registered",
  "date_deployed",
  "s_n",
];

// The order of these fields matter
// as the table uses it as the order
// of the columns displayed. All later
// visible fields are rendered after
const visibleFields = [
  "no",
  "name",
  "number",
  "company",
  "network",
  // "bands",
  "device_type",
  "geography",
  "date_deployed",
  // "date_registered",
  // "remote_access_port",
  "tagListItems",
];
const numericFields = ["id", "latitude", "longitude", "no"];

const centeredFields = [
  "tagListItems",
  "date_deployed",
  "geography",
  "algo_sequence",
  "network",
  "remote_access_port",
  "date_registered",
];

const routerableFields = ["name"];
export default {
  components: {
    ModifiableTable,
  },
  data() {
    return {
      data: [],
      sitesUpdated: false,
      columns: [],
      after: "",
      uuidBefore: "",
    };
  },
  methods: {
    async updateSitesStatus() {
      let newSites = [];
      for (const site of this.data) {
        let response = await API.get(`/site/${site["uuid"]}/ping`);
        if (!response.error.exists) {
          site["is_live"] = !response.data.is_live;
          site["is_powered"] = !response.data.is_powered;
        }
        newSites.push(site)
      }
      this.sitesUpdated = true;
      this.processSites(newSites);
    },
    /** Generate the site and columns list
     **/
    async processSites(sitesResponse) {
      let sites = [];
      this.data = [];

      // Loop through sites list
      sitesResponse.forEach((site) => {
        let newSite = {
          // These are params the table renders as a link (url)
          routerableParams: {
            name: { routerTo: "site", name: site["name"], uuid: site["uuid"] },
          },
        };
        for (const [k, v] of Object.entries(site)) {
          newSite[k] = v;
        }
        // Don't want to render company data
        newSite["company"] = newSite.company.name
          ? newSite.company.name
          : newSite.company;
        newSite["network"] = newSite.network.name
          ? newSite.network.name
          : newSite.network;
        newSite["tagListItems"] = [
        {
            name: newSite.is_live ? "Online" : "Offline",
            type: !this.sitesUpdated ? "is-normal" : newSite.is_live ? "is-success" : "is-warning",
            icon: !this.sitesUpdated ? "loading" : newSite.is_live ? "wifi" : "wifi-strength-2-alert",
            rounded: true,
          },
          {
            name: newSite.is_powered ? "Powered" : "Unpowered",
            type: !this.sitesUpdated ? "is-normal" : newSite.is_powered ? "is-success" : "is-danger",
            icon: !this.sitesUpdated ? "loading" : newSite.is_powered ? "power-plug" : "power-plug-off",
            rounded: true,
          },
        ];

        // Add to site list
        sites.push(newSite);
      });

      if (this.columns.length === 0) {
        this.columns = Object.keys(sites[0])
          // Exclude the routerable params from columns
          .filter((k) => k !== "routerableParams")
          .map((p) => {
            return {
              field: p === "tagListItems" ? "tagList" : p,
              width: p === "tagListItems" ? 185 : "",
              label:
                p === "company"
                  ? "Affiliation"
                  : p === "pd_type"
                  ? "Power Device Type"
                  : p === "tagListItems"
                  ? "Status"
                  : snakeToStartCase(p),
              numeric: numericFields.includes(p),
              sortable: sortableFields.includes(p),
              centered: centeredFields.includes(p),
              routerable: routerableFields.includes(p),
              visible: visibleFields.includes(p) && !p.endsWith("_id"),
            };
          });

        // Order columns in expected view
        for (let index = 0; index < visibleFields.length; index++) {
          let indexInColumns = this.columns
            .map((c) => c.field)
            .indexOf(visibleFields[index]);

          // `move` is added to Array.prototype in `utils.js`
          this.columns.move(indexInColumns, index);
        }
      }
      this.data = sites;
    },

    async updateSiteList() {
      let sites = [...this.data];
      let maxRequests = 20;
      let requestsCount = 1;
      let allFetched = false;

      if (this.after !== "") {
        while (!allFetched && requestsCount <= maxRequests) {
          requestsCount += 1;
          let response = await fetchNextSetOfData("sites", this.after, sites);
          this.after = response.newUuidAfter;
          allFetched = response.allIsFetched;
        }
        this.processSites(sites);
      }
    },
  },

  async mounted() {
    this.sitesUpdated = false;
    let response = await API.get("/sites");
    if (!response.error.exists) {
      if (response.data.items_count == response.data.items_per_page) {
        this.after = response.data.uuid_after;
      }
      let sites = [];
      numberize(response.data.items, sites);
      this.processSites(sites);
      setTimeout(async () => {
        await this.updateSitesStatus();
      }, 1000);
    }

    // setTimeout(async () => {
    //   await this.updateSiteList();
    // }, 5000);
  },
};
</script>
