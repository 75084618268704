<template>
  <section
    class="section is-flex is-flex-direction-column is-align-items-center is-align-content-center is-justify-content-center"
  >
    <img class="mb-5" alt="Vue logo" src="@/assets/images/zysod/zysod.png" />

    <div class="is-flex is-flex-direction-column">
      <div class="card p-4">
        <div class="card-content">
          <p
            style="text-align: center"
            class="is-centered has-text-weight-bold is-size-2"
          >
            404
          </p>
          <p style="text-align: center" class="has-text-grey">
            The page you're looking for was not found.
          </p>
        </div>
      </div>
      <div class="is-align-self-center has-text-grey is-size-6 pt-0">
        <a href="/">Go to the Home Page</a>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style scoped>
.section {
  height: 100vh;
}

img {
  height: 3.5rem;
}

.card {
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 5%);
  margin-bottom: 1.5rem;
  width: 20vw;
  min-width: 22rem;
}
</style>
