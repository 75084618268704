<template>
  <div class="column">
    <div class="card">
      <b-loading :is-full-page="false" v-model="isLoading" :can-cancel="true"></b-loading>
      <header class="card-header is-danger p-2">
        <p class="card-header-title has-text-weight-normal is-size-6">
          Configure the alarm threshold of a Site
        </p>
        <!-- <button class="card-header-icon" aria-label="more options">
          <span class="icon">
            <i class="mdi mdi-earth mdi-40px" aria-hidden="true"></i>
          </span>
        </button> -->
      </header>
      <div v-show="!isLoading && selectedSite &&!alarmsDisabled" class="mt-3">
          <b-message type="is-info" has-icon>
            An alarm will be triggered if the value of the parameter is
            outside the set range and it happens seven times within one
            minute.
          </b-message>
      </div>
      <div class="card-content py-3 px-2">
        <div>
          <b-field class="px-3">
            <SearcheableSelect :focusOnMount="true" @selected="choice => selectedSite = choice.uuid"
              placeholder="Select the site to configure" :dataSource="sites" :formatter="(s) =>
                `${s.name} (${s.geography}) (${s.device_type === 'david' ? 'David' : s.pd_type
                })`" />

          </b-field>
        </div>

        <div class="p-4" v-if="selectedSite">
          <div v-if="alarmsDisabled" class="is-flex is-justify-content-center is-flex-direction-column is-align-content-center p-4 m-5">
            <div class="is-flex is-size-6 is-align-self-center pb-2">Alarms are disabled for this site. First enable them to
              make changes.
            </div>
            <div class="is-align-self-center">
              <b-button :loading="enableButtonLoading" @click="showToggleAlarmsModal=true" icon-left="alarm-light" type="is-link">Enable Alarms</b-button>
            </div>
          </div>
          <div v-else style="min-height: 20vh; overflow: auto">
            <div class="pb-3">
              <div class="pb-3" :key="index" v-for="(threshold, index) in siteThreshold">
                <b-field :label="threshold.displayName"> </b-field>

                <div class="is-flex is-justify-content-space-between">
                  <b-input class="slider-input" lazy v-model="thresholds[threshold.name][0]" type="number"
                    inputmode="numeric" :min="-1000" :max="0">
                  </b-input>

                  <b-input class="slider-input" inputmode="numeric" lazy
                    onkeypress="return (event.charCode != 8 && event.charCode == 0 || (event.charCode >= 48 && event.charCode <= 57))"
                    v-model="thresholds[threshold.name][1]" type="number" :min="0" :max="1000">
                  </b-input>
                </div>

                <div>
                  <b-slider v-model="thresholds[threshold.name]" indicator lazy
                    :custom-formatter="(val) => val + threshold.unit" :tooltip="false" type="is-success"
                    :min="threshold.min - 30" :max="threshold.max + 30" :step="2">
                  </b-slider>
                </div>
              </div>
            </div>

            <footer v-show="!isLoading" class="card-footer">
              <b-button style="height: 50px" type="is-light"
                class="is-radiusless card-footer-item">Cancel</b-button>
              <b-button :loading="saveButtonLoading" style="height: 50px" @click="aboutToReset = true"
                type="is-danger is-light" class="is-radiusless card-footer-item">Reset to template Defaults</b-button>
              <b-button :loading="saveButtonLoading" style="height: 50px" @click="saveHandler" type="is-link"
                :disabled="detectedSiteChange" class="is-radiusless card-footer-item">Save Changes</b-button>
            </footer>
          </div>
        </div>

        <!-- Default backdrop -->
        <div v-else>
          <div style="height: 300px" class="is-flex is-align-items-center is-justify-content-center">
            <div class="is-flex is-flex-direction-column">
              <div class="is-flex is-align-items-center is-justify-content-center">
                <div class="is-flex">
                  <p class="is-align-self-center tg">
                    <!-- <i
                      class="mdi mdi-transfer-left mdi-40px"
                      aria-hidden="true"
                    ></i>
                    <i
                      class="mdi mdi-transfer-right mdi-40px"
                      aria-hidden="true"
                    ></i> -->
                    <i class="mdi mdi-alarm-light mdi-40px" aria-hidden="true"></i>
                  </p>
                </div>
              </div>
              <p class="is-size-6 pb-5 has-text-grey">
                Configure the range outside which an alarm would be triggered
              </p>
            </div>
          </div>
        </div>

        <ConfirmModal :show="aboutToReset" header="Reset Site Alarm Thresholds"
          content="Are you sure you want to reset the alarm thresholds for this site?"
          @response="(c) => resetHandler(c)" />
      </div>
    </div>
    <ConfirmModal @response="(r) => handleConfirmToggleAlarmsModalResponse(r)" :show="showToggleAlarmsModal"
      header="Enable Alarms"
      content="This will enable the alarms for this site. Are you sure you want to continue?"/>

  </div>
</template>

<script>
import ConfirmModal from "@/components/ConfirmModal.vue";
import SearcheableSelect from "@/components/SearcheableSelect.vue";
import { useUserStore } from "@/store";
import { API, snakeToStartCase } from "@/utils";

export default {
  setup() {
    const store = useUserStore();
    return { store };
  },

  data() {
    return {
      sites: [],
      siteThreshold: [],
      originalThresholds: {},
      thresholds: {},

      selectedSite: "",
      isLoading: false,
      alarmsDisabled: false,
      aboutToReset: false,
      detectedSiteChange: false,
      saveButtonLoading: false,
      showToggleAlarmsModal: false,
      enableButtonLoading: false,
    };
  },
  watch: {
    selectedSite(newUuid) {
      // If the select wasn't reset
      if (newUuid !== "") {
        let dsite = this.sites.find((u) => u.uuid === newUuid);
        this.alarmsDisabled = !dsite.alarms_enabled;
        this.fetchSiteThresholds(newUuid);
      }
    },
  },

  methods: {
    snakeToStartCase(input) {
      return snakeToStartCase(input);
    },

    async handleConfirmToggleAlarmsModalResponse(confirmed){
      this.showToggleAlarmsModal = false;
      if (!confirmed) {
        return
      }

      if (confirmed) {
        this.enableButtonLoading = true;
        let resp = await API.put(`/site/${this.selectedSite}`, {
          data: { changes: [{ name: "alarms_enabled", value: true }] },
        });

        if (resp.code === 200 && !resp.error.exists) {
          await this.fetchSiteThresholds(this.selectedSite);
          this.alarmsDisabled = false;
          
          this.$buefy.snackbar.open({
            position: "is-top",
            message: "Alarms have been successfully enabled.",
          });
        } else {
          this.errorMessage = resp.error.message;
        }
      }
      this.enableButtonLoading = false;
      await this.fetchSites();
    },

    async fetchSites() {
      this.isLoading = true;
      let response = await API.get("/sites");
      if (!response.error.exists) {
        this.sites = response.data.items.map((s) => {
          return {
            uuid: s.uuid,
            pd_type: s.pd_type,
            device_type: s.device_type,
            alarms_enabled: s.alarms_enabled,
            name: snakeToStartCase(s.name),
            geography: snakeToStartCase(s.geography),
            david_type: snakeToStartCase(s.david_type),
          };
        });
      }
      this.isLoading = false;
    },

    handleSelectResponse(choice) {
      this.selectedSite = choice.uuid;
    },

    fetchChanges() {
      let changes = [];
      for (const [name, limits] of Object.entries(this.thresholds)) {
        let [lowerValue, upperValue] = limits;
        let lowerName = `${name}_lower`;
        let upperName = `${name}_upper`;
        let originalLowerValue = this.originalThresholds[lowerName];
        let originalUpperValue = this.originalThresholds[upperName];

        if (lowerValue === "") {
          lowerValue = originalLowerValue;
          this.thresholds[name][0] = originalLowerValue;
        }

        if (upperValue === "") {
          upperValue = originalUpperValue;
          this.thresholds[name][1] = originalUpperValue;
        }

        if (lowerValue >= upperValue) {
          return [];
        }

        if (lowerValue !== originalLowerValue) {
          changes.push({
            name: lowerName,
            value: lowerValue,
          });
        }
        if (upperValue !== originalUpperValue) {
          changes.push({
            name: upperName,
            value: upperValue,
          });
        }
      }
      return changes;
    },

    async saveHandler() {
      this.saveButtonLoading = true;
      const changes = this.fetchChanges();
      if (changes.length === 0) {
        this.$buefy.snackbar.open({
          message: "No changes were made",
          type: "is-warning",
          pauseOnHover: false,
          position: "is-top",
        });
      } else {
        let ss = this.selectedSite;
        let response = await API.put(`/alarm-threshold/${this.selectedSite}`, {
          data: {
            changes: changes,
          },
        });
        if (response.code === 200 && !response.error.exists) {
          this.$buefy.snackbar.open({
            message: "Alarm threshold has been successfully updated",
            type: "is-success",
            pauseOnHover: false,
            position: "is-top",
          });
          // // Reset
          this.selectedSite = "";
          setTimeout(() => {
            this.selectedSite = ss;
          }, 200);
        } else {
          this.$buefy.snackbar.open({
            message: "An error occured when saving the changes. Try again",
            type: "is-danger",
            pauseOnHover: false,
            position: "is-top",
          });
        }
      }
      this.saveButtonLoading = false;
    },

    async resetHandler(confirmed) {
      if (!confirmed) {
        this.aboutToReset = false;
        return;
      }
      this.saveButtonLoading = true;
      let currentSite = this.selectedSite;
      let response = await API.get(
        `/alarm-threshold/${this.selectedSite}/reset`
      );
      if (response.code === 200 && !response.error.exists) {
        this.$buefy.snackbar.open({
          message: "Alarm threshold has been successfully reset.",
          type: "is-success",
          pauseOnHover: false,
          position: "is-top",
        });
        // // Reset
        this.selectedSite = "";
        setTimeout(() => {
          this.selectedSite = currentSite;
        }, 200);
      } else {
        this.$buefy.snackbar.open({
          message: response.error.message,
          type: "is-danger",
          pauseOnHover: false,
          position: "is-top",
        });
      }
      this.aboutToReset = false;
      this.saveButtonLoading = false;
    },

    async fetchSiteThresholds(uuid) {
      this.isLoading = true;
      this.thresholds = {};
      this.siteThreshold = [];
      this.originalThresholds = {};
      this.detectedSiteChange = false;

      let response = await API.get(`/alarm-threshold/${uuid}`);
      let unitsResponse = await API.get(`/site/${uuid}/units`);

      if (!response.error.exists && !unitsResponse.error.exists) {
        this.originalThresholds = response.data;

        let usefulthresholds = Object.entries(response.data).filter(
          (k) => k[0].indexOf("lower") !== -1 || k[0].indexOf("upper") !== -1
        );
        for (let i = 0; i < usefulthresholds.length; i += 2) {
          let lower = usefulthresholds[i][1]
            ? parseInt(usefulthresholds[i][1])
            : -100;
          let upper = usefulthresholds[i + 1][1]
            ? parseInt(usefulthresholds[i + 1][1])
            : 100;
          let name = usefulthresholds[i][0].slice(0, -6);
          let unit = unitsResponse.data[name];
          let displayName = snakeToStartCase(name);
          this.siteThreshold.push({
            name: name,
            max: upper,
            min: lower,
            displayName: displayName,
            unit: unit.replace("*", "°"),
          });
          this.thresholds[name] = [lower, upper];
        }
      } else {
        this.detectedSiteChange = true;
        this.$buefy.snackbar.open({
          message:
            "Could not fetch the threshold configuration for this site. This occurs most likely when a site's device type or geography has been modified. Reset the threshold to its default template and then try again.",
          position: "is-top",
          type: "is-warning",
          duration: 10000,
        });
      }
      this.isLoading = false;
    },
  },
  beforeUnmount() {
    this.thresholds = {};
    this.siteThreshold = [];
    this.originalThresholds = {};
    this.sites = [];
  },
  mounted() {
    this.fetchSites();
  },
  components: { ConfirmModal, SearcheableSelect },
};
</script>

<style scoped>
.tg>.mdi:before,
.mdi-set {
  color: grey;
  font-size: 40px !important;
}

.slider-input {
  width: 100px;
}
</style>
