<template>
  <section>
    <b-modal :width="568" v-if="show" :active="show">
      <div class="column">
        <div class="card">
          <header class="card-header">
            <p class="card-header-title is-size-6 my-2 has-text-weight-bold">
              Register a new user
            </p>
            <button class="card-header-icon" aria-label="more options">
              <span class="icon">
                <i class="mdi mdi-account-plus mdi-40px" aria-hidden="true"></i>
              </span>
            </button>
          </header>
          <div>
            <b-message v-if="errorMessage != ''" type="is-danger" has-icon>
              {{ errorMessage }}
            </b-message>
          </div>
          <div>
            <b-message
              :auto-close="true"
              :duration="4000"
              v-if="!errorMessage && email.includes('@')"
              type="is-info"
              has-icon
            >
              The user will be sent an email to register on the NMS.
            </b-message>
          </div>
          <div class="card-content pb-1">
            <div class="content">
              <div class="is-flex">
                <div class="column">
                  <section>
                    <b-field label="Affiliated Company">
                      <b-select
                        v-model="company"
                        placeholder="e.g. Zysod Communications Limited"
                        expanded
                        icon="domain"
                      >
                        <option disabled value="">
                          Choose the affiliated company for the user
                        </option>
                        <option
                          :value="company.identifier"
                          :key="index"
                          v-for="(company, index) in companies"
                        >
                          {{ snakeToStartCase(company.name) }}
                        </option>
                      </b-select>
                    </b-field>

                    <b-field label="Access Role">
                      <b-select
                        :disabled="this.roleIsDisabled"
                        v-model="role"
                        placeholder="e.g. Engineer"
                        expanded
                        icon="security"
                      >
                        <option disabled value="">
                          Choose the desired access control for the user
                        </option>
                        <option
                          :value="role.uuid"
                          :key="index"
                          v-for="(role, index) in roles"
                          :disabled="
                            ['mno_user', 'third_party_user', 'user'].includes(
                              role.name
                            )
                          "
                        >
                          {{ snakeToStartCase(role.name) }}
                        </option>
                      </b-select>
                    </b-field>

                    <b-field class="is-capitalized" label="Email Address">
                      <b-input
                        icon="at"
                        type="email"
                        v-model="email"
                        placeholder="e.g. user@mnocompany.com"
                      ></b-input>
                    </b-field>
                  </section>
                </div>
              </div>
            </div>
          </div>
          <footer class="card-footer">
            <b-button
              style="height: 50px"
              @click="$emit('response', false)"
              type="is-light"
              class="is-radiusless card-footer-item"
              >Cancel</b-button
            >
            <b-button
              :loading="confirmLoading"
              style="height: 50px"
              @click="confirmHandler"
              type="is-link"
              class="is-radiusless card-footer-item"
              >Save</b-button
            >
          </footer>
        </div>
      </div>
    </b-modal>
  </section>
</template>

<script>
import {
  API,
  APIMultiRequests,
  snakeToStartCase,
  validateEmail,
} from "@/utils";

export default {
  props: {
    show: {
      default: false,
      type: Boolean,
    },
  },

  data() {
    return {
      roleIsDisabled: true,
      confirmLoading: false,

      errorMessage: "",

      role: "",
      email: "",
      company: "",

      roles: [],
      companies: [],
    };
  },

  watch: {
    company() {
      let companyNotSelected = this.company === "";
      let company = this.companies.find((c) => c.identifier === this.company);

      let companyIsNotInHouse = company ? company.type !== "in_house" : true;
      if (companyNotSelected || companyIsNotInHouse) {
        this.roleIsDisabled = true;

        // Auto select company-related role
        this.role = this.roles.find(
          (r) => r.name === `${company.type}_user`
        ).uuid;
      } else {
        this.roleIsDisabled = false;
        this.role = "";
      }
    },
  },

  async mounted() {
    const dropdownRequests = [
      { mode: "get", url: `/companies` },
      { mode: "get", url: `/roles` },
    ];

    // Make all requests in parallel
    const [companies, roles] = await APIMultiRequests(dropdownRequests);
    this.roles = roles.data.items;
    this.companies = companies.data.items;
  },

  methods: {
    snakeToStartCase(text) {
      return snakeToStartCase(text);
    },

    async registerUser(userData) {
      let response = await API.post("/users", {
        data: userData,
      });
      if (![200, 201].includes(response.code)) {
        this.errorMessage = response.error.message;
        return false;
      }
      return true;
    },

    async confirmHandler() {
      let error = "";
      this.errorMessage = "";
      this.confirmLoading = true;

      this.email = this.email.trim();

      if (this.company === "") {
        error = "A company affiliation has not been selected";
      } else if (this.role === "") {
        error = "A role for the user has not been selected";
      } else if (!validateEmail(this.email)) {
        error = "The emaill address is invalid";
      }

      if (error !== "") {
        this.errorMessage = error;
        this.confirmLoading = false;
        return;
      }

      const userData = {
        email: this.email,
        role_id: this.role,
        company_identifier: this.company,
      };

      let successful = await this.registerUser(userData);
      this.confirmLoading = false;
      if (successful) {
        this.$emit("response", successful);
      }
    },
  },
};
</script>
<style scoped>
.field {
  margin-bottom: 1.4rem !important;
}
</style>
