<template>
  <div>
    <section v-if="isVerified"></section>
    <NotFound v-else />
  </div>
</template>

<script>
import NotFound from "@/components/NotFound.vue";
import { API } from "@/utils";

export default {
  data() {
    return {
      isVerified: false,
      token: "",
    };
  },
  async created() {
    this.isVerified = false;
    let token = this.$router.currentRoute.query.token;
    if (!token) {
      return;
    }
    // Check token from query params
    this.token = token;
    let response = await API.get(`/authwall/verify?token=${this.token}`);
    // Bad token
    if (!response || response.code === 400) {
      this.isVerified = false;
      return;
    }
    // Redirect to login
    this.$router.push("/authwall");
    this.$buefy.snackbar.open({
      message:
        "Your account has been successfully verified. You can log in now.",
      type: "is-success",
      pauseOnHover: false,
      position: "is-top",
      duration: 10000,
    });
  },
  components: { NotFound },
};
</script>

<style scoped></style>
