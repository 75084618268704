<template>
  <div id="chart" style="height: inherit">
    <apexchart
      type="bar"
      height="100%"
      width="100%"
      :options="chartOptions"
      :series="series"
    ></apexchart>
  </div>
</template>

<script>
export default {
  props: {
    categories: {
      type: Array,
      default: () => [],
    },
    series: {
      type: Array,
      default: () => [],
    },
  },
  // watch: {
  //   categories(nc) {
  //     this.options = {
  //       ...this.options,
  //       xaxis: {
  //         categories: nc,
  //       },
  //     };
  //   },
  // },
  data() {
    return {
      chartOptions: {
        chart: {
          type: "bar",
          height: 350,
          stacked: true,
          stackType: "100%",
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "40%",
          },
        },
        stroke: {
          width: 1,
          colors: ["#fff"],
        },
        // title: {
        //   text: "100% Stacked Bar",
        // },
        xaxis: {
          categories: this.categories,
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val;
            },
            // formatter: function (val) {
            //   return val + "K"
            // }
          },
        },
        fill: {
          opacity: 1,
        },
        legend: {
          position: "bottom",
          horizontalAlign: "center",
          offsetX: 0,
        },
      },
    };
  },
};
</script>
<style scoped>
.tg > .mdi:before,
.mdi-set {
  color: grey;
  font-size: 40px !important;
}
</style>
