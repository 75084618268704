import Vue from 'vue'
import App from './App.vue'
import {  PiniaVuePlugin } from 'pinia'
import VueRouter from 'vue-router'
import Buefy from 'buefy'
import 'buefy/dist/buefy.css'
import 'leaflet/dist/leaflet.css';
import { useUserStore, pinia } from './store'
import routes from './routes'
import VueApexCharts from "vue-apexcharts";


Vue.use(Buefy)
Vue.use(PiniaVuePlugin)
Vue.use(VueApexCharts)
Vue.use(require('vue-moment'));

Vue.config.productionTip = false
Vue.component('apexchart', VueApexCharts)

Vue.use(VueRouter)
export const router = new VueRouter({
  // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
  routes, // short for `routes: routes`
  mode: 'history',

})

new Vue({
  pinia,
  router,
  render: h => h(App),
}).$mount('#app')


// GOOD
router.beforeEach((to, from, next) => {
  const store = useUserStore();
  let isAuthenticated = store.getAuthStatus();
  if (to.name !== 'authwall' && !isAuthenticated) next({ name: 'authwall', });
  else next()
})
