import { render, staticRenderFns } from "./AuthwallRegister.vue?vue&type=template&id=6458276b&scoped=true&"
import script from "./AuthwallRegister.vue?vue&type=script&lang=js&"
export * from "./AuthwallRegister.vue?vue&type=script&lang=js&"
import style0 from "./AuthwallRegister.vue?vue&type=style&index=0&id=6458276b&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6458276b",
  null
  
)

export default component.exports