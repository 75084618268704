import NmsApp from './containers/NmsApp.vue'
import NmsHome from './containers/NmsHome.vue'
import NotFound from './components/NotFound.vue'
import Sites from './containers/Sites/Sites.vue'
import MainContentParent from './containers/MainContentParent.vue'
import Site from './containers/Sites/Site/Site.vue'
import Authwall from './containers/Authwall/Authwall.vue'
import AuthwallVerify from './containers/Authwall/AuthwallVerify.vue'
import AuthwallReset from './containers/Authwall/AuthwallReset.vue'
import AuthwallRegister from './containers/Authwall/AuthwallRegister.vue'
import DeploymentsHome from './containers/Deployments/DeploymentsHome.vue';
import AdminHome from './containers/Admin/AdminHome.vue'
import Profile from './containers/Profile/Profile.vue'
import Monitoring from './containers/Monitoring/Monitoring.vue'
import Tickets from './containers/Tickets/Tickets.vue'
import Dashboard from './containers/Dashboard/Dashboard.vue'

// 2. Define some routes
// Each route should map to a component.
// We'll talk about nested routes later.

export default [
  {
    path: '/',
    name: 'app',
    component: NmsApp
  },
  {
    path: '/authwall',
    name: 'authwall',
    component: Authwall
  },
  {
    path: '/authwall/register',
    name: 'authwall-register',
    component: AuthwallRegister
  },
  {
    path: '/authwall/verify',
    name: 'authwall-verify',
    component: AuthwallVerify
  },
  {
    path: '/authwall/reset',
    name: 'authwall-reset',
    component: AuthwallReset
  },

  {
    path: '/us',
    name: 'home',
    component: NmsHome,
    children: [
      // Main navigation
      {
        path: '/us/dashboard',
        name: 'dashboard',
        component: MainContentParent,
        children: [
          {
            path: '/us/dashboard',
            name: 'dashboard',
            component: Dashboard,
          },
        ]
      },
      // Main navigation
      {
        path: '/us/sites',
        name: 'sites',
        component: MainContentParent,
        children: [
          {
            path: '/us/sites/:uuid',
            name: 'site',
            component: Site,
          },
          {
            path: '/us/sites',
            name: 'sites',
            component: Sites,
          },
        ]
      },
      {
        path: '/us/tickets',
        name: 'tickets',
        component: MainContentParent,
        children: [
          {
            path: '/us/tickets',
            name: 'tickets',
            component: Tickets,
          }
        ]
      },
      {
        path: '/us/deployments',
        name: 'deployments',
        component: MainContentParent,
        children: [
          {
            path: '/us/deployments',
            name: 'deployments',
            component: DeploymentsHome,
          }
        ]
      },
      {
        path: '/us/admin',
        name: 'admin',
        component: MainContentParent,

        children: [
          {
            path: '/us/admin',
            name: 'admin',
            component: AdminHome,
          }
        ]
      },

      {
        path: '/us/monitoring',
        name: 'monitoring',
        component: MainContentParent,
        children: [
          {
            path: '/us/monitoring',
            name: 'monitoring',
            component: Monitoring,
          }
        ]
      },
      {
        path: '/us/profile',
        name: 'profile',
        component: MainContentParent,
        children: [
          {
            path: '/us/profile',
            name: 'profile',
            component: Profile,
          }
        ]

      },
      {
        path: '/us/settings',
        name: 'settings',
        component: MainContentParent,
      },
      
    ]
  },

  {
    path: "/:notFound",
    component: NotFound,
  },
]