<template>
  <div class="is-flex">
    <b-loading
      v-if="isLoading"
      :is-full-page="true"
      v-model="isLoading"
      :can-cancel="false"
    ></b-loading>

    <Navbar v-if="!isLoading" />
    <div v-if="!isLoading" style="width: 100%; overflow-y: auto">
      <MainContent />
    </div>

    <vue-internet-checker @status="status" />

    <!-- Internet Check -->
    <b-modal width="25vw" :active="!onLine">
      <div class="card">
        <header class="card-header">
          <p class="card-header-title is-capitalized">No Internet Connection</p>
        </header>

        <div class="card-content">
          <div
            class="is-flex is-flex-direction-column mx-3 is-justify-content-center is-align-items-center"
          >
            <p class="is-align-self-center tg">
              <i
                class="mdi mdi-wifi-strength-off is-no-alarm mdi-40px"
                aria-hidden="true"
              ></i>
            </p>
            <p class="is-size-6 pb-4">
              No internet connection detected. This application will not work
              properly without an active internet connection.
            </p>
          </div>
        </div>

        <footer class="card-footer">
          <b-button
            @click="onLine = !false"
            type="is-link"
            style="height: 48px"
            class="is-radiusless card-footer-item"
            >Acknowledged</b-button
          >
        </footer>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { API } from "@/utils";
import Navbar from "./Navbar.vue";
import { useUserStore } from "@/store";
import MainContent from "./MainContent.vue";
import vueInternetChecker from "vue-internet-checker";

export default {
  components: { Navbar, MainContent, vueInternetChecker },

  async created() {
    this.isLoading = true;
    let response = await API.get("/profile");
    this.isLoading = false;

    if (!response || response.code !== 200) {
      if (this.$route.name !== "authwall");
      {
        this.$router.push({ name: "authwall" });
      }
      this.$buefy.snackbar.open({
        message: "You need to login before accessing that page",
        position: "is-top",
      });
      return;
    }

    // Profile has been gotten
    this.store.updateUserDetails(response.data);
    this.store.updateAuth(true);
  },

  setup() {
    const store = useUserStore();
    return { store };
  },

  methods: {
    status(ele) {
      this.onLine = ele;
    },
  },
  data() {
    return {
      isLoading: false,
      onLine: true,
    };
  },
};
</script>

<style scoped>
a {
  color: #4a4a4a !important;
}
.tg > .mdi:before,
.mdi-set {
  color: grey;
  font-size: 40px !important;
}
</style>
