<template>
  <section>
    <b-modal :width="768" v-if="show" :active="show">
      <div class="column">
        <div class="card">
          <b-loading
            :is-full-page="true"
            v-model="isLoading"
            :can-cancel="true"
          ></b-loading>
          <header class="card-header">
            <p class="card-header-title is-size-6 my-2 has-text-weight-bold">
              Edit Server Configuration
            </p>
            <button class="card-header-icon" aria-label="more options">
              <span class="icon">
                <i class="mdi mdi-pencil mdi-40px" aria-hidden="true"></i>
              </span>
            </button>
          </header>
          <div>
            <b-message class="p-0 m-0" v-if="errorMessage != ''" type="is-danger" has-icon>
              {{ errorMessage }}
            </b-message>
            <b-message class="p-0 m-0" type="is-warning" auto-close :duration="7000" has-icon>
              Be careful! Do not change the value unless you are absolutely
              sure.
            </b-message>
          </div>
          <div class="card-content my-0 pb-0">
            <div class="content">
              <div class="is-flex">
                <div class="column">
                  <section>
                    <b-field class="is-capitalized" label="Name">
                      <b-input
                        icon="label"
                        :disabled="true"
                        v-model="name"
                      ></b-input>
                    </b-field>

                    <b-field class="is-capitalized" label="Value">
                      <b-select
                        v-if="mustSelect"
                        v-model="value"
                        expanded
                        icon="select"
                      >
                        <option disabled value="">
                          Choose the required value
                        </option>
                        <option
                          :key="index"
                          :value="parameter"
                          v-for="(parameter, index) in dropdown"
                        >
                          <span class="is-capitalized">{{
                            snakeToStartCase(parameter)
                          }}</span>
                        </option>
                      </b-select>

                      <b-input v-else icon="label" v-model="value"></b-input>
                    </b-field>

                    <b-field label="Description">
                      <b-input
                        v-model="description"
                        :disabled="true"
                        type="textarea"
                      ></b-input>
                    </b-field>

                    <b-field
                      type="is-danger"
                      :message="
                        confirm.length > 3
                          ? 'Be careful! Invalid changes to the value can cause sites and the NMS to behave incorrectly.'
                          : ''
                      "
                    >
                      <b-input
                        icon-right=" "
                        icon="check"
                        placeholder="Type CONFIRM (in caps) to confirm the changes that were made."
                        v-model="confirm"
                      ></b-input>
                    </b-field>
                  </section>
                </div>
              </div>
            </div>
          </div>
          <footer class="card-footer pt-0">
            <b-button
              style="height: 50px"
              @click="$emit('response', false)"
              type="is-light"
              class="is-radiusless card-footer-item"
              >Cancel</b-button
            >
            <b-button
              :loading="confirmLoading"
              style="height: 50px"
              @click="confirmHandler"
              type="is-link"
              class="is-radiusless card-footer-item"
              >Save</b-button
            >
          </footer>
        </div>
      </div>
    </b-modal>
  </section>
</template>

<script>
import { API, snakeToStartCase } from "@/utils";

const POWER_DEVICE_DETERMINANT = "power_status_parameter";

export default {
  props: {
    show: {
      default: false,
      type: Boolean,
    },
    uuid: String,
  },

  data() {
    return {
      isLoading: false,
      confirmLoading: false,
      name: "",
      value: "",
      dropdown: [],
      confirm: "",
      description: "",
      errorMessage: "",
      mustSelect: false,
    };
  },

  async mounted() {
    this.isLoading = true;
    await this.fetchConfigData();
    this.isLoading = false;
  },
  methods: {
    snakeToStartCase(v) {
      return snakeToStartCase(v);
    },
    async fetchConfigData() {
      let response = await API.get(`/configuration/${this.uuid}`);
      if (![200].includes(response.code)) {
        this.errorMessage = response.error.message;
        return;
      }

      this.name = response.data.name;
      this.value = response.data.value;

      // Device configurations of power status parameters
      this.mustSelect = this.name.includes(POWER_DEVICE_DETERMINANT);
      this.description = response.data.description;

      if (this.mustSelect) {
        let paramKeyIndex = this.name.indexOf(POWER_DEVICE_DETERMINANT) - 1;
        let deviceType = this.name.slice(0, paramKeyIndex);

        // Get the parameters
        let dropdownResponse = await API.get(
          `/alarm-templates?geography=urban&deviceType=${deviceType}`
        );
        if (![200].includes(dropdownResponse.code)) {
          this.errorMessage = dropdownResponse.error.message;
          return;
        }

        // Populate dropdown
        this.dropdown = dropdownResponse.data.parameters;
      }
    },

    async confirmHandler() {
      let error = "";
      this.errorMessage = "";
      this.confirmLoading = true;

      if (this.confirm !== "CONFIRM".toLocaleUpperCase()) {
        error = "Type CONFIRM (in caps) to confirm the changes you made.";
      }

      if (this.value.length === 0) {
        error = "The value entered is empty or invalid";
      }

      if (this.name.length < 1 || this.description.length < 1) {
        error = "The name or description is empty";
      }

      if (error !== "") {
        this.errorMessage = error;
        this.confirmLoading = false;
        return;
      }
      const configData = Object.entries({
        name: this.name.trim(),
        value: this.value.trim(),
        description: this.description,
      }).map((k) => {
        return { name: k[0], value: k[1] };
      });
      let response = await API.put(`/configuration/${this.uuid}`, {
        data: { changes: configData },
      });
      this.confirmLoading = false;
      if (response.code === 200 && !response.error.exists) {
        this.$emit("response", true);
      } else {
        this.errorMessage = response.error.message;
      }
    },
  },
};
</script>
<style scoped>
.field {
  margin-bottom: 1.4rem !important;
}
</style>
