<template>
  <section>
    <b-modal v-if="show" :active="show">
      <div class="column">
        <div class="card">
          <b-loading
            :is-full-page="true"
            v-model="isLoading"
            :can-cancel="true"
          ></b-loading>
          <header class="card-header">
            <p class="card-header-title is-size-6 my-2 has-text-weight-bold">
              Register a new Site
            </p>
            <p class="is-align-self-center pr-4">
              <b-button
                outlined
                type="is-dark"
                title="Import configuration from an NMS export file"
                icon-left="import"
                label="Import File"
                @click="handleImportSite"
              ></b-button>
            </p>
          </header>
          <div>
            <b-message v-if="errorMessage != ''" type="is-danger" has-icon>
              {{ errorMessage }}
            </b-message>
          </div>
          <div class="card-content pb-1">
            <div class="content">
              <div class="is-flex">
                <div class="column">
                  <section>
                    <b-field label="Country of Deployment">
                      <b-select
                        v-model="country"
                        :disabled="true"
                        expanded
                        icon="flag"
                      >
                        <option selected value="">Nigeria</option>
                      </b-select>
                    </b-field>
                    <b-field class="is-capitalized" label="Site Name">
                      <b-input
                        icon="label"
                        v-model="name"
                        placeholder="e.g. Tejuosho"
                      ></b-input>
                    </b-field>

                    <b-field label="Model Number">
                      <b-input
                        icon="pound"
                        v-model="modelNumber"
                        placeholder="e.g. ZCT-BSLW30DR-F4"
                      ></b-input>
                    </b-field>
                    <b-field label="Latitude">
                      <b-input
                        icon="latitude"
                        type="text"
                        v-model="latitude"
                        placeholder="e.g. 6.34567"
                      ></b-input>
                    </b-field>
                    <b-field label="Affiliated Company">
                      <SearcheableSelect 
                        @selected="(c) => company = c.uuid" 
                        icon="domain"
                        placeholder="Choose the affiliated company" 
                        :dataSource="renderedCompanies" 
                      />
                    </b-field>
                    <b-field label="Geography">
                      <SearcheableSelect 
                        @selected="(c) => geography = c.name" 
                        icon="map-marker-radius"
                        placeholder="Choose the site's geography" 
                        :dataSource="geographies" 
                        :title="
                          deviceType !== 'david' ? 'Device is not a David' : ''
                        "
                      />
                    </b-field>
                    <b-field label="David Type">
                      <SearcheableSelect 
                        @selected="(c) => davidType = c.name" 
                        icon="select-compare"
                        placeholder="Choose the David type" 
                        :dataSource="davidTypes" 
                        :disabled="deviceType !== 'david' ? true : false"
                        :title="
                          deviceType !== 'david' ? 'Device is not a David' : ''
                        "
                      />
                    </b-field>
                  </section>
                </div>
                <div class="column">
                  <section>
                    <b-field label="Deployment Medium">
                      <b-select
                        v-model="deploymentMedium"
                        placeholder="e.g. Arduino"
                        expanded
                        icon="map-marker-path"
                      >
                        <option disabled value="">
                          Choose the deployment medium
                        </option>
                        <option
                          :key="dep.name"
                          v-for="dep in deploymentMedia"
                          :value="dep.name"
                        >
                          <span class="is-capitalized">{{
                            snakeToStartCase(dep.name)
                          }}</span>
                        </option>
                      </b-select>
                    </b-field>
                    <b-field label="Site Number">
                      <b-input
                        icon="identifier"
                        type="number"
                        v-model="number"
                        placeholder="e.g. 20230202"
                      ></b-input>
                    </b-field>
                    <b-field label="Serial Number">
                      <b-input
                        icon="pound"
                        v-model="serialNumber"
                        placeholder="e.g. ZC011A"
                      ></b-input>
                    </b-field>
                    <b-field label="Longitude">
                      <b-input
                        type="text"
                        icon="longitude"
                        v-model="longitude"
                        placeholder="e.g. 3.45678"
                      ></b-input>
                    </b-field>
                    <b-field label="Associated Network">
                      <b-select
                        class="is-capitalized"
                        v-model="network"
                        :disabled="company === ''"
                                                :title="
                          company === ''
                            ? 'First choose the Affiliated Company'
                            : ''
                        "
                        placeholder="e.g. Airtel"
                        expanded
                        icon="network-strength-2"
                      >
                        <option disabled value="">
                          Choose the site's network
                        </option>

                        <option
                          :key="net.name"
                          v-for="net in renderedNetworks"
                          :value="net.uuid"
                        >
                          {{ snakeToStartCase(net.name) }}
                        </option>
                      </b-select>
                    </b-field>
                    <b-field label="Device Type">
                      <SearcheableSelect @selected="(c) => deviceType = c.name" icon="select-compare"
                        placeholder="Choose the device type" :dataSource="deviceTypes" />
                    </b-field>
                    <b-field label="Power Device Type">
                      <SearcheableSelect @selected="(c) => pdType = c.name" 
                        :disabled="deviceType !== 'power_device' ? true : false" icon="select-compare"
                        placeholder="Choose the Power Device type" :dataSource="powerDeviceTypes"
                        :title="
                          deviceType !== 'power_device'
                            ? 'Device is not a Power Device'
                            : ''"
                        />
                    </b-field>
                  </section>
                </div>
              </div>
            </div>
          </div>
          <footer class="card-footer">
            <b-button
              style="height: 50px"
              @click="$emit('response', false)"
              type="is-light"
              class="is-radiusless card-footer-item"
              >Cancel</b-button
            >
            <b-button
              :loading="confirmLoading"
              style="height: 50px"
              @click="confirmHandler"
              type="is-link"
              class="is-radiusless card-footer-item"
              >Save</b-button
            >
          </footer>
        </div>
      </div>
    </b-modal>
  </section>
</template>

<script>
import SearcheableSelect from "@/components/SearcheableSelect.vue";
import { snakeToStartCase, APIMultiRequests, API } from "@/utils";

export default {
    props: {
        show: {
            default: false,
            type: Boolean,
        },
    },
    watch: {
        company(newCompanyId) {
            let comp = this.companies.find((c) => c.uuid === newCompanyId);
            // MNO companies must have the network be directly
            // associated with them. This is to fulfil the requ
            // irement that a site affiliated with MTN must have
            // its network as MTN, and can never be any other network
            if (comp.type === "mno") {
                this.network = "";
                this.renderedNetworks = this.networks.filter((n) => comp.network_id_name === n.name);
            }
            // ThirdParty company types must have their
            // deployment geography limited to Rural only
            else if (comp.type === "third_party") {
                this.geography = "rural";
                this.renderedNetworks = this.networks;
            }
            // Shouldn't ever reach here, ideally
            else {
                this.renderedNetworks = this.networks;
            }
        },
    },
    data() {
        return {
            isLoading: false,
            confirmLoading: false,
            companies: [],
            networks: [],
            deploymentMedia: [],
            deviceTypes: [],
            geographies: [],
            powerDeviceTypes: [],
            davidTypes: [],
            errorMessage: "",
            renderedNetworks: [],
            renderedCompanies: [],
            name: "",
            number: "",
            country: "",
            latitude: "",
            longitude: "",
            network: "",
            company: "",
            geography: "",
            pdType: "",
            modelNumber: "",
            serialNumber: "",
            deviceType: "",
            davidType: "",
            deploymentType: "",
            deploymentMedium: "",
        };
    },
    mounted() {
        this.fetchDropdownData();
    },
    methods: {
        snakeToStartCase(input) {
            return snakeToStartCase(input);
        },
        handleImportSite() {
            var input = document.createElement("input");
            input.type = "file";
            input.onchange = (e) => {
                // getting a hold of the file reference
                this.errorMessage = "";
                let file = e.target.files[0];
                if (file.size >= 2000) {
                    this.errorMessage =
                        "InvalidFileError: This file cannot be used to register a site.";
                    return;
                }
                // setting up the reader
                let reader = new FileReader();
                reader.readAsText(file, "UTF-8");
                // here we tell the reader what to do when it's done reading...
                reader.onload = (readerEvent) => {
                    var content = readerEvent.target.result; // this is the content!
                    try {
                        content = JSON.parse(content);
                        let isValid = true;
                        for (const key of [
                            "david_type",
                            "pd_type",
                            "name",
                            "number",
                            "latitude",
                            "longitude",
                            "geography",
                            "device_type",
                            "serial_number",
                            "model_number",
                            "deployment_type",
                            "deployment_medium",
                        ]) {
                            if (key in content) {
                                continue;
                            }
                            else {
                                isValid = false;
                                break;
                            }
                        }
                        if (!isValid) {
                            this.errorMessage =
                                "InvalidFileError: This file cannot be used to register a site.";
                        }
                        else {
                            this.pdType =
                                content.pd_type === null
                                    ? content.pd_type
                                    : content.pd_type.toString();
                            this.name =
                                content.name === null ? content.name : content.name.toString();
                            this.number =
                                content.number === null
                                    ? content.number
                                    : content.number.toString();
                            this.latitude =
                                content.latitude === null
                                    ? content.latitude
                                    : content.latitude.toString();
                            this.longitude =
                                content.longitude === null
                                    ? content.longitude
                                    : content.longitude.toString();
                            this.geography =
                                content.geography === null
                                    ? content.geography
                                    : content.geography.toString();
                            this.deploymentMedium =
                                content.deployment_medium === null
                                    ? content.deployment_medium
                                    : content.deployment_medium.toString();
                            this.serialNumber =
                                content.serial_number === null
                                    ? content.name
                                    : content.serial_number.toString();
                            this.modelNumber =
                                content.model_number === null
                                    ? content.name
                                    : content.model_number.toString();
                            this.davidType =
                                content.david_type === null
                                    ? content.name
                                    : content.david_type.toString();
                            this.deviceType =
                                content.device_type === null
                                    ? content.name
                                    : content.device_type.toString();
                        }
                    }
                    catch {
                        this.errorMessage =
                            "InvalidFileError: This file cannot be used to register a site.";
                    }
                };
            };
            input.click();
        },
        async registerSite(siteData) {
            let response = await API.post("/sites", {
                data: siteData,
            });
            if (![200, 201].includes(response.code)) {
                this.errorMessage = response.error.message;
                return false;
            }
            return true;
        },
        async confirmHandler() {
            let error = "";
            this.errorMessage = "";
            this.confirmLoading = true;
            let name = this.name.trim();
            let number = this.number.trim();
            if (!this.deploymentMedia.map((m) => m.name).includes(this.deploymentMedium)) {
                error = "No deployment medium selected";
            }
            else if (name.length < 3 || name.length > 20) {
                error = "The site name must be between 3 and 20 characters";
            }
            else if (!name.isAlphaNumeric()) {
                error =
                    "The site name must be alphanumeric without any space in-between";
            }
            else if (number.length !== 8 || isNaN(number)) {
                error = "The site number must be an eight-digit number";
            }
            else if (parseInt(number.slice(6, 8)) < 1 ||
                parseInt(number.slice(6, 8)) > 34) {
                error =
                    "The last two digits of the site number must be between 01 and 34";
            }
            else if (this.modelNumber.length < 5 || this.modelNumber.length > 20) {
                error = "The model number must be between 5 and 20 characters";
            }
            else if (this.serialNumber.length < 5 ||
                this.serialNumber.length > 20) {
                error = "The serial number must be between 5 and 20 characters";
            }
            else if (this.latitude.length < 5 ||
                isNaN(this.latitude) ||
                this.latitude.indexOf(".") === -1) {
                error = "The latitude value is invalid";
            }
            else if (this.longitude.length < 5 ||
                isNaN(this.longitude) ||
                this.longitude.indexOf(".") === -1) {
                error = "The longitude value is invalid";
            }
            else if (!this.companies.map((m) => m.uuid).includes(this.company)) {
                error = "No affiliated company selected";
            }
            else if (!this.networks.map((m) => m.uuid).includes(this.network)) {
                error = "No affiliated network selected";
            }
            else if (!this.geographies.map((m) => m.name).includes(this.geography)) {
                error = "No geography selected";
            }
            else if (
            // Check if selected company's type is third_party, and
            // that the selected geography is Rural
            this.companies.find((c) => c.uuid === this.company).type ===
                "third_party" &&
                this.geography !== "rural") {
                error = "Third Party Companies must be deployed with Rural geography";
            }
            else if (!this.deviceTypes.map((m) => m.name).includes(this.deviceType)) {
                error = "No device type selected";
            }
            else if (this.deviceType === "david" &&
                !this.davidTypes.map((m) => m.name).includes(this.davidType)) {
                error = "No david type selected";
            }
            else if (this.deviceType === "power_device" &&
                !this.powerDeviceTypes.map((m) => m.name).includes(this.pdType)) {
                error = "No power device type selected";
            }
            else if (this.deviceType === "david" &&
                !this.modelNumber.toLocaleUpperCase().includes("L") &&
                !this.modelNumber.toLocaleUpperCase().includes("D") &&
                !this.modelNumber.toLocaleUpperCase().includes("W")) {
                error = "The model number is invalid for a David";
            }
            if (error !== "") {
                this.errorMessage = error;
                this.confirmLoading = false;
                return;
            }
            const siteData = {
                number: this.number.trim(),
                name: this.name.trim().toLocaleLowerCase(),
                // country: "",
                networkId: this.network,
                companyId: this.company,
                geography: this.geography,
                deviceType: this.deviceType,
                latitude: this.latitude.trim(),
                longitude: this.longitude.trim(),
                deploymentMedium: this.deploymentMedium,
                deploymentType: `${this.deviceType}_only`,
                pdType: this.deviceType === "david" ? null : this.pdType,
                modelNumber: this.modelNumber.trim().toLocaleUpperCase(),
                serialNumber: this.serialNumber.trim().toLocaleUpperCase(),
                davidType: this.deviceType === "power_device" ? null : this.davidType,
            };
            let successful = await this.registerSite(siteData);
            if (successful) {
                this.$emit("response", successful);
            }
            this.confirmLoading = false;
        },
        async fetchDropdownData() {
            const dropdownRequests = [
                { mode: "get", url: `/companies` },
                { mode: "get", url: `/networks` },
                { mode: "get", url: `/device-types` },
                {
                    mode: "get",
                    url: `/deployment-media`,
                },
                { mode: "get", url: `/geographies` },
                { mode: "get", url: `/david-types` },
                {
                    mode: "get",
                    url: `/power-device-types`,
                },
            ];
            // Make all requests in parallel
            const dropdownResponses = await APIMultiRequests(dropdownRequests);
            const [companies, networks, deviceTypes, deploymentMedia, geographies, davidTypes, powerDeviceTypes,] = dropdownResponses;
            // Populate in state
            this.networks = networks.data.items;
            this.companies = companies.data.items;
            this.renderedNetworks = networks.data.items;
            this.renderedCompanies = companies.data.items;
            this.davidTypes = davidTypes.data.items;
            this.geographies = geographies.data.items;
            this.deviceTypes = deviceTypes.data.items;
            this.deploymentMedia = deploymentMedia.data.items;
            this.powerDeviceTypes = powerDeviceTypes.data.items;
        },
    },
    components: { SearcheableSelect }
};
</script>
<style scoped>
.field {
  margin-bottom: 1.4rem !important;
}
</style>
