<template>
  <div class="column">
    <div class="card">
      <b-loading
        :is-full-page="false"
        v-model="isLoading"
        :can-cancel="true"
      ></b-loading>
      <header class="card-header">
        <p class="card-header-title is-size-6 my-2 has-text-weight-bold">
          Editing site configuration for
          <span class="is-capitalized pl-1 has-text-grey">{{ name }}</span>
        </p>
        <button class="card-header-icon" aria-label="more options">
          <span class="icon">
            <i
              class="mdi mdi-information-outline mdi-40px"
              aria-hidden="true"
            ></i>
          </span>
        </button>
      </header>
      <div>
        <b-message v-if="errorMessage != ''" type="is-danger" has-icon>
          {{ errorMessage }}
        </b-message>
      </div>
      <div class="content mb-0">
        <div class="card-content pb-0">
          <b-field class="px-3" label="Deployment Medium">
            <b-select
              v-model="deploymentMedium"
              placeholder="e.g. Arduino"
              expanded
              icon="map-marker-path"
            >
              <option disabled value="">Choose a deployment medium</option>
              <option
                :key="dep.name"
                v-for="dep in deploymentMedia"
                :value="dep.name"
              >
                <span class="is-capitalized">{{
                  snakeToStartCase(dep.name)
                }}</span>
              </option>
            </b-select>
          </b-field>

          <div class="is-flex">
            <div class="column">
              <section>
                <b-field class="is-capitalized" label="Site Name">
                  <b-input
                    icon="label"
                    v-model="name"
                    placeholder="e.g. Tejuosho"
                  ></b-input>
                </b-field>

                <b-field label="Model Number">
                  <b-input
                    icon="pound"
                    v-model="modelNumber"
                    placeholder="e.g. ZCT-BSLW30DR-F4"
                  ></b-input>
                </b-field>
                <b-field label="Latitude">
                  <b-input
                    icon="latitude"
                    type="text"
                    v-model="latitude"
                    placeholder="e.g. 6.34567"
                  ></b-input>
                </b-field>
                <b-field label="Affiliated Company">
                  <SearcheableSelect
                        @selected="(c) => company = c.uuid" 
                        icon="domain"
                        :defaultChoice="selectedCompany"
                        placeholder="Choose the affiliated company" 
                        :dataSource="companies" 
                      />
                </b-field>
                <b-field label="Geography">
                  <b-select
                    v-model="geography"
                    placeholder="e.g. Rural"
                    expanded
                    icon="map-marker-radius"
                  >
                    <option
                      :key="geo.name"
                      v-for="geo in geographies"
                      :value="geo.name"
                    >
                      <span class="is-capitalized">{{
                        snakeToStartCase(geo.name)
                      }}</span>
                    </option>
                  </b-select>
                </b-field>
                <b-field label="David Type">
                  <b-select
                    v-model="davidType"
                    :disabled="deviceType !== 'david' ? true : false"
                    :title="
                      deviceType !== 'david' ? 'Device is not a David' : ''
                    "
                    placeholder="e.g. Outdoor"
                    expanded
                    icon="select-compare"
                  >
                    <option
                      :key="dave.name"
                      v-for="dave in davidTypes"
                      :value="dave.name"
                    >
                      <span class="is-capitalized">{{
                        snakeToStartCase(dave.name)
                      }}</span>
                    </option>
                  </b-select>
                </b-field>
              </section>
            </div>
            <div class="column">
              <section>
                <b-field label="Site Number">
                  <b-input
                    icon="identifier"
                    type="number"
                    v-model="number"
                    placeholder="e.g. 20230202"
                  ></b-input>
                </b-field>
                <b-field label="Serial Number">
                  <b-input
                    icon="pound"
                    v-model="serialNumber"
                    placeholder="e.g. ZC011A"
                  ></b-input>
                </b-field>
                <b-field label="Longitude">
                  <b-input
                    type="text"
                    icon="longitude"
                    v-model="longitude"
                    placeholder="e.g. 3.45678"
                  ></b-input>
                </b-field>
                <b-field label="Associated Network">
                  <b-select
                    class="is-capitalized"
                    v-model="network"
                    :disabled="company === ''"
                    :title="
                      company === ''
                        ? 'First choose the Affiliated Company'
                        : ''
                    "
                    placeholder="e.g. Airtel"
                    expanded
                    icon="network-strength-2"
                  >
                    <option
                      :key="net.name"
                      v-for="net in renderedNetworks"
                      :value="net.uuid"
                    >
                      {{ snakeToStartCase(net.name) }}
                    </option>
                  </b-select>
                </b-field>
                <b-field label="Device Type">
                  <b-select
                    v-model="deviceType"
                    placeholder="e.g. Power Device"
                    expanded
                    icon="select-compare"
                  >
                    <option
                      :key="dev.name"
                      v-for="dev in deviceTypes"
                      :value="dev.name"
                    >
                      <span class="is-capitalized">{{
                        snakeToStartCase(dev.name)
                      }}</span>
                    </option>
                  </b-select>
                </b-field>
                <b-field label="Power Device Type">
                  <b-select
                    v-model="pdType"
                    :disabled="deviceType !== 'power_device' ? true : false"
                    :title="
                      deviceType !== 'power_device'
                        ? 'Device is not a Power Device'
                        : ''
                    "
                    placeholder="e.g. Greencisco 4000W 48V 201901 Inverter"
                    expanded
                    icon="select-compare"
                  >
                    <option
                      :key="pd.name"
                      v-for="pd in powerDeviceTypes"
                      :value="pd.name"
                    >
                      <span class="is-capitalized">{{
                        snakeToStartCase(pd.name)
                      }}</span>
                    </option>
                  </b-select>
                </b-field>
              </section>
            </div>
          </div>
        </div>
      </div>
      <footer class="card-footer">
        <b-button
          style="height: 50px"
          @click="$emit('response', false)"
          type="is-light"
          class="is-radiusless card-footer-item"
          >Cancel</b-button
        >
        <b-button
          style="height: 50px"
          :loading="confirmLoading"
          @click="confirmHandler"
          type="is-link"
          class="is-radiusless card-footer-item"
          >Save</b-button
        >
      </footer>
    </div>
  </div>
</template>

<script>
// import { useUserStore } from '@/store';
import { API, snakeToStartCase, APIMultiRequests } from "@/utils";
import SearcheableSelect from "@/components/SearcheableSelect.vue";
export default {
  props: {
    uuid: String,
  },

  data() {
    return {
      isLoading: false,
      companies: [],
      networks: [],
      deploymentMedia: [],
      deviceTypes: [],
      geographies: [],
      powerDeviceTypes: [],
      davidTypes: [],

      selectedCompany: {},

      errorMessage: "",
      renderedNetworks: [],
      renderedCompanies: [],
      confirmLoading: false,

      name: "...",
      number: "",
      latitude: "",
      longitude: "",
      network: "",
      company: "",
      geography: "",
      pdType: "",
      modelNumber: "",
      serialNumber: "",
      deviceType: "",
      davidType: "",
      deploymentType: "",
      deploymentMedium: "Arduino",
    };
  },
  watch: {
    async uuid() {
      this.isLoading = true;
      this.errorMessage = "";
      await this.fetchSiteData();
      this.isLoading = false;
    },
    company(newCompanyId) {
      let comp = this.companies.find((c) => c.uuid === newCompanyId);
      if (comp.type === "mno") {
        this.renderedNetworks = this.networks.filter(
          (n) => comp.network_id_name === n.name
        );
        this.network = this.renderedNetworks[0].uuid;
      } else {
        this.renderedNetworks = this.networks;
      }
    },
  },
  async created() {
    this.isLoading = true;
    await this.fetchDropdownData();
    await this.fetchSiteData();
    this.isLoading = false;
  },

  methods: {
    snakeToStartCase(input) {
      return snakeToStartCase(input);
    },
    async updateSite(siteData) {
      let response = await API.put(`/site/${this.uuid}`, {
        data: {
          changes: Object.entries(siteData).map((k) => {
            return { name: k[0], value: k[1] };
          }),
        },
      });
      if (![200, 201].includes(response.code)) {
        this.errorMessage = response.error.message;
        return false;
      }
      return true;
    },
    async confirmHandler() {
      let error = "";
      this.errorMessage = "";
      this.confirmLoading = true;

      let name = this.name.trim();
      let number = this.number.trim();
      if (
        !this.deploymentMedia.map((m) => m.name).includes(this.deploymentMedium)
      ) {
        error = "No deployment medium selected";
      } else if (name.length < 3 || name.length > 20) {
        error = "The site name must be between 3 and 20 characters";
      } else if (!name.isAlphaNumeric()) {
        error =
          "The site name must be alphanumeric without any space in-between";
      } else if (number.length !== 8 || isNaN(number)) {
        error = "The site number must be an eight-digit number";
      } else if (
        parseInt(number.slice(6, 8)) < 1 ||
        parseInt(number.slice(6, 8)) > 34
      ) {
        error =
          "The last two digits of the site number must be between 01 and 34";
      } else if (this.modelNumber.length < 5 || this.modelNumber.length > 20) {
        error = "The model number must be between 5 and 20 characters";
      } else if (
        this.serialNumber.length < 5 ||
        this.serialNumber.length > 20
      ) {
        error = "The serial number must be between 5 and 20 characters";
      } else if (
        this.latitude.length < 5 ||
        isNaN(this.latitude) ||
        this.latitude.toString().indexOf(".") === -1
      ) {
        error = "The latitude value is invalid";
      } else if (
        this.longitude.length < 5 ||
        isNaN(this.longitude) ||
        this.longitude.toString().indexOf(".") === -1
      ) {
        error = "The longitude value is invalid";
      } else if (!this.companies.map((m) => m.uuid).includes(this.company)) {
        error = "No affiliated company selected";
      } else if (!this.networks.map((m) => m.uuid).includes(this.network)) {
        error = "No affiliated network selected";
      } else if (
        !this.geographies.map((m) => m.name).includes(this.geography)
      ) {
        error = "No geography selected";
      } else if (
        !this.deviceTypes.map((m) => m.name).includes(this.deviceType)
      ) {
        error = "No device type selected";
      } else if (
        this.deviceType === "david" &&
        !this.davidTypes.map((m) => m.name).includes(this.davidType)
      ) {
        error = "No david type selected";
      } else if (
        this.deviceType === "power_device" &&
        !this.powerDeviceTypes.map((m) => m.name).includes(this.pdType)
      ) {
        error = "No power device type selected";
      } else if (
        this.deviceType === "david" &&
        !this.modelNumber.toLocaleUpperCase().includes("L") &&
        !this.modelNumber.toLocaleUpperCase().includes("D") &&
        !this.modelNumber.toLocaleUpperCase().includes("W")
      ) {
        error = "The model number is invalid for a David";
      }

      if (error !== "") {
        this.errorMessage = error;
        this.confirmLoading = false;
        return;
      }

      const siteData = {
        number: this.number.trim(),
        name: this.name.trim().toLocaleLowerCase(),

        // country: "",
        networkId: this.network,
        companyId: this.company,
        geography: this.geography,
        deviceType: this.deviceType,
        latitude: this.latitude.toString().trim(),
        longitude: this.longitude.toString().trim(),
        deploymentMedium: this.deploymentMedium,
        deploymentType: `${this.deviceType}_only`,
        pdType: this.deviceType === "david" ? null : this.pdType,
        modelNumber: this.modelNumber.trim().toLocaleUpperCase(),
        serialNumber: this.serialNumber.trim().toLocaleUpperCase(),
        davidType: this.deviceType === "power_device" ? null : this.davidType,
      };

      let successful = await this.updateSite(siteData);
      if (successful) {
        this.$emit("response", successful);
      }
      this.confirmLoading = false;
    },
    async fetchDropdownData() {
      const dropdownRequests = [
        { mode: "get", url: `/companies` },
        { mode: "get", url: `/networks` },
        { mode: "get", url: `/device-types` },
        {
          mode: "get",
          url: `/deployment-media`,
        },
        { mode: "get", url: `/geographies` },
        { mode: "get", url: `/david-types` },
        {
          mode: "get",
          url: `/power-device-types`,
        },
      ];

      // Make all requests in parallel
      const dropdownResponses = await APIMultiRequests(dropdownRequests);
      const [
        companies,
        networks,
        deviceTypes,
        deploymentMedia,
        geographies,
        davidTypes,
        powerDeviceTypes,
      ] = dropdownResponses;

      // Populate in state
      this.networks = networks.data.items;
      this.companies = companies.data.items;
      this.davidTypes = davidTypes.data.items;
      this.geographies = geographies.data.items;
      this.deviceTypes = deviceTypes.data.items;
      this.deploymentMedia = deploymentMedia.data.items;
      this.powerDeviceTypes = powerDeviceTypes.data.items;
    },
    async fetchSiteData() {
      this.selectedCompany = {};
      let response = await API.get(`/site/${this.uuid}`);
      if (response.code === 200 && !response.error.exists) {
        this.name = response.data.name;
        this.number = response.data.number;
        this.latitude = response.data.latitude;
        this.longitude = response.data.longitude;
        this.geography = response.data.geography;
        this.pdType = response.data.pd_type;
        this.davidType = response.data.david_type;
        this.deploymentMedium = response.data.deployment_medium;
        this.serialNumber = response.data.serial_number;
        this.modelNumber = response.data.model_number;
        this.deviceType = response.data.device_type;
        this.renderedNetworks = this.networks;
        // Order matters!
        this.selectedCompany = this.companies.find(
          (c) => c.identifier === response.data.company.identifier
        )
        this.company = this.selectedCompany.uuid;
        this.network = this.networks.find(
          (n) => n.name === response.data.network.name
        ).uuid;
      }
    },
  },
  components: { SearcheableSelect }
};
</script>
<style scoped>
.field {
  margin-bottom: 1.4rem !important;
}
select {
  width: 100%;
  text-transform: capitalize !important;
}
</style>
