<template>
  <div :style="`width: ${width};`">
    <apexchart
      type="donut"
      :options="chartOptions"
      :series="series"
    ></apexchart>
  </div>
</template>

<script>
export default {
  props: {
    width: {
      type: String,
      default: "300px",
    },
    series: {
      type: Array,
      default: () => [],
    },
    labels: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      chartOptions: {
        legend: {
          position: "bottom",
          horizontalAlign: "center",
          offsetY: 5,
        },
        dataLabels: {
          enabled: false,
          dropShadow: {
            opacity: 0.29,
          },
          // formatter: function (val) {
          //   return val + "%";
          // },
          background: {
            enabled: false,
            // dropShadow: {
            //   opacity: 1
            // }
          },

          style: {
            fontSize: "10px",

            fontWeight: "10",
            colors: ["white"],
          },
        },
        chart: {
          type: "donut",
          width: "100%",

          sparkline: {
            enabled: false,
          },
        },

        plotOptions: {
          pie: {
            dataLabels: {
              enabled: false,
              // offset: -10,
            },

            donut: {
              size: "55%",
            },
            labels: {
              show: false,
            },
          },
        },
        labels: this.labels,
        responsive: [
          {
            breakpoint: 1180,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },
    };
  },
};
</script>
<style scoped>
.tg > .mdi:before,
.mdi-set {
  color: grey;
  font-size: 40px !important;
}
</style>
