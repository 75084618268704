<template>
  <div class="is-fullwidth is-flex is-flex-direction-column is-flex-grow-2">
    <div class="tabs is-left is-full is-size-7 mb-4">
      <ul>
        <li
          class="mx-0 mr-4"
        >
        </li>
      </ul>
    </div>


    <!-- Active alarm Data -->
    <div class="is-flex-grow-2">
      <div class="card">
        <header class="card-header is-danger p-2">
          <p class="card-header-title is-size-6">Customer Tickets</p>
          <button class="card-header-icon" aria-label="more options">
            <span class="icon">
              <i
                class="mdi mdi-tag-outline mdi-40px"
                aria-hidden="true"
              ></i>
            </span>
          </button>
        </header>
        <div
          class="card-content is-flex is-align-items-center is-justify-content-center"
          style="height: 500px"
        >
          <div class="is-flex is-flex-direction-column pb-6">
            <div
              class="is-flex is-align-items-center is-justify-content-center"
            >
              <div class="is-flex">
                <p class="is-align-self-center tg">
                  <i
                    class="mdi mdi-face-agent mdi-40px"
                    aria-hidden="true"
                  ></i>
                </p>
              </div>
            </div>
            <p class="is-size-6 has-text-grey">
              No customer support tickets have been created.
            </p>
          </div>
        </div>
      </div>

      <!-- Metadata -->
      <div
        class="is-size-7 mt-5 has-text-grey has-text-centered"
      >
        Last updated: {{ lastPing || "Just now" }}.
      </div>
    </div>
  </div>
</template>

<script>
import { useUserStore } from "@/store";
const module = "/ Tickets";

export default {
  setup() {
    const store = useUserStore();
    return { store };
  },
  data() {
    return {
      lastPing: "",
    };
  },
  methods: {},

  async mounted() {
    this.pingIntervalId = setInterval(async () => {
      this.lastPing = new Date().toLocaleString();
    }, 7000);

    this.store.updateTopBar(`${module}`);
  },

  beforeDestroy() {
    clearInterval(this.pingIntervalId);
  },
};
</script>
<style scoped>
.tg > .mdi:before,
.mdi-set {
  color: grey;
  font-size: 40px !important;
}
</style>
