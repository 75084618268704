<template>
  <section
    v-if="!isAuthenticated"
    class="section is-flex is-flex-direction-column is-align-items-center is-align-content-center is-justify-content-center"
  >
    <img class="mb-5" alt="Vue logo" src="@/assets/images/zysod/zysod.png" />

    <Notification
      v-if="showErrorNotification"
      @x-clicked="showErrorNotification = false"
      :text="errorMessage"
    />

    <div class="is-flex is-flex-direction-column" v-if="!showForgotPassword">
      <div class="card is-align-self-center px-2 mb-3">
        <div class="card-content">
          <b-field class="is-size-7 mb-5 mt-2" label="Email Address">
            <b-input
              required
              placeholder="you@zysod.net"
              v-model="email"
              type="email"
              icon="at"
            >
            </b-input>
          </b-field>
          <b-field label="Password" class="mb-3">
            <b-input
              required
              type="password"
              v-model="password"
              placeholder="Your password"
              password-reveal
              icon="key"
            >
            </b-input>
          </b-field>
          <b-field class="pt-4 pb-2">
            <b-button
              class="vbtn"
              expanded
              type="is-info"
              :loading="buttonLoading"
              @click="signInHandler"
              >Authenticate</b-button
            >
            <!-- icon-left="key" -->
          </b-field>
        </div>
      </div>
      <div class="is-align-self-center has-text-grey is-size-6 pt-0">
        <a @click="forgotPasswordHandler(true)">Forgot Password?</a>
      </div>
    </div>

    <div class="is-flex is-flex-direction-column" v-else>
      <div class="card is-align-self-center px-2">
        <div class="card-content">
          <b-field class="is-size-7 mb-3 mt-2" label="Email Address">
            <b-input
              required
              placeholder="you@zysod.net"
              v-model="email"
              type="email"
              icon="at"
            >
            </b-input>
          </b-field>

          <b-field class="pt-3 pb-2">
            <b-button
              class="vbtn"
              expanded
              type="is-info"
              :disabled="resetLinkSent"
              :loading="buttonLoading"
              @click="resetHandler"
              >{{
                !resetLinkSent
                  ? "Send me a Reset Link"
                  : "Reset Link already sent"
              }}</b-button
            >
            <!-- icon-left="key" -->
          </b-field>
        </div>
      </div>
      <div class="is-align-self-center has-text-grey is-size-6 pt-0">
        <a @click="forgotPasswordHandler(false)">Cancel</a>
      </div>
    </div>

    <div class="has-text-grey is-size-7 pt-6">
      <p>
        Ⓒ {{ currentYear }}, Zysod Communications Limited.
      </p>
    </div>
  </section>
</template>

<script>
import { API } from "@/utils";
import Notification from "@/components/Notification.vue";
import { useUserStore } from "@/store";
import { validateEmail } from "@/utils";

export default {
  setup() {
    const store = useUserStore();
    return { store };
  },

  components: {
    //   Button,
    Notification,
  },
  data() {
    return {
      currentYear: new Date().getFullYear(),

      email: "",
      password: "",
      errorMessage: "",
      buttonLoading: false,
      resetLinkSent: false,
      showErrorNotification: false,
      isAuthenticated: true,
      showForgotPassword: false,
    };
  },
  async created() {
    // Check token in local storage
    let response = await API.get("/profile");

    if (!response || response.code !== 200) {
      this.isAuthenticated = false;
      return;
    }

    // Profile has been gotten
    this.store.updateUserDetails(response.data);
    this.store.updateAuth(true);
    this.isAuthenticated = true;
    this.$router.push("/us/dashboard");
  },
  methods: {
    toggleLoading() {
      this.buttonLoading = !this.buttonLoading;
    },
    forgotPasswordHandler(status) {
      this.errorMessage = "";
      this.showForgotPassword = status;
      this.showErrorNotification = false;
    },

    async signInHandler() {
      this.toggleLoading();

      if (!validateEmail(this.email) || !this.password) {
        this.errorMessage = "Invalid email address or password.";
        this.showErrorNotification = true;
        this.toggleLoading();
        return;
      }

      this.showErrorNotification = false;

      let response = await API.post("/authwall", {
        data: {
          email: this.email.trim(),
          password: this.password.trim(),
        },
      });

      if (response.error.exists) {
        this.errorMessage = response.error.message;
        this.showErrorNotification = true;
        this.toggleLoading();
        return;
      }

      this.store.reset();
      this.store.updateAuth(!response.error.exists);
      await localStorage.setItem("nmsToken", response.data.token);

      this.$router.push("/us/dashboard");
    },

    async resetHandler() {
      this.toggleLoading();

      if (!validateEmail(this.email)) {
        this.errorMessage = "Invalid email address.";
        this.showErrorNotification = true;
        this.toggleLoading();
        return;
      }

      this.showErrorNotification = false;

      let response = await API.post("/authwall/tasks", {
        data: {
          request_type: "",
          task_type: "send_password_reset_link",
          request_body: {
            email: this.email.trim(),
          },
        },
      });

      if (response.error.exists || response.code !== 200) {
        this.errorMessage = response.error.message;
        this.showErrorNotification = true;
        this.toggleLoading();
        return;
      }

      this.resetLinkSent = true;
      this.toggleLoading();
      this.$buefy.snackbar.open({
        message: "A password reset link has been sent to your email address.",
        type: "is-success",
        pauseOnHover: false,
        position: "is-top",
        duration: 10000,
      });
    },
  },
};
</script>

<style scoped>
.section {
  height: 100vh;
}

img {
  height: 3.5rem;
}
.vbtn {
  background-color: #2d73d4;
  color: white;
  text-transform: uppercase;
  height: 55px;
  font-weight: bold;
  font-size: 0.86rem;
}
.card {
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 5%);
  margin-bottom: 1.5rem;
  width: 20vw;
  min-width: 22rem;
}
</style>
