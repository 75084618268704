<template>
  <div>
    <section
      v-if="canRegister"
      class="section is-flex is-flex-direction-column is-align-items-center is-align-content-center is-justify-content-center"
    >
      <img class="mb-5" alt="Vue logo" src="@/assets/images/zysod/zysod.png" />

      <Notification
        v-if="showErrorNotification"
        @x-clicked="showErrorNotification = false"
        :text="errorMessage"
      />

      <div v-if="accountCreated" class="card is-align-self-center">
        <header class="card-header p-2">
          <p class="card-header-title is-size-6">Account Created</p>
          <button class="card-header-icon" aria-label="more options">
            <span class="icon">
              <i
                class="mdi mdi-information-outline mdi-40px"
                aria-hidden="true"
              ></i>
            </span>
          </button>
        </header>

        <div class="card-content">
          <div
            class="is-flex is-flex-direction-column m-5 pb-6 is-justify-content-center is-align-items-center"
          >
            <p class="is-align-self-center tg">
              <i
                class="mdi mdi-account-check is-no-alarm mdi-40px"
                aria-hidden="true"
              ></i>
            </p>
            <p class="is-size-6 pb-5">
              An account has been created for you,
              <span
                class="mr-1 is-capitalized has-text-grey has-text-weight-bold"
                >{{ firstName }}</span
              >
              <span class="is-capitalized has-text-grey has-text-weight-bold">{{
                lastName
              }}</span
              >. Kindly check your email ({{ this.email }}) for a verification email from us. Once
              verified, you can log in and access the application.
            </p>
            <br />
            <p class="mb-1">Didn't get the email?</p>
            <p class="control">
              <b-button
                outlined
                class="p-4"
                icon-left="email-arrow-right-outline"
                @click="resendHandler"
                :loading="buttonLoading"
                :disabled="resentLink"
                type="is-dark"
                >{{
                  !resentLink
                    ? "Resend Verification Email"
                    : "Verification Email Resent"
                }}</b-button
              >
            </p>
          </div>
        </div>
      </div>

      <div v-else class="card is-align-self-center">
        <header class="card-header p-2">
          <p class="card-header-title is-size-6">Create your Account</p>
          <button class="card-header-icon" aria-label="more options">
            <span class="icon">
              <i class="mdi mdi-account-plus mdi-40px" aria-hidden="true"></i>
            </span>
          </button>
        </header>

        <div class="card-content">
          <b-field class="is-size-7 mb-4" label="Email Address">
            <b-input disabled v-model="email" type="email" icon="at"> </b-input>
          </b-field>
          <b-field label="First Name" class="mb-4">
            <b-input
              required
              type="text"
              onkeydown="return /[a-z]/i.test(event.key)"
              v-model="firstName"
              placeholder="Your first name"
              icon="account"
            >
            </b-input>
          </b-field>
          <b-field label="Last Name" class="mb-4">
            <b-input
              required
              onkeydown="return /[a-z]/i.test(event.key)"
              type="text"
              v-model="lastName"
              placeholder="Your last name"
              icon="account"
            >
            </b-input>
          </b-field>
          <b-field label="Your password" class="mb-4">
            <b-input
              required
              type="password"
              password-reveal
              onkeydown="return /[0-9a-zA-Z.,@&_-$%(){}\[\]:;]/i.test(event.key)"
              v-model="password"
              placeholder="A secure password"
              icon="lock"
            >
            </b-input>
          </b-field>
          <b-field label="Your password, again" class="mb-4">
            <b-input
              required
              type="password"
              onkeydown="return /[0-9a-zA-Z.,@&_-$%(){}\[\]:;]/i.test(event.key)"
              password-reveal
              v-model="password2"
              placeholder="The same secure password"
              icon="lock"
            >
            </b-input>
          </b-field>
          <b-field class="pt-4 pb-2">
            <b-button
              class="vbtn"
              expanded
              type="is-info"
              :loading="buttonLoading"
              @click="createHandler"
              >Create Account</b-button
            >
          </b-field>
        </div>
      </div>
    </section>

    <NotFound v-else />
  </div>
</template>

<script>
import { API } from "@/utils";
import Notification from "@/components/Notification.vue";
import NotFound from "@/components/NotFound.vue";

export default {
  components: {
    //   Button,
    Notification,
    NotFound,
  },
  data() {
    return {
      email: "",
      password: "",
      password2: "",
      firstName: "",
      lastName: "",
      errorMessage: "",

      buttonLoading: false,
      showErrorNotification: false,
      resentLink: false, // Resent verification email
      canRegister: false,
      accountCreated: false,
      accountData: {},
      token: "",
      uuid: "",
    };
  },
  async created() {
    this.accountCreated = false;
    this.canRegister = false;
    let token = this.$router.currentRoute.query.token;

    if (!token) {
      return;
    }

    // Check token from query params
    this.token = token;
    let response = await API.get(`/authwall/register?token=${this.token}`);

    // Bad token
    if (!response || response.code === 400) {
      this.canRegister = false;
      return;
    }

    this.canRegister = true;

    // Register user
    if (response.code === 201) {
      this.email = response.data.email;
    }
    // Verify user
    else if (response.code === 200) {
      this.accountCreated = true;
      this.email = response.data.email;
      this.uuid = response.data.uuid;
      this.lastName = response.data.last_name;
      this.firstName = response.data.first_name;
    }
  },
  methods: {
    toggleLoading() {
      this.buttonLoading = !this.buttonLoading;
    },

    async resendHandler() {
      this.errorMessage = "";
      this.showErrorNotification = false;

      this.toggleLoading();

      let response = await API.post("/authwall/tasks", {
        data: {
          request_type: "",
          task_type: "resend_verification_email",
          request_body: {
            email: this.email.trim(),
          },
        },
      });

      if (response.error.exists || response.code !== 200) {
        this.errorMessage = response.error.message;
        this.showErrorNotification = true;
        this.toggleLoading();
        return;
      }

      this.resentLink = true;
      this.toggleLoading();

      this.$buefy.snackbar.open({
        message:
          "The verification email has been resent to your email address.",
        type: "is-success",
        pauseOnHover: false,
        position: "is-top",
        duration: 10000,
      });
    },
    async createHandler() {
      let error = "";
      this.errorMessage = "";
      this.showErrorNotification = false;
      this.toggleLoading();

      if (this.firstName.length < 3) {
        error = "The first name is too short";
      } else if (this.firstName.length > 15) {
        error = "The first name is too long";
      } else if (this.lastName.length < 3) {
        error = "The last name is too short";
      } else if (this.lastName.length > 15) {
        error = "The last name is too long";
      } else if (this.password.length < 5 || this.password2.length < 5) {
        error = "The passwords should be at least six characters";
      } else if (this.password !== this.password2) {
        error = "The passwords do not match.";
      }

      if (error !== "") {
        this.errorMessage = error;
        this.showErrorNotification = true;
        this.toggleLoading();
        return;
      }

      let response = await API.post(`/authwall/register?token=${this.token}`, {
        data: {
          firstName: this.firstName.trim(),
          lastName: this.lastName.trim(),
          password: this.password.trim(),
        },
      });

      this.toggleLoading();

      if (response.code !== 200 || response.error.exists) {
        this.errorMessage = response.error.message;
        this.showErrorNotification = true;
        return;
      }

      // Created successfully
      this.accountCreated = true;
      this.email = response.data.email;
      this.uuid = response.data.uuid;
      this.lastName = response.data.last_name;
      this.firstName = response.data.first_name;
    },
  },
};
</script>

<style scoped>
.section {
  height: 100vh;
}

img {
  height: 3.5rem;
}
.vbtn {
  background-color: #2d73d4;
  color: white;
  text-transform: uppercase;
  height: 55px;
  font-weight: bold;
  font-size: 0.86rem;
}
.card {
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 5%);
  margin-bottom: 1.5rem;
  width: 20vw;
  min-width: 22rem;
}

.tg > .mdi:before,
.mdi-set {
  color: grey;
  font-size: 40px !important;
}
</style>
