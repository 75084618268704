<template>
  <div>
    <div class="columns">
      <div class="column is-12">
        <div class="card">
          <header class="card-header is-danger p-2">
            <p class="card-header-title is-size-6">Registered Networks <span class="ml-1 has-text-grey">({{ data.length }})</span></p>
            <p class="is-flex is-align-items-center">
              <b-button
                @click="createClicked = !createClicked"
                class="mr-3 px-5"
                aria-label="more options"
                :type="`${
                  !createClicked ? 'is-info is-light' : 'is-light is-danger'
                }`"
                outlined
                :icon-left="createClicked ? 'cancel' : 'signal-variant'"
              >
                {{ createClicked ? "Cancel" : "Register Network" }}
              </b-button>
            </p>
          </header>
          <b-loading
            :is-full-page="false"
            v-model="isLoading"
            :can-cancel="true"
          ></b-loading>
          <div
            v-if="!dataPresent"
            class="card-content is-flex is-align-items-center is-justify-content-center"
            style="height: 500px"
          >
            <div v-if="!isLoading" class="is-flex is-flex-direction-column">
              <div
                class="is-flex is-align-items-center is-justify-content-center"
              >
                <div class="is-flex">
                  <p class="is-align-self-center tg">
                    <i
                      class="mdi mdi-information-outline mdi-40px"
                      aria-hidden="true"
                    ></i>
                  </p>
                </div>
              </div>
              <p class="is-size-6 has-text-grey">
                No networks have been registered.
              </p>
            </div>
          </div>
          <div v-show="dataPresent">
            <ModifiableTable
              :showHeader="true"
              :tableData="data"
              :tableColumns="columns"
              @deleteRowData="(r) => deleteHandler(r)"
              @editRowData="(r) => editHandler(r)"
            />
          </div>
        </div>
      </div>
    </div>

    <EditNetwork
      v-if="aboutToEdit"
      :show="aboutToEdit"
      :uuid="aboutToEditData.uuid"
      @response="(r) => editNetworkHandler(r)"
    />

    <RegisterNetwork
      @response="(r) => registerNetwork(r)"
      :show="createClicked"
      v-if="createClicked"
    />

    <ConfirmModal
      :show="aboutToDelete"
      :header="`Delete Network`"
      :content="`Are you sure you want to delete network ${snakeToStartCase(
        aboutToDeleteData.name
      )}?`"
      @response="(r) => deleteModalConfirmHandler(r)"
    />
  </div>
</template>

<script>
import ConfirmModal from "@/components/ConfirmModal.vue";
import ModifiableTable from "@/components/ModifiableTable.vue";
import { useUserStore } from "@/store";
import { API, snakeToStartCase } from "@/utils";
import EditNetwork from "./EditNetwork.vue";
import RegisterNetwork from "./RegisterNetwork.vue";

const EXCLUDE_PARAMS = ["uuid"];

const visibleFields = [
  "no",
  "name",
  "registered_sites",
  "gsm_uplink",
  "gsm_downlink",
  "dcs_uplink",
  "dcs_downlink",
  "wcdma_uplink",
  "wcdma_downlink",
  // "lte_uplink",
  // "lte_downlink",
];

export default {
  setup() {
    const store = useUserStore();
    return { store };
  },
  data() {
    return {
      data: [],
      dataPresent: false,
      editClicked: false,
      createClicked: false,
      columns: [],
      isLoading: false,
      aboutToEdit: false,
      aboutToEditData: {},
      aboutToDelete: false,
      aboutToDeleteData: {},
    };
  },
  methods: {
    snakeToStartCase(v) {
      return snakeToStartCase(v);
    },
    editNetworkHandler(successful) {
      if (successful) {
        this.aboutToEditData = {};
        this.aboutToEdit = !successful;
        this.$buefy.snackbar.open({
          message: "Network successfully updated",
          type: "is-success",
          pauseOnHover: false,
          position: "is-bottom",
        });
        this.fetchNetworks();
      } else {
        this.aboutToEdit = successful;
      }
    },
    editHandler(rowData) {
      this.aboutToEditData = rowData;
      this.aboutToEdit = true;
    },
    registerNetwork(successful) {
      if (successful) {
        this.createClicked = !successful;
        this.fetchNetworks();
        this.$buefy.snackbar.open({
          message: "Network successfully created",
          type: "is-success",
          pauseOnHover: false,
          position: "is-bottom",
        });
      } else {
        this.createClicked = successful;
      }
    },
    async deleteModalConfirmHandler(confirmed) {
      if (confirmed) {
        this.isLoading = true;
        let response = await API.delete(
          `/network/${this.aboutToDeleteData.uuid}`
        );
        if (!response.error.exists) {
          this.$buefy.snackbar.open({
            message: "Network successfully deleted",
            type: "is-success",
            pauseOnHover: false,
            position: "is-bottom",
          });
          this.aboutToDeleteData = {};
          this.fetchNetworks();
        } else {
          this.$buefy.snackbar.open({
            message: response.error.message,
            type: "is-danger",
            pauseOnHover: false,
            position: "is-top",
          });
        }
      }
      this.isLoading = false;
      this.aboutToDelete = false;
    },
    deleteHandler(rowData) {
      this.aboutToDeleteData = rowData;
      this.aboutToDelete = true;
    },
    async fetchNetworks() {
      this.isLoading = true;
      this.data = [];

      let response = await API.get("/networks");
      this.dataPresent = response.data.items_count > 0;
      if (!response.error.exists && this.dataPresent) {
        this.data = response.data.items.map((c, index) => {
          return {
            ...c,
            no: index + 1,
          };
        });

        this.columns = this.dataPresent
          ? Object.keys(this.data[0])
              .filter((c) => !EXCLUDE_PARAMS.includes(c))
              .map((c) => {
                return {
                  field: c,
                  sortable: c !== "color",
                  visible: visibleFields.includes(c),
                  centered: !["name"].includes(c),
                  label: c === "color" ? "Marker Color" : snakeToStartCase(c),
                };
              })
          : [];

        // Order columns in expected view
        for (let index = 0; index < visibleFields.length; index++) {
          let indexInColumns = this.columns
            .map((c) => c.field)
            .indexOf(visibleFields[index]);

          // `move` is added to Array.prototype in `utils.js`
          this.columns.move(indexInColumns, index);
        }
      }

      this.isLoading = false;
    },
  },

  async mounted() {
    this.siteId = this.$route.params.uuid;
    await this.fetchNetworks();
  },

  components: {
    ModifiableTable,
    ConfirmModal,
    EditNetwork,
    RegisterNetwork,
  },
};
</script>
<style scoped>
.tg > .mdi:before,
.mdi-set {
  color: grey;
  font-size: 40px !important;
}
</style>
