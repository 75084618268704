<template>
  <div class="columns">
    <div class="column is-4">
      <div class="card">
        <header class="card-header is-align-items-center py-2 is-danger p-4">
          <!-- <b-field> -->
          <b-input
            style="width: 30vw"
            icon="magnify"
            :icon-right="searchTerm ? 'close-circle' : ''"
            icon-right-clickable
            @icon-right-click="searchTerm = ''"
            placeholder="Filter by name, or other values..."
            v-model="searchTerm"
          ></b-input>
          <!-- </b-field> -->
        </header>
        <div class="card-content my-2 py-3 px-3">
          <b-loading
            :is-full-page="false"
            v-model="isLoading"
            :can-cancel="true"
          ></b-loading>
          <ul
            style="max-height: 80vh; min-height: 50vh; overflow-y: auto"
            class="is-flex is-flex-direction-column mb-5"
          >
            <li
              class="py-4 px-3 list-separated-item"
              v-for="site in renderedData"
              :key="site.uuid"
            >
              <div
                class="is-flex has-text-grey is-align-self-center is-justify-content-space-between"
              >
                <div class="is-flex is-flex-direction-column">
                  <router-link
                    class="is-capitalized is-size-6"
                    :to="{
                    name: 'site',
                    params: { name: site.name, uuid: site.uuid },
                  }"
                  >
                    {{ site.name }}
                  </router-link>

                  <div
                    class="is-clickable is-flex is-align-content-center is-size-7"
                  >
                    <span
                      ><i
                        :class="{
                      'mdi-fridge-industrial-outline':
                        site.device_type === 'david' ? true : false,
                      'mdi-toggle-switch-variant-off':
                        site.device_type === 'power_device' ? true : false,
                    }"
                        class="mdi"
                        aria-hidden="true"
                      ></i>
                    </span>
                    <span class="is-capitalized is-align-self-center">{{
                      snakeToStartCase(site.device_type)
                    }}</span>
                  </div>
                </div>
                <div class="is-align-self-center">
                  <b-dropdown
                    position="is-bottom-left"
                    append-to-body
                    aria-role="menu"
                    trap-focus
                    class="py-0"
                  >
                    <template #trigger>
                      <span class="icon is-clickable">
                        <i
                          class="mdi mdi-dots-vertical mdi-40px"
                          aria-hidden="true"
                        ></i>
                      </span>
                    </template>

                    <b-dropdown-item
                      @click="editSiteHandler(site.uuid)"
                      class="is-flex has-text-grey py-0"
                    >
                      <i
                        class="is-align-self-center mdi mdi-square-edit-outline mdi-18px"
                        aria-hidden="true"
                      ></i>
                      <span class="is-align-self-center pl-2 is-clickable"
                        >Edit
                                            </span>
                    </b-dropdown-item>
                    <b-dropdown-item
                      @click="deleteSitehander(site.name, site.uuid)"
                      class="is-flex has-text-grey py-0"
                    >
                      <i
                        class="is-align-self-center mdi mdi-trash-can-outline mdi-18px"
                        aria-hidden="true"
                      ></i>
                      <span class="is-align-self-center pl-2 is-clickable"
                        >Delete
                      </span>
                    </b-dropdown-item>
                    <b-dropdown-item
                      @click="exportSitehander(site.uuid)"
                      class="is-flex has-text-grey py-0"
                    >
                      <i
                        class="is-align-self-center mdi mdi-share-variant-outline mdi-18px"
                        aria-hidden="true"
                      ></i>
                      <span class="is-align-self-center pl-2 is-clickable"
                        >Export
                      </span>
                    </b-dropdown-item>
                    <b-dropdown-item @click="toggleSiteAlarm(site)" class="is-flex has-text-grey py-0">
                      <i :class="site.alarms_enabled ? 'mdi-alarm-light-off' : 'mdi-alarm-light'"
                        class="is-align-self-center mdi mdi-18px" aria-hidden="true"></i>
                      <span class="is-align-self-center pl-2 is-clickable">{{ site.alarms_enabled ? 'Disable Alarms' :
                        'Enable Alarms' }}
                      </span>
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div
      v-if="!isEditingSite"
      class="column is-flex is-flex-grow-1 is-justify-content-center is-align-items-center"
    >
      <div
        style="height: 300px"
        class="is-flex is-align-items-center is-justify-content-center"
      >
        <div class="is-flex is-flex-direction-column">
          <div class="is-flex is-align-items-center is-justify-content-center">
            <div class="is-flex">
              <p class="is-align-self-center tg">
                <i
                  class="mdi mdi-square-edit-outline mdi-40px"
                  aria-hidden="true"
                ></i>
                <i class="mdi mdi-earth-plus mdi-40px" aria-hidden="true"></i>
                <i
                  class="mdi mdi-trash-can-outline mdi-40px"
                  aria-hidden="true"
                ></i>
              </p>
            </div>
          </div>
          <p class="is-size-5 pb-5 has-text-grey">
            Register new sites, Delete, or Edit exisitng sites
          </p>
          <p class="is-align-self-center">
            <b-button
              class="r-button"
              type="is-dark"
              outlined
              icon-left="earth-plus"
              @click="registerSiteHandler()"
              >Register New Site</b-button
            >
          </p>
        </div>
      </div>
    </div>
    <EditSite
      @response="(r) => editSiteResponse(r)"
      :uuid="currentEditingSiteUuid"
      v-else
    />

    <!-- Modals when deleting or adding sites -->
    <RegisterSite
      v-if="showRegisterSiteModal"
      @response="(r) => registerSiteResponse(r)"
      :show="showRegisterSiteModal"
    />
    <ConfirmModalStepped
      @response="(r) => handleConfirmModalResponse(r)"
      :show="showDeleteSiteModal"
      :header="`Delete Site ${deleteSiteConfirmAgainst}`"
      content="Are you sure you want to delete this site?"
      :confirmAgainst="deleteSiteConfirmAgainst"
    />
    <ConfirmModal
      @response="(r) => handleConfirmExportModalResponse(r)"
      :show="showExportSiteModal"
      header="Export Site Configuration"
      content="This will export the site's configuration as a JSON file, so the site can be registered quicker later." />
    <ConfirmModal @response="(r) => handleConfirmToggleAlarmsModalResponse(r)" :show="showToggleAlarmsModal"
      :header="`Alarm status for ${alarmTogglingSite ? alarmTogglingSite.name : 'this site'}`"
      :content="`This will ${alarmTogglingSite && alarmTogglingSite.alarms_enabled ? 'disable' : 'enable'} the alarms configuration for ${alarmTogglingSite ? snakeToStartCase(alarmTogglingSite.name) : 'this site'}. Note that alarms will not be triggered for a site with disabled alarms.`" />
  </div>
</template>

<script>
import { useUserStore } from "@/store";
import {
  API,
  snakeToStartCase,
  fetchNextSetOfData,
  downloadFile,
} from "@/utils";
import EditSite from "../Sites/EditSite.vue";
import RegisterSite from "../Sites/RegisterSite.vue";
import ConfirmModalStepped from "@/components/ConfirmModalStepped.vue";
import ConfirmModal from "@/components/ConfirmModal.vue";

export default {
  setup() {
    const store = useUserStore();
    return { store };
  },
  watch: {
    searchTerm(query) {
      this.renderedData = this.sites;
      if (query.length >= 3) {
        this.isLoading = true;

        setTimeout(() => {
          let filterResult = [];

          for (const item of this.sites) {
            // Search the values
            for (const s of Object.values(item)) {
              if (
                JSON.stringify(s)
                  .toLocaleLowerCase()
                  .includes(query.toLocaleLowerCase()) &&
                !filterResult.includes(item)
              ) {
                filterResult.push(item);
              }
            }
          }
          this.renderedData = filterResult;
          this.isLoading = false;
        }, 400);
      }
    },
  },
  data() {
    return {
      sites: [],
      renderedData: [],
      columns: [],
      after: "",
      searchTerm: "",
      isLoading: false,
      isEditingSite: false,
      alarmTogglingSite: null,
      currentEditingSiteUuid: "",
      currentConfirmSiteUuid: "",
      currentDeletingSiteUuid: "",
      showDeleteSiteModal: false,
      showExportSiteModal: false,
      showToggleAlarmsModal: false,
      showRegisterSiteModal: false,
      deleteSiteConfirmAgainst: "",
    };
  },
  methods: {
    async deleteSite() {
      let response = await API.delete(`/site/${this.currentDeletingSiteUuid}`);
      if (response.code !== 200 || response.error.exists) {
        this.$buefy.snackbar.open({
          message: response.error.message,
        });
      } else {
        this.$buefy.snackbar.open({
          message: "Successfully deleted site",
        });
      }
    },
    snakeToStartCase(input) {
      return snakeToStartCase(input);
    },
    registerSiteHandler() {
      this.showRegisterSiteModal = true;
    },
    registerSiteResponse(response) {
      this.showRegisterSiteModal = false;
      if (!response) {
        return;
      }
      this.$buefy.snackbar.open({
        message: "Site saved successfully",
      });
      this.fetchSites();
    },
    editSiteHandler(uuid) {
      this.isEditingSite = true;
      this.currentEditingSiteUuid = uuid;
    },
    deleteSitehander(name, uuid) {
      this.showDeleteSiteModal = true;
      this.deleteSiteConfirmAgainst = name;
      this.currentDeletingSiteUuid = uuid;
    },
    exportSitehander(uuid) {
      this.currentConfirmSiteUuid = uuid;
      this.showExportSiteModal = true;
    },
    toggleSiteAlarm(site) {
      this.alarmTogglingSite = site;
      this.showToggleAlarmsModal = true;
    },
    async handleConfirmToggleAlarmsModalResponse(confirmed) {
      if (confirmed && this.alarmTogglingSite) {
        let resp = await API.put(`/site/${this.alarmTogglingSite.uuid}`, {
          data: { changes: [{ name: "alarms_enabled", value: !this.alarmTogglingSite.alarms_enabled }] },
        });

        let status = this.alarmTogglingSite.alarms_enabled ? 'disabled' : 'enabled';
        if (resp.code === 200 && !resp.error.exists) {
          this.$buefy.snackbar.open({
            position: "is-top",
            message: `Alarms for ${this.snakeToStartCase(this.alarmTogglingSite.name)} successfully ${status}.`,
          });
          this.fetchSites();
        } else {
          this.errorMessage = resp.error.message;
        }
      }
      this.showToggleAlarmsModal = false;
      this.alarmTogglingSite = null;
    },
    async handleConfirmExportModalResponse(confirmed) {
      if (confirmed) {
        let site;
        let response = await API.get(`/site/${this.currentConfirmSiteUuid}`);
        if (response.code !== 200 || response.error.exists) {
          this.$buefy.snackbar.open({
            position: "is-top",
            type: "is-danger",
            message: response.error.message || "An error occured, please try again.",
          });
          this.showExportSiteModal = false;
          this.currentConfirmSiteUuid = "";
          return;
        } else {
          site = response.data;
        }

        for (const key of [
          "uuid",
          "network_id",
          "company_id",
          "remote_access_port",
          "algo_sequence",
          "trafficable",
          "date_registered",
          "date_deployed",
          "bands",
          "network",
          "company",
        ]) {
          if (key in site) {
            delete site[key];
          }
        }
        const fileName = `nms_site_${site.name}.json`;
        downloadFile(JSON.stringify(site), "application/json", fileName);
        this.$buefy.snackbar.open({
          position: "is-top",
          message: `Downloaded successfully.`,
        });
      }
      this.showExportSiteModal = false;
      this.currentConfirmSiteUuid = "";
    },
    async handleConfirmModalResponse(confirmed) {
      if (confirmed) {
        this.isEditingSite = false;
        this.currentEditingSiteUuid = "";
        await this.deleteSite();
        this.fetchSites();
      }
      this.showDeleteSiteModal = false;
      this.currentDeletingSiteUuid = "";
      this.deleteSiteConfirmAgainst = "";
    },
    editSiteResponse(confirmed) {
      this.isEditingSite = false;
      if (!confirmed) {
        this.currentEditingSiteUuid = "";
        return;
      }
      this.$buefy.snackbar.open({
        message: "Site saved successfully",
      });
      this.fetchSites();
    },
    async fetchSites() {
      this.isLoading = true;
      let response = await API.get("/sites");
      if (!response.error.exists) {
        this.sites = response.data.items.map((s) => {
          return { uuid: s.uuid, name: s.name, device_type: s.device_type, alarms_enabled: s.alarms_enabled };
        });
        this.renderedData = this.sites;
        if (response.data.items_count == response.data.items_per_page) {
          this.after = response.data.uuid_after;
        }
        this.isLoading = false;
      }
    },

    async updateSiteList() {
      let sites = [...this.sites];
      let maxRequests = 20;
      let requestsCount = 1;
      let allFetched = false;

      if (this.after !== "") {
        while (!allFetched && requestsCount <= maxRequests) {
          requestsCount += 1;
          let response = await fetchNextSetOfData(
            "sites",
            this.after,
            sites,
            false
          );
          this.after = response.newUuidAfter;
          allFetched = response.allIsFetched;
        }
      }
      sites = sites.map((s) => {
        return { uuid: s.uuid, name: s.name, device_type: s.device_type };
      });
      this.sites = sites;
      this.renderedData = sites;
    },
  },
  async created() {
    await this.fetchSites();

    // setTimeout(() => {
    //   this.updateSiteList();
    // }, 5000);
  },
  components: { EditSite, ConfirmModalStepped, ConfirmModal, RegisterSite },
};
</script>

<style scoped>
.columns {
  height: 100%;
}

.list-separated-item {
  border-top: 1px solid rgba(0, 40, 100, 0.12);
}

.list-separated-item:not(.is-selected):nth-child(even) {
  background-color: rgb(17 127 241 / 2%) !important;
}

.list-separated-item:first-child {
  padding-top: 0;
  border-top: none;
}

span > .mdi:before,
.mdi-set {
  font-size: 14px !important;
}

.tg > .mdi:before,
.mdi-set {
  color: grey;
  font-size: 40px !important;
}

.r-button {
  width: 210px;
  max-width: 300px;
  min-width: 120px;
  height: 52px;
}
</style>
