<template>
  <div class="column">
    <div class="card">
      <b-loading
        :is-full-page="false"
        v-model="isLoading"
        :can-cancel="true"
      ></b-loading>
      <header class="card-header">
        <p class="card-header-title is-size-6 my-2 has-text-weight-bold">
          Remote control metadata for
          <span class="is-capitalized pl-1 has-text-grey">{{ siteName }}</span>
        </p>
        <button class="card-header-icon" aria-label="more options">
          <span class="icon">
            <i
              class="mdi mdi-information-outline mdi-40px"
              aria-hidden="true"
            ></i>
          </span>
        </button>
      </header>
      <div class="card-content pb-0">
        <b-table
          style="color: grey"
          class="has-text-grey"
          :data="renderedData"
          :loading="isLoading"
        >
          <b-table-column
            :key="index"
            v-for="(column, index) in columns"
            cell-class="tdd"
            :centered="column.centered"
            :visible="column.visible"
            :field="column.field"
            :label="column.label"
            :numeric="column.numeric"
            :sortable="column.sortable"
            v-slot="props"
          >
            <span class="is-capitalized py-4">
              {{
                [null, undefined].includes(props.row[column.field])
                  ? "N/A"
                  : snakeToStartCase(props.row[column.field])
              }}
            </span>
          </b-table-column>
        </b-table>
      </div>
      <!-- <div class="py-1" style="text-align: center">
        <span class="icon">
          <i class="mdi mdi-dots-horizontal mdi-40px" aria-hidden="true"></i>
        </span>
      </div> -->
      <footer>
        <div class="card">
          <div class="card-content">
            <!-- Command choices and button -->
            <b-field>
              <!-- Dropdown -->
              <b-select
                v-model="selectedCommand"
                expanded
                icon="raspberry-pi"
                placeholder="Choose a command to execute"
              >
                <option disabled value="">Choose a command to execute</option>

                <option
                  v-for="command in possibleCommands"
                  :value="command.name"
                  :key="command.name"
                >
                  {{ command.displayName }}
                </option>
              </b-select>

              <!-- Execute button -->
              <p class="control">
                <b-button
                  :disabled="!enableExecuteButton && !parameterValue"
                  class="px-5"
                  icon-left="cog-play"
                  type="is-dark"
                  :loading="commandExecuting"
                  @click="executeRemoteCommand"
                  >Execute Command</b-button
                >
              </p>
            </b-field>

            <!-- Remote requests that require updating or adding -->
            <p class="control" v-if="showValuesDropdown">
              <!-- Setting parameters -->
              <b-field
                v-if="selectedCommand === 'set_parameter'"
                label="Parameter Name"
                expanded
              >
                <b-field>
                  <b-select
                    v-model="parameterName"
                    icon="raspberry-pi"
                    placeholder="Choose the Parameter Name"
                  >
                    <option disabled value="">Choose the Parameter Name</option>

                    <option
                      v-for="(parameter, index) in davidParameters"
                      :value="parameter.name"
                      :key="index"
                    >
                      {{ parameter.displayName }}
                    </option>
                  </b-select>

                  <b-select
                    v-model="parameterValue"
                    v-if="parameterName.includes('switch')"
                    icon="switch"
                    :disabled="!parameterName"
                    placeholder="Choose the switch state"
                  >
                    <option disabled value="">Choose the switch state</option>
                    <option value="on">On</option>
                    <option value="off">Off</option>
                  </b-select>

                  <b-input
                    v-else
                    v-model="parameterValue"
                    :disabled="!parameterName"
                    step="any"
                    type="number"
                    placeholder="Parameter value. E.g. 0.5."
                  ></b-input>
                </b-field>
              </b-field>

              <!-- Adding a Cron Job -->
              <b-field
                v-else-if="selectedCommand === 'add_daily_cron_job'"
                label="Cron Job Name"
                expanded
              >
                <b-field>
                  <b-select
                    v-model="selectedCommand"
                    expanded
                    icon="raspberry-pi"
                    placeholder="Choose the Parameter Name"
                  >
                    <option disabled value="">Choose the Parameter Name</option>

                    <option
                      v-for="command in possibleCommands"
                      :value="command.name"
                      :key="command.name"
                    >
                      {{ command.displayName }}
                    </option>
                  </b-select>

                  <b-input placeholder="Parameter value. E.g. 0.5."></b-input>
                </b-field>
              </b-field>

              <!-- Removing a Cron Job -->
              <b-field
                v-else-if="selectedCommand === 'remove_daily_cron_job'"
                label="Cron Job Name"
                expanded
              >
                <b-field>
                  <b-select
                    v-model="selectedCommand"
                    expanded
                    icon="raspberry-pi"
                    placeholder="Choose the Parameter Name"
                  >
                    <option disabled value="">Choose the Parameter Name</option>

                    <option
                      v-for="command in possibleCommands"
                      :value="command.name"
                      :key="command.name"
                    >
                      {{ command.displayName }}
                    </option>
                  </b-select>

                  <b-input placeholder="Parameter value. E.g. 0.5."></b-input>
                </b-field>
              </b-field>
            </p>

            <!-- Default backdrop before request -->
            <div
              v-if="!remoteResponsePresent"
              class="column mb-6 py-6 is-flex is-flex-grow-1 is-justify-content-center is-align-items-center"
            >
              <b-loading
                :is-full-page="false"
                v-model="commandExecuting"
                :can-cancel="false"
              ></b-loading>
              <div
                class="is-flex is-align-items-center is-justify-content-center"
              >
                <div class="is-flex is-flex-direction-column">
                  <div
                    class="is-flex is-align-items-center is-justify-content-center"
                  >
                    <div class="is-flex">
                      <p v-if="!commandExecuting" class="is-align-self-center tg">
                        <i
                          class="mdi mdi-reply-all mdi-40px"
                          aria-hidden="true"
                        ></i>
                      </p>
                    </div>
                  </div>
                  <p :style="commandExecuting ? 'margin-top: 24px' : ''" class="is-size-6 has-text-grey">
                    The response from the device will show here, if available
                  </p>
                </div>
              </div>
            </div>

            <!-- Request made -->
            <div v-else class="column pt-0 px-0">
              <!-- No valid response from device -->
              <div
                v-if="Object.keys(remoteResponse).length === 0"
                class="is-flex is-flex-direction-column py-6 mb-5 is-flex-grow-1 is-justify-content-center is-align-items-center"
              >
                <div
                  class="is-flex is-align-items-center is-justify-content-center"
                >
                  <div class="is-flex">
                    <p class="is-align-self-center tg">
                      <i
                        class="mdi mdi-alert-remove mdi-40px"
                        aria-hidden="true"
                      ></i>
                    </p>
                  </div>
                </div>
                <p class="is-size-6 has-text-grey">
                  Did not get a response from the device. Try again.
                </p>
              </div>

              <!-- Valid response gotten -->
              <div v-else class="card-content pt-0 px-0 pb-2">
                <b-table
                  class="has-text-grey"
                  :striped="true"
                  :hoverable="true"
                  :data="responseData"
                  :columns="responseColumns"
                >
                </b-table>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  </div>
</template>

<script>
import {
  DAVID_COMMANDS,
  REMOTE_SET_COMMANDS,
  DAVID_PARAMETERS,
} from "@/constants";
import { API, snakeToStartCase } from "@/utils";

export default {
  props: {
    siteName: String,
    siteDetails: Object,
  },

  data() {
    return {
      possibleCommands: [],
      remoteCommands: [],
      renderedData: [],
      isLoading: false,
      columns: [],
      responseColumns: [],
      remoteResponse: {},
      remoteResponsePresent: false,
      commandExecuting: false,
      enableExecuteButton: false,

      deviceType: "",
      selectedCommand: "",
      deploymentMedium: "",
      rfSwitchOnOff: false,
      parameterName: "",
      parameterValue: "",
      showValuesDropdown: false,
    };
  },

  watch: {
    siteName() {
      this.populateTable();
      this.getPossibleCommands();
    },
    selectedCommand(command) {
      // Reset any previously set
      this.parameterName = "";
      this.parameterValue = "";
      this.enableExecuteButton = false;
      this.remoteResponsePresent = false;

      // Show only if command allows setting
      this.showValuesDropdown = false;
      if (REMOTE_SET_COMMANDS.includes(command)) {
        this.showValuesDropdown = true;
      }

      if (this.selectedCommand !== "") {
        // Selected command do not require name and value?
        if (!REMOTE_SET_COMMANDS.includes(this.selectedCommand)) {
          this.enableExecuteButton = true;
          return;
        }
      }
    },
    parameterName() {
      // Reset value
      this.parameterValue = "";
    },
  },

  methods: {
    snakeToStartCase(input) {
      return snakeToStartCase(input);
    },

    async executeRemoteCommand() {
      this.commandExecuting = true;

      let payload = {
        task_type: "remote_request",
        request_type: this.selectedCommand,
        request_body: {
          site_name: this.siteDetails.name,
          request_params: {
            name: this.parameterName,
            value: this.parameterValue,
          },
        },
      };
      // this.remoteResponsePresent = true;

      let response = await API.post("/tasks", {
        data: payload,
      });

      if (response && response.code === 200 && !response.error.exists) {
        let taskId = response.data.task_id;
        let intervalShouldClear = false;
        let retries = 0;

        // Default states
        this.remoteResponse = {};
        this.remoteResponsePresent = false;

        let intervalID = setInterval(async () => {
          if (intervalShouldClear || retries >= 10) {
            this.commandExecuting = false;
            this.remoteResponsePresent = true;
            clearInterval(intervalID);
            return;
          }

          retries += 1;
          let taskResponse = await API.get(`/task/${taskId}`);

          // Task is complete
          if (
            taskResponse.code === 200 &&
            !taskResponse.error.exists &&
            taskResponse.data.message === "task complete"
          ) {
            // Save response and clear interval
            this.remoteResponse = taskResponse.data.data;
            this.remoteResponsePresent = true;

            this.formatResponseTable();
            intervalShouldClear = true;
          }
        }, 1000);
      }
    },
    async fetchRemoteCommands() {
      let response = await API.get("/remote-requests");
      if (response.code === 200 && !response.error.exists) {
        this.remoteCommands = response.data.items;
        this.getPossibleCommands();
      }
    },

    formatResponseTable() {
      this.responseColumns = [
        { field: "key", label: "Response Key" },
        { field: "value", label: "Response Value" },
      ];

      this.responseData = Object.keys(this.remoteResponse).map((k) => {
        return {
          key: snakeToStartCase(k),
          value: snakeToStartCase(this.remoteResponse[k]),
        };
      });
    },

    getPossibleCommands() {
      this.selectedCommand = "";
      // Possible commands differ by type of device
      let commands =
        // Exclude David-y commands from non-David device
        this.deviceType.toLocaleLowerCase() !== "david"
          ? this.remoteCommands.filter((c) => !DAVID_COMMANDS.includes(c.name))
          : // A David includes David-y commands (all)
            this.remoteCommands;

      this.possibleCommands = commands.map((c) => {
        return {
          name: c.name,
          displayName: snakeToStartCase(c.name),
        };
      });
    },
    populateTable() {
      this.columns = [
        "name",
        "device_type",
        "remote_access_port",
        "deployment_medium",
        "date_deployed",
      ].map((c) => {
        return {
          field: c,
          label: snakeToStartCase(c),
          visible: true,
          numeric: false,
          sortable: false,
          centered: [
            "remote_access_port",
            "deployment_medium",
            "date_deployed",
          ].includes(c)
            ? true
            : false,
        };
      });
      this.renderedData = [this.siteDetails];
      this.deviceType = snakeToStartCase(this.siteDetails.device_type);
      this.deploymentMedium = snakeToStartCase(
        this.siteDetails.deployment_medium
      );
    },
  },

  mounted() {
    this.davidParameters = DAVID_PARAMETERS.map((n) => {
      return { name: n, displayName: snakeToStartCase(n) };
    });
    this.populateTable();
    this.fetchRemoteCommands();
  },
};
</script>
<style scoped>
.field {
  margin-bottom: 1.4rem !important;
}
select {
  width: 100%;
  text-transform: capitalize !important;
}

.tg > .mdi:before,
.mdi-set {
  color: grey;
  font-size: 30px !important;
}
</style>
