<template>
  <section>
    <div
      v-for="(param, index) in parameters"
      :key="index"
      class="card is-flex mb-5 ml-0 mb-0 px-6 is-align-items-center is-justify-content-space-between"
      style="height: 140px;"
    >
      <!-- Parameter -->
      <div
        class="is-flex is-flex-direction-column has-text-grey is-align-items-flex-start pr-auto"
      >
        <span class="punit">
          <span :class="param.status">
            {{ param.value }}
          </span>
          <span class="is-20">{{ param.unit }}</span>
        </span>
        <span class="is-capitalized is-14">{{ param.name }} </span>
      </div>

      <!-- Icon -->
      <div class="is-align-self-center">
        <i :class="`mdi-${param.icon}`" class="mdi card-icon"></i>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    parameters: Array,
  },
};
</script>
<style scoped>
.punit {
  line-height: normal;
  font-size: 1.75em;
}
</style>
