<template>
  <div class="column">
    <div class="card">
      <header class="card-header is-danger p-2">
        <p class="card-header-title has-text-weight-normal is-size-6">
          Configure default alarm thresholds based on the Geography and Device
          Type
        </p>
        <!-- <button class="card-header-icon" aria-label="more options">
          <span class="icon">
            <i class="mdi mdi-alarm-light mdi-40px" aria-hidden="true"></i>
          </span>
        </button> -->
      </header>
      <div v-if="selectedDeviceType && selectedGeography" class="mt-3">
        <b-message v-if="templateDoesntExist" type="is-warning" has-icon>
          The template with the selected configuration does not exist.
          Create it below by setting the required range.
        </b-message>
        <b-message
          v-if="!templateDoesntExist && !isLoading"
          type="is-info"
          has-icon
        >
          Changes to this configuration will not affect sites that have
          already been registered.
        </b-message>
      </div>
      <div class="card-content py-3 px-2">
        <div class="columns mb-0 px-3">
          <div class="column pb-0">
            <b-field>
              <b-select
                v-model="selectedGeography"
                expanded
                icon="map-marker"
                placeholder="Select the Geography"
              >
                <option disabled value="">Select the Geography</option>
                <option
                  v-for="geography in geographies"
                  :value="geography.name"
                  :key="geography.uuid"
                >
                  {{ geography.displayName }}
                </option>
              </b-select>
            </b-field>
          </div>
          <div class="column pb-0">
            <b-field>
              <b-select
                v-model="selectedDeviceType"
                expanded
                icon="select-compare"
                placeholder="Select the Device Type"
              >
                <option disabled value="">Select the Device Type</option>
                <option
                  v-for="device in deviceTypes"
                  :value="device.name"
                  :key="device.uuid"
                >
                  {{ device.displayName }}
                </option>
              </b-select>
            </b-field>
          </div>
        </div>

        <div class="p-4" v-if="selectedDeviceType && selectedGeography">
          <b-loading
            :is-full-page="false"
            v-model="isLoading"
            :can-cancel="true"
          ></b-loading>
          <div style="min-height: 20vh; overflow: auto">
            <div class="pb-3">
              <div
                class="pb-3"
                :key="index"
                v-for="(threshold, index) in siteThreshold"
              >
                <b-field :label="threshold.displayName"> </b-field>

                <div class="is-flex is-justify-content-space-between">
                  <b-input
                    class="slider-input"
                    lazy
                    v-model="thresholds[threshold.name][0]"
                    type="number"
                    inputmode="numeric"
                    :min="-1000"
                    :max="0"
                  >
                  </b-input>

                  <b-input
                    class="slider-input"
                    inputmode="numeric"
                    lazy
                    onkeypress="return (event.charCode != 8 && event.charCode == 0 || (event.charCode >= 48 && event.charCode <= 57))"
                    v-model="thresholds[threshold.name][1]"
                    type="number"
                    :min="2"
                    :max="1000"
                  >
                  </b-input>
                </div>

                <div>
                  <b-slider
                    v-model="thresholds[threshold.name]"
                    indicator
                    lazy
                    :custom-formatter="(val) => val + threshold.unit"
                    :tooltip="false"
                    type="is-success"
                    :min="threshold.min - 30"
                    :max="threshold.max + 30"
                    :step="2"
                  >
                  </b-slider>
                </div>
              </div>
            </div>

            <!-- <div class="pb-3">
              <b-field
                class="px-1 py-1 slider"
                :label="threshold.displayName"
                v-for="(threshold, index) in siteThreshold"
                :key="index"
              >
                <b-slider
                  v-model="thresholds[threshold.name]"
                  :indicator="true"
                  :tooltip="false"
                  :custom-formatter="(val) => val + threshold.unit"
                  type="is-success"
                  :min="threshold.min - 10"
                  :max="threshold.max + 10"
                  :step="2"
                >
                </b-slider>
              </b-field>
            </div> -->
            <footer v-show="!isLoading" class="card-footer">
              <b-button
                style="height: 50px"
                type="is-light"
                @click="cancelHandler"
                class="is-radiusless card-footer-item"
                >Cancel</b-button
              >
              <b-button
                :loading="saveButtonLoading"
                style="height: 50px"
                @click="saveHandler"
                type="is-link"
                class="is-radiusless card-footer-item"
                >{{
                  templateDoesntExist ? "Create Template" : "Save Changes"
                }}</b-button
              >
            </footer>
          </div>
        </div>

        <!-- Default backdrop -->
        <div v-else>
          <div
            style="height: 300px"
            class="is-flex is-align-items-center is-justify-content-center"
          >
            <div class="is-flex is-flex-direction-column">
              <div
                class="is-flex is-align-items-center is-justify-content-center"
              >
                <div class="is-flex">
                  <p class="is-align-self-center tg">
                    <i
                      class="mdi mdi-timer-edit mdi-40px"
                      aria-hidden="true"
                    ></i>
                    <!-- <i
                      class="mdi mdi-map-marker mdi-40px"
                      aria-hidden="true"
                    ></i>
                    <i
                      class="mdi mdi-alarm-bell mdi-40px"
                      aria-hidden="true"
                    ></i> -->
                  </p>
                </div>
              </div>
              <p class="is-size-6 pb-5 has-text-grey">
                All deployed sites use these templates for their initial alarm
                thresholds
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useUserStore } from "@/store";
import { snakeToStartCase, APIMultiRequests, API } from "@/utils";
export default {
  setup() {
    const store = useUserStore();
    return { store };
  },
  data() {
    return {
      geographies: [], // All sites in dropdown
      deviceTypes: [], // A site's key-value pair of its lower and upper limits
      units: [],

      thresholds: {},
      thresholdUnits: {},
      siteThreshold: {},
      originalThresholds: {}, // Original site values

      editingUuid: "", // Selected ste in dropdown
      selectedGeography: "", // Selected ste in dropdown
      selectedDeviceType: "", // Selected ste in dropdown

      isLoading: false,
      saveButtonLoading: false,
      templateDoesntExist: false,
    };
  },
  watch: {
    selectedGeography(geography) {
      // If the select wasn't reset
      if (geography !== "" && this.selectedDeviceType !== "") {
        this.siteThreshold = [];
        this.fetchTemplate(geography, this.selectedDeviceType);
      }
    },
    selectedDeviceType(deviceType) {
      // If the select wasn't reset
      if (deviceType !== "" && this.selectedGeography !== "") {
        this.siteThreshold = [];
        this.fetchTemplate(this.selectedGeography, deviceType);
      }
    },
  },
  methods: {
    async fetchDropdownData() {
      this.isLoading = true;

      const dropdownRequests = [
        { mode: "get", url: `/geographies` },
        { mode: "get", url: `/david-types` },
        {
          mode: "get",
          url: `/power-device-types`,
        },
      ];
      // Make all requests in parallel
      const dropdownResponses = await APIMultiRequests(dropdownRequests);
      let [geographies, davidTypes, powerDeviceTypes] = dropdownResponses;

      geographies = geographies.data.items.map((s) => {
        return {
          ...s,
          displayName: snakeToStartCase(s.name),
        };
      });
      davidTypes = davidTypes.data.items.map((s) => {
        return {
          ...s,
          displayName: snakeToStartCase(s.name) + " David",
        };
      });
      powerDeviceTypes = powerDeviceTypes.data.items.map((s) => {
        return {
          ...s,
          displayName: snakeToStartCase(s.name),
        };
      });

      this.geographies = geographies;
      this.deviceTypes = [...davidTypes, ...powerDeviceTypes];
      this.isLoading = false;
    },
    resetThresholds() {
      this.thresholds = {};
      this.editingUuid = "";
      this.siteThreshold = [];
      this.thresholdUnits = {};
      this.originalThresholds = {};
    },
    async fetchTemplate(geography, deviceType) {
      this.isLoading = true;
      this.templateDoesntExist = false;
      this.resetThresholds(); // Clear/reset

      let params = { geography: geography, deviceType: deviceType };
      // let isDavid = ["table_top", "indoor", "outdoor"].includes(deviceType);

      // Check if it exists
      let response = await API.get(
        `/alarm-template/${geography}/${deviceType}`
      );

      // Doesn't exist, needs to be created
      if (response.code === 404 && response.error.exists) {
        // Need to fetch the template units and parameters from a similar site
        let templateResponse = await API.get("/alarm-templates", {
          data: params,
        });

        if (templateResponse.code === 200) {
          // Need the units
          let unitsResponse = templateResponse.data.units;
          // Need the thresholds
          let usefulthresholds = templateResponse.data.parameters;

          // All good?
          // Parse and save the thresholds and unit
          for (let i = 0; i < usefulthresholds.length; i += 1) {
            let upper = 100;
            let lower = -100;
            let name = usefulthresholds[i];

            let unit = unitsResponse[i];
            let displayName = snakeToStartCase(name);
            this.siteThreshold.push({
              name: name,
              max: upper,
              min: lower,
              displayName: displayName,
              unit: unit.replace("*", "°"),
            });
            this.thresholdUnits[name] = unit;
            this.thresholds[name] = [lower, upper];
          }

          // Remind that it still needs to be created
          this.templateDoesntExist = true;
        }

        // Weird. Shouldn't ever reach here
        else {
          this.$buefy.snackbar.open({
            message:
              "Could not fetch the template's defaults. Refresh the page and try again.",
            type: "is-danger",
            pauseOnHover: false,
            position: "is-bottom",
          });
        }
      }

      // Template exists; only need to parse the template
      else if (response.code === 200 && !response.error.exists) {
        let usefulthresholds = JSON.parse(response.data.template);
        // Convert back to expected format
        usefulthresholds.forEach((t) => {
          this.originalThresholds[t.name] = t.value;
        });

        this.editingUuid = response.data.uuid;

        for (let i = 0; i < usefulthresholds.length; i += 2) {
          let unit = usefulthresholds[i]["unit"];
          let lower = parseInt(usefulthresholds[i]["value"]);
          let upper = parseInt(usefulthresholds[i + 1]["value"]);
          let name = usefulthresholds[i]["name"].slice(0, -6);

          let displayName = snakeToStartCase(name);
          this.siteThreshold.push({
            name: name,
            max: upper,
            min: lower,
            displayName: displayName,
            unit: unit.replace("*", "°"),
          });
          this.thresholdUnits[name] = unit;
          this.thresholds[name] = [lower, upper];
        }
      }

      // Something else is wrong
      else {
        this.$buefy.snackbar.open({
          message: "An unknown error occured. Refresh the page and try again.",
          type: "is-danger",
          pauseOnHover: false,
          position: "is-bottom",
        });
      }

      this.isLoading = false;
    },

    fetchChanges() {
      let changes = [];
      for (const [name, limits] of Object.entries(this.thresholds)) {
        let [lowerValue, upperValue] = limits;
        let unit = this.thresholdUnits[name];

        if (lowerValue === "") {
          lowerValue = -100;
          this.thresholds[name][0] = -100;
        }

        if (upperValue === "") {
          upperValue = 100;
          this.thresholds[name][1] = 100;
        }

        if (lowerValue >= upperValue) {
          return [];
        }

        changes.push({
          unit: unit,
          value: lowerValue,
          name: `${name}_lower`,
        });
        changes.push({
          unit: unit,
          value: upperValue,
          name: `${name}_upper`,
        });
      }
      return changes;
    },
    async saveHandler() {
      this.saveButtonLoading = true;
      let changes = this.fetchChanges();

      if (changes.length === 0) {
        this.$buefy.snackbar.open({
          message: "No changes were made",
          type: "is-warning",
          pauseOnHover: false,
          position: "is-bottom",
        });
      } else {
        let response;
        if (this.templateDoesntExist) {
          response = await API.post("/alarm-templates", {
            data: {
              template: JSON.stringify(changes),
              device: this.selectedDeviceType,
              geography: this.selectedGeography,
            },
          });
        } else {
          response = await API.put(`/alarm-template/${this.editingUuid}`, {
            data: {
              changes: [{ name: "template", value: JSON.stringify(changes) }],
            },
          });
        }

        if ([200, 201].includes(response.code) && !response.error.exists) {
          this.$buefy.snackbar.open({
            message: `Alarm template has been successfully ${
              this.templateDoesntExist ? "created" : "edited"
            }.`,
            type: "is-success",
            pauseOnHover: false,
            position: "is-bottom",
          });
          // Reset
          this.fetchTemplate(this.selectedGeography, this.selectedDeviceType);
        } else {
          this.$buefy.snackbar.open({
            message: `An error occured when ${
              this.templateDoesntExist ? "creating" : "editing"
            } the configuration. Try again.`,
            type: "is-danger",
            pauseOnHover: false,
            position: "is-bottom",
          });
        }
      }
      this.saveButtonLoading = false;
    },
    cancelHandler() {
      this.resetThresholds();
      this.selectedGeography = "";
      this.selectedDeviceType = "";
    },
  },

  beforeUnmount() {
    this.resetThresholds();
    this.geographies = []; // All sites in dropdown
    this.deviceTypes = []; // A site's key-value pair of its lower and upper limits
    this.units = [];
  },

  mounted() {
    this.fetchDropdownData();
  },
};
</script>

<style scoped>
.tg > .mdi:before,
.mdi-set {
  color: grey;
  font-size: 40px !important;
}
</style>
