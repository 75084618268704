<template>
  <div class="column">
    <div class="card">
      <header class="card-header is-danger p-2">
        <p class="card-header-title has-text-weight-normal is-size-6">
          Generate the script for live deployment
        </p>
        <!-- <button class="card-header-icon" aria-label="more options">
          <span class="icon">
            <i class="mdi mdi-file-document-plus mdi-40px" aria-hidden="true"></i>
          </span>
        </button> -->
      </header>
      <div class="card-content py-3 px-2">
        <b-loading
          :is-full-page="false"
          v-model="isLoading"
          :can-cancel="true"
        ></b-loading>
        <div>
          <b-field class="px-3">
            <b-select
              v-model="selectedDeploymentMedium"
              expanded
              icon="map-marker-path"
              placeholder="Select the site to configure"
            >
              <option disabled value="">Select the Deployment Medium</option>
              <option
                :disabled="!medium.name.includes('raspberry')"
                v-for="medium in deploymentMedia"
                :value="medium.name"
                :key="medium.uuid"
              >
                Deploying using {{ medium.displayName }}
              </option>
            </b-select>
          </b-field>
        </div>

        <!-- Show site selection -->
        <div class="p-3" v-if="selectedDeploymentMedium">
          <div style="min-height: 20vh; overflow: auto">
            <div class="pb-3 pt-1">
              <b-message v-if="showWarning" type="is-warning" has-icon>
                Generating deployment script. This shouldn't take too long...
              </b-message>
              <b-message v-if="showFailedGeneration" type="is-danger" has-icon>
                An error occured while generating the script. Try again.
              </b-message>
              <b-message v-if="downloadLinkReady" type="is-success" has-icon>
                Script successfuly generated and can now be downloaded.
              </b-message>
            </div>

            <div class="px-2" v-if="sitesToDeploy.length !== 0">
              <div>
                <div
                  v-for="(site, index) in sitesToDeploy"
                  :key="index"
                  :class="index + 1 === sitesToDeploy.length ? 'pb-4' : ''"
                  class="has-text-grey is-size-6 is-capitalized is-flex is-align-content-center"
                >
                  <i class="mdi mdi-circle-small features-item-icon pl-2"
                    ><span class="status"></span
                  ></i>
                  <span class="features-item-text is-align-self-center pl-2">{{
                    site
                  }}</span>
                </div>

                <footer v-show="!isLoading" class="card-footer">
                  <b-button
                    :loading="saveButtonLoading"
                    style="height: 50px"
                    type="is-link"
                    icon-left="python"
                    @click="generateHandler"
                    class="is-radiusless card-footer-item"
                    >Generate for {{ sitesToDeploy.length }}
                    {{
                      sitesToDeploy.length === 1 ? "site" : "sites"
                    }}</b-button
                  >
                  <b-button
                    :disabled="!downloadLinkReady"
                    style="height: 50px"
                    icon-left="download"
                    @click="downloadScriptHandler"
                    type="is-success"
                    class="is-radiusless card-footer-item"
                    >Download {{ this.scriptUrl }}</b-button
                  >
                </footer>
              </div>
            </div>
            <div v-else>
              <div
                class="is-flex pt-5 is-align-items-center is-justify-content-center"
              >
                <div class="is-flex is-flex-direction-column">
                  <div
                    class="is-flex is-align-items-center is-justify-content-center"
                  >
                    <div class="is-flex">
                      <p class="is-align-self-center tg">
                        <i class="mdi mdi-earth-minus" aria-hidden="true"></i>
                      </p>
                    </div>
                  </div>
                  <p class="is-size-6 pb-5 has-text-grey">No sites {{ sites.length !== 0 ? 'have been selected' : 'have been registered with that deployment medium' }}</p>
                </div>
              </div>
            </div>
            <hr v-show="sites.length !== 0" />
            <div v-if="sites.length !== 0" class="card-content py-3">
              <b-message auto-close :duration="4000" type="is-info" has-icon>
                Note that sites deployed together will share the same Remote Access Port.</b-message>
              <div
                class="is-flex is-flex-wrap-wrap is-justify-content-space-between ml-6"
              >
                <div v-for="site in sites" :key="site.uuid" class="sitebox">
                  <b-field>
                    <b-checkbox
                      v-model="sitesToDeploy"
                      :native-value="site.name"
                    >
                      {{ site.displayName }}
                    </b-checkbox>
                  </b-field>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Default backdrop -->
        <div v-else>
          <div
            style="height: 300px"
            class="is-flex is-align-items-center is-justify-content-center"
          >
            <div class="is-flex is-flex-direction-column">
              <div
                class="is-flex is-align-items-center is-justify-content-center"
              >
                <div class="is-flex">
                  <p class="is-align-self-center tg">
                    <!-- <i class="mdi mdi-linux mdi-40px" aria-hidden="true"></i> -->
                    <i
                      class="mdi mdi-raspberry-pi mdi-40px"
                      aria-hidden="true"
                    ></i>
                    <!-- <i
                      class="mdi mdi-microsoft-windows mdi-40px"
                      aria-hidden="true"
                    ></i> -->
                  </p>
                </div>
              </div>
              <p class="is-size-6 pb-5 has-text-grey">
                Generate the script to deploy sites using a preferred medium
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useUserStore } from "@/store";
import { API, snakeToStartCase, API_BASE_URL } from "@/utils";
export default {
  setup() {
    const store = useUserStore();
    return { store };
  },
  data() {
    return {
      scriptUrl: "",
      scriptDowloadLink: "",
      sites: [], // All sites in dropdown
      sitesToDeploy: [], // All sites in dropdown
      deploymentMedia: [],
      selectedDeploymentMedium: "", // Selected ste in dropdown

      isLoading: false,
      showWarning: false,
      downloadLinkReady: false,
      saveButtonLoading: false,
      showFailedGeneration: false,
    };
  },
  watch: {
    selectedDeploymentMedium(medium) {
      this.sitesToDeploy = [];
      this.resetDownloadProps();
      // If the select wasn't reset
      if (medium !== "") {
        this.fetchSites(medium);
      }
    },
    sitesToDeploy() {
      this.resetDownloadProps();
    },
  },
  methods: {
    resetDownloadProps() {
      this.scriptUrl = "";
      this.showWarning = false;
      this.saveButtonLoading = false;
      this.downloadLinkReady = false;
      this.showFailedGeneration = false;
    },
    async fetchSites(medium) {
      this.isLoading = true;
      let response = await API.get("/sites", {
        data: { deployment_medium: medium },
      });
      if (!response.error.exists) {
        this.sites = response.data.items.map((s) => {
          return {
            ...s,
            displayName: snakeToStartCase(s.name),
          };
        });
      }
      this.isLoading = false;
    },
    async downloadScriptHandler() {
      window.open(API_BASE_URL + this.scriptDowloadLink, "_blank");
    },

    async generateHandler() {
      if (this.sitesToDeploy.length > 0) {
        this.resetDownloadProps();

        // Do this after
        this.saveButtonLoading = true;
        this.showWarning = true;

        let response = await API.post("/tasks", {
          data: {
            request_type: "",
            task_type: "generate_script",
            request_body: {
              sites: this.sitesToDeploy,
            },
          },
        });

        if (response.code === 200 && !response.error.exists) {
          let taskId = response.data.task_id;
          let intervalShouldClear = false;
          let retries = 0;

          let intervalID = setInterval(async () => {
            if (intervalShouldClear || retries >= 10) {
              clearInterval(intervalID);
            }
            retries += 1;

            // Server error during generation
            if (retries >= 10 && this.scriptUrl === "") {
              this.resetDownloadProps();
              this.showFailedGeneration = true;
            }

            let taskResponse = await API.get(`/task/${taskId}`);

            // Task is complete
            if (
              taskResponse.code === 200 &&
              !taskResponse.error.exists &&
              taskResponse.data.message === "task complete"
            ) {
              // Save url
              this.resetDownloadProps();
              intervalShouldClear = true;
              this.downloadLinkReady = true;
              this.scriptDowloadLink = taskResponse.data.data;
              this.scriptUrl = taskResponse.data.data.split("/").lastElement();
            }
          }, 1000);
        }
      }
    },
    async fetchDeploymentMedia() {
      this.isLoading = true;
      let response = await API.get("/deployment-media");
      if (!response.error.exists) {
        this.deploymentMedia = response.data.items.map((m) => {
          return {
            ...m,
            displayName: snakeToStartCase(m.name),
          };
        });
      }
      this.isLoading = false;
    },
  },
  mounted() {
    this.fetchDeploymentMedia();
  },
};
</script>

<style scoped>
.tg > .mdi:before,
.mdi-set {
  color: grey;
  font-size: 40px !important;
}
.sitebox {
  width: 160px;
  height: 35px;
}
</style>
