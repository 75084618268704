<template>
  <section>
    <b-modal :width="768" v-if="show" :active="show">
      <div class="column">
        <div class="card">
          <b-loading
            :is-full-page="true"
            v-model="isLoading"
            :can-cancel="true"
          ></b-loading>
          <header class="card-header">
            <p class="card-header-title is-size-6 my-2 has-text-weight-bold">
              Edit Company
            </p>
            <button class="card-header-icon" aria-label="more options">
              <span class="icon">
                <i
                  class="mdi mdi-information-outline mdi-40px"
                  aria-hidden="true"
                ></i>
              </span>
            </button>
          </header>
          <div>
            <b-message v-if="errorMessage != ''" type="is-danger" has-icon>
              {{ errorMessage }}
            </b-message>
          </div>
          <div class="card-content pb-1">
            <div class="content">
              <div class="is-flex">
                <div class="column">
                  <section>
                    <b-field class="is-capitalized" label="Company Name">
                      <b-input
                        icon="label"
                        v-model="name"
                        placeholder="e.g. Zysod Communications Limited"
                      ></b-input>
                    </b-field>
                    <b-field label="License End Date">
                      <b-datetimepicker
                        expanded
                        icon="timer"
                        :min-datetime="minDatetime"
                        v-model="datetime"
                      ></b-datetimepicker>
                    </b-field>

                    <b-field label="Company Type">
                      <b-select
                        v-model="company"
                        placeholder="e.g. MNO"
                        expanded
                        icon="domain"
                      >
                        <option disabled value="">
                          Choose the company type
                        </option>
                        <option value="mno">
                          <span class="is-capitalized">MNO</span>
                        </option>
                        <option value="third_party">
                          <span class="is-capitalized">Third Party</span>
                        </option>
                        <option disabled value="in_house">
                          In House (Zysod-related)
                        </option>
                      </b-select>
                    </b-field>

                    <b-field v-if="showMNONetwork" label="Associated Network">
                      <b-select
                        v-model="mnoNetwork"
                        placeholder="e.g. MTN"
                        expanded
                        icon="signal"
                      >
                        <option disabled value="">
                          Choose the associated Network
                        </option>
                        <option
                          :key="net.uuid"
                          v-for="net in networks"
                          :value="net.name"
                        >
                          {{ snakeToStartCase(net.name) }}
                        </option>
                      </b-select>
                    </b-field>

                    <b-field
                      label="Status"
                      type="is-danger"
                      :message="
                        enabled
                          ? ''
                          : 'Be aware! Deactivating a company turns off the network in all its remotely-reachable sites.'
                      "
                    >
                      <b-radio-button
                        expanded
                        v-model="enabled"
                        :native-value="false"
                        type="is-danger is-light"
                      >
                        <b-icon icon="close"></b-icon>
                        <span>Deactivated</span>
                      </b-radio-button>

                      <b-radio-button
                        expanded
                        v-model="enabled"
                        :native-value="true"
                        type="is-success is-light"
                      >
                        <b-icon icon="check"></b-icon>
                        <span>Activated</span>
                      </b-radio-button>
                    </b-field>

                    <b-field label="Company Address">
                      <b-input
                        v-model="address"
                        type="textarea"
                        placeholder="e.g. No, 2, Address Street..."
                      ></b-input>
                    </b-field>

                    <!-- <b-field label="Company Image">
                      <b-field
                        class="file is-dark"
                        :class="{ 'has-name': !!file }"
                      >
                        <b-upload v-model="file" class="file-label">
                          <span class="file-cta">
                            <b-icon class="file-icon" icon="upload"></b-icon>
                            <span class="file-label">Click to upload</span>
                          </span>
                          <span class="file-name" v-if="file">
                            {{ file.name }}
                          </span>
                        </b-upload>
                      </b-field>
                    </b-field> -->
                  </section>
                </div>
              </div>
            </div>
          </div>
          <footer class="card-footer">
            <b-button
              style="height: 50px"
              @click="$emit('response', false)"
              type="is-light"
              class="is-radiusless card-footer-item"
              >Cancel</b-button
            >
            <b-button
              :loading="confirmLoading"
              style="height: 50px"
              @click="confirmHandler"
              type="is-link"
              class="is-radiusless card-footer-item"
              >Save</b-button
            >
          </footer>
        </div>
      </div>
    </b-modal>
  </section>
</template>

<script>
import { API, snakeToStartCase } from "@/utils";
import moment from "moment";
import { DEFAULT_FORMAT, DEFAULT_UI_FORMAT } from "@/constants";

export default {
  props: {
    show: {
      default: false,
      type: Boolean,
    },
    uuid: String,
  },

  data() {
    return {
      isLoading: false,
      confirmLoading: false,
      enabled: true,

      errorMessage: "",

      name: "",
      minDatetime: new Date(),
      datetime: new Date(),
      address: "",

      company: "",
      mnoNetwork: "",
      networks: [],
      showMNONetwork: false,
    };
  },
  watch: {
    company(value) {
      if (value === "mno") {
        this.showMNONetwork = true;
      } else {
        // Reset
        this.mnoNetwork = "";
        this.showMNONetwork = false;
      }
    },
  },
  async mounted() {
    this.isLoading = true;
    let response = await API.get("/networks");
    if (response.code === 200) {
      this.networks = response.data.items;
    }
    await this.fetchCompanyData();
    this.isLoading = false;
  },
  methods: {
    snakeToStartCase(v) {
      return snakeToStartCase(v);
    },
    async fetchCompanyData() {
      this.isLoading = true;
      let response = await API.get(`/company/${this.uuid}`);
      if (![200, 201].includes(response.code)) {
        this.errorMessage = response.error.message;
        this.isLoading = false;
        return;
      }
      this.name = response.data.name;
      this.datetime = moment(
        response.data.license_end,
        DEFAULT_UI_FORMAT
      ).toDate();
      this.address = response.data.address;
      this.company = response.data.type;
      this.enabled = response.data.enabled;
      if (response.data.network_id_name) {
        this.mnoNetwork = response.data.network_id_name
          ? this.networks.filter(
              (n) => n.name === response.data.network_id_name
            )[0].name
          : "";
        this.showMNONetwork = true;
      }
      this.isLoading = false;
    },

    async confirmHandler() {
      let error = "";
      this.errorMessage = "";
      this.confirmLoading = true;

      let name = this.name.trim();
      let address = this.address.trim();

      if (name.length < 5 || name.length > 30) {
        error = "The company name must be between 5 and 30 characters";
      } else if (!["mno", "third_party"].includes(this.company)) {
        error = "A valid company type has not been selected";
      } else if (address.length < 5 || address.length > 100) {
        error = "The company address must be between 5 and 100 characters";
      } else if (this.showMNONetwork && this.mnoNetwork === "") {
        error =
          "The network to be associated with the MNO has not been selected";
      } else if (this.licenseDuration <= 0) {
        error = "The license duration must be a positive number";
      }
      if (error !== "") {
        this.errorMessage = error;
        this.confirmLoading = false;
        return;
      }

      const companyData = Object.entries({
        license_end: moment(this.datetime).format(DEFAULT_FORMAT),
        address: this.address.trim(),
        name: this.name.trim(),
        enabled: this.enabled,
        type: this.company,
        network_id_name: this.mnoNetwork === "" ? null : this.mnoNetwork,
      }).map((k) => {
        return { name: k[0], value: k[1] };
      });

      let response = await API.put(`/company/${this.uuid}`, {
        data: { changes: companyData },
      });

      this.confirmLoading = false;
      if (response.code === 200 && !response.error.exists) {
        this.$emit("response", true);
      } else {
        this.errorMessage = response.error.message;
      }
    },
  },
};
</script>
<style scoped>
.field {
  margin-bottom: 1.4rem !important;
}
</style>
