<template>
  <section>
    <b-modal :width="1368" :active="show">
      <div class="column">
        <div class="card">
          <header ref="errorRef" class="card-header">
            <p
              class="card-header-title is-capitalzed is-size-6 my-2 has-text-weight-bold"
            >
              Create New Role
            </p>
            <button class="card-header-icon" aria-label="more options">
              <span class="icon">
                <i class="mdi mdi-plus mdi-40px" aria-hidden="true"></i>
              </span>
            </button>
          </header>

          <div>
            <b-message v-if="errorMessage != ''" type="is-danger" has-icon>
              {{ errorMessage }}
            </b-message>
          </div>

          <div class="card-content pb-1">
            <div class="content pb-5">
              <div class="columns">
                <div class="column is-4">
                  <b-field
                    style="margin-bottom: 0 !important"
                    label="Role name"
                  >
                    <b-input
                      icon="form-textbox"
                      type="text"
                      placeholder="E.g. CustomerService Personnel"
                      v-model="roleName"
                    />
                  </b-field>
                </div>
                <div class="column">
                  <b-field
                    style="margin-bottom: 0 !important"
                    label="Role Description"
                  >
                    <b-input
                      icon="form-textbox"
                      type="text"
                      placeholder="E.g. Permissions for Zysod customer support"
                      v-model="roleDescription"
                    />
                  </b-field>
                </div>
              </div>
              <hr class="mt-2" />
              <p class="has-text-weight-bold is-size-6">
                Select the desired permissions for the role &nbsp;
                <span>
                  <b-tag size="is-medium"
                    :type="choices.length < 5 ? 'is-danger' : 'is-success'"
                    >{{ `${choices.length} selected` }}</b-tag
                  >
                </span>
              </p>
              <div class="is-flex is-flex-wrap-wrap">
                <b-checkbox
                  class="p-2"
                  :key="index"
                  v-for="(permission, index) in permissions"
                  v-model="choices"
                  :native-value="permission.name"
                >
                  <b-tag class="mr-2">{{ permission.name }}</b-tag>
                </b-checkbox>
              </div>
            </div>
          </div>

          <footer class="card-footer">
            <b-button
              style="height: 50px"
              @click="$emit('response', false)"
              type="is-light"
              class="is-radiusless card-footer-item"
              >Cancel</b-button
            >
            <b-button
              style="height: 50px"
              :loading="confirmLoading"
              @click="confirmHandler"
              type="is-link"
              class="is-radiusless card-footer-item"
              >Save</b-button
            >
          </footer>
        </div>
      </div>
    </b-modal>
  </section>
</template>

<script>
import { API } from "@/utils";

export default {
  props: {
    show: {
      default: false,
      type: Boolean,
    },
    permissions: Array,
  },

  data() {
    return {
      confirmLoading: false,

      errorMessage: "",
      choices: [],
      roleName: "",
      roleDescription: "",
    };
  },

  methods: {
    async confirmHandler() {
      let error = "";
      this.confirmLoading = true;

      if (this.roleName.length < 5 || this.roleName.length > 20) {
        error = "The role name should be between 5 and 20 characters.";
      }

      if (this.roleDescription.length < 10) {
        error =
          "The role description is not explanatory enough. Use more characters.";
      }

      if (this.choices.length < 5) {
        error = "Please select at least 5 permissions for the new role.";
      }

      if (error !== "") {
        this.errorMessage = error;
        this.confirmLoading = false;
        this.$refs.errorRef.scrollIntoView();
        return;
      }

      this.confirmLoading = false;
      let response = await API.post("/roles", {
        data: {
          description: this.roleDescription.trim(),
          name: this.roleName.trim(),
          permissions: this.choices,
        },
      });

      if (response.code === 201 && !response.error.exists) {
        this.$emit("response", true);
        this.confirmLoading = false;
        this.errorMessage = ""; // Clear
      } else {
        this.errorMessage = response.error.message;
        this.$refs.errorRef.scrollIntoView({ behavior: "smooth" });
      }
    },
  },
};
</script>
<style scoped>
.field {
  margin-bottom: 1.4rem !important;
}
</style>
