
export const API_ERRORS = {
    "invalidCredentials": "Incorrect email address or password.",
    "siteAlreadyExists": "Site with similar details already exists.",
    "SiteNotFound": "The site was not found.",
    "companyAlreadyExists": "Company with similar details already exists.",
    "userAlreadyExists": "User with similar details already exists.",
    "roleAlreadyExists": "Role with similar details already exists.",
    "networkAlreadyExists": "Network with similar details already exists.",
    "unverifiedUserAccount": "Account is yet to be verified.",
    "deactivatedUserAccount": "Your account is currently not active. Contact us to re-activate it.",
    "david_alarm_thresholdNotFound": "Alarm Threshold not found for this site"
}