<template>
  <div class="pb-4">
    <!-- <div class="tabs is-left is-full is-size-7 mb-4">
      <ul>
        <li class="mx-0 mr-4" :class="{ 'is-active': true }">
          <a>
            <span class="icon is-small"
              ><i class="mdi mdi-trending-up" aria-hidden="true"></i
            ></span>
            <span>Summary</span>
          </a>
        </li>
      </ul>
    </div> -->
    <div>
      <!-- Ist row -->
      <div class="columns">
        <div class="column is-6">
          <div class="card">
            <b-loading
              :is-full-page="false"
              v-model="alarmLoading"
              :can-cancel="true"
            ></b-loading>
            <!-- Alarms Header -->
            <header class="card-header-title p-1">
              <p style="" class="card-header-title is-size-6">
                <span class="pb-1 my-2 hbt">Alarms</span>
              </p>
              <button class="card-header-icon" aria-label="more options">
                <span class="icon">
                  <i
                    class="mdi mdi-alarm-light mdi-40px"
                    aria-hidden="true"
                  ></i>
                </span>
              </button>
            </header>

            <div class="card-content dbhcard pt-0">
              <div
                class="px-4 is-flex is-flex-direction-row is-justify-content-space-around"
              >
                <div
                  class="is-flex is-flex-direction-column is-align-items-center is-justify-content-center"
                >
                  <span
                    :class="
                      alarms.active && alarms.active > 0
                        ? 'is-alert'
                        : 'is-zlue'
                    "
                    class="is-size-1 mb-0 pb-0"
                  >
                    {{ alarms.active || "0" }}
                  </span>
                  <span class="mt-0 pt-0 is-capitalized has-text-grey is-14"
                    >Active Alarms
                  </span>
                </div>
                <div class="hbtr"></div>
                <div
                  class="is-flex is-flex-direction-column is-align-items-center is-justify-content-center"
                >
                  <span
                    :class="
                      alarms.inactive && alarms.inactive > 0
                        ? 'is-alert'
                        : 'is-zlue'
                    "
                    class="is-size-1 mb-0 pb-0"
                  >
                    {{ alarms.inactive || "0" }}
                  </span>
                  <span class="mt-0 pt-0 is-capitalized has-text-grey is-14"
                    >Inactive Alarms
                  </span>
                </div>
                <div class="hbtr"></div>
                <div
                  class="is-flex is-flex-direction-column is-align-items-center is-justify-content-center"
                >
                  <span class="is-zlue is-size-1">
                    {{ alarms.sites || "0" }}
                    <span class="is-size-7 has-text-grey">
                      / {{ alarms.total_sites || "0" }}</span
                    >
                  </span>
                  <span class="mt-0 pt-0 is-capitalized has-text-grey is-14"
                    >Affected Sites
                  </span>
                </div>
                <div class="hbtr"></div>
                <div
                  class="is-flex is-flex-direction-column is-align-items-center is-justify-content-center"
                >
                  <span class="is-zlue is-size-1">
                    {{ alarms.affiliations || "0" }}
                    <span class="is-size-7 has-text-grey">
                      / {{ alarms.total_affiliations || "0" }}</span
                    >
                  </span>
                  <span class="mt-0 pt-0 is-capitalized has-text-grey is-14"
                    >Affected Companies
                  </span>
                </div>
                <!-- <div class="hbtr"></div> -->
                <!-- <div class="is-align-self-center">
                <div class="is-flex is-align-items-center  is-flex-direction-column">
                  <span class="is-zlue is-size-3">
                    4 <span class="is-size-7 has-text-grey"> / 45</span>
                  </span>
                  <span class="mt-0 is-capitalized has-text-grey is-14"
                    >Live Sites
                  </span>
                </div>

                <div class="my-4"></div>

                <div class="is-flex is-align-items-center is-flex-direction-column">
                  <span class="is-zlue is-size-3">
                    38 <span class="is-size-7 has-text-grey"> / 45</span>
                  </span>
                  <span class="mt-0 is-capitalized has-text-grey is-14"
                    >Deployed Sites
                  </span>
                </div>
              </div> -->
              </div>
            </div>
          </div>
          <br />

          <!-- Tickets Header -->
          <div class="card">
            <b-loading
              :is-full-page="false"
              v-model="ticketLoading"
              :can-cancel="true"
            ></b-loading>
            <header class="card-header-title p-1">
              <p style="" class="card-header-title is-size-6">
                <span class="pb-1 my-2 hbt">Tickets</span>
              </p>
              <button class="card-header-icon" aria-label="more options">
                <span class="icon">
                  <i
                    class="mdi mdi-tag-multiple mdi-40px"
                    aria-hidden="true"
                  ></i>
                </span>
              </button>
            </header>
            <div class="card-content dbhcard pt-0">
              <div
                class="px-4 is-flex is-flex-direction-row is-justify-content-space-around"
              >
                <div
                  class="is-flex is-flex-direction-column is-align-items-center is-justify-content-center"
                >
                  <span class="is-zlue is-size-1 mb-0 pb-0">
                    {{ tickets.total }}
                  </span>
                  <span class="mt-0 pt-0 is-capitalized has-text-grey is-14"
                    >Total Tickets
                  </span>
                </div>
                <div class="hbtr"></div>
                <div
                  class="is-flex is-flex-direction-column is-align-items-center is-justify-content-center"
                >
                  <span class="is-zlue is-size-1">
                    {{ tickets.open }}
                    <span class="is-size-7 has-text-grey">
                      / {{ tickets.total }}</span
                    >
                  </span>
                  <span class="mt-0 pt-0 is-capitalized has-text-grey is-14"
                    >Open Tickets
                  </span>
                </div>
                <div class="hbtr"></div>
                <div
                  class="is-flex is-flex-direction-column is-align-items-center is-justify-content-center"
                >
                  <span class="is-zlue is-size-1">
                    {{ tickets.ttc }}
                    <span class="is-size-7 has-text-grey"> mins</span>
                  </span>
                  <span class="mt-0 pt-0 has-text-grey is-14"
                    >Average TTC
                  </span>
                </div>
                <!-- <div class="hbtr"></div> -->
                <!-- <div class="is-align-self-center">
                <div class="is-flex is-align-items-center  is-flex-direction-column">
                  <span class="is-zlue is-size-3">
                    4 <span class="is-size-7 has-text-grey"> / 45</span>
                  </span>
                  <span class="mt-0 is-capitalized has-text-grey is-14"
                    >Live Sites
                  </span>
                </div>

                <div class="my-4"></div>

                <div class="is-flex is-align-items-center is-flex-direction-column">
                  <span class="is-zlue is-size-3">
                    38 <span class="is-size-7 has-text-grey"> / 45</span>
                  </span>
                  <span class="mt-0 is-capitalized has-text-grey is-14"
                    >Deployed Sites
                  </span>
                </div>
              </div> -->
              </div>
            </div>
          </div>
        </div>

        <div class="column is-6">
          <!-- Sites Header -->

          <div class="card">
            <b-loading
              :is-full-page="false"
              v-model="siteLoading"
              :can-cancel="true"
            ></b-loading>
            <header class="card-header-title p-1">
              <p style="" class="card-header-title is-size-6">
                <span class="pb-1 my-2 hbt">Sites</span>
              </p>
              <button class="card-header-icon" aria-label="more options">
                <span class="icon">
                  <i class="mdi mdi-earth mdi-40px" aria-hidden="true"></i>
                </span>
              </button>
            </header>
            <div class="card-content dbcard pt-0">
              <div
                class="px-4 is-flex is-flex-direction-row is-justify-content-space-around"
              >
                <div
                  class="is-flex is-flex-direction-column is-align-items-center is-justify-content-center"
                >
                  <span class="is-zlue is-size-1 mb-0 pb-0">
                    {{ sites.total }}
                  </span>
                  <span class="mt-0 pt-0 is-capitalized has-text-grey is-14"
                    >Registered Sites
                  </span>
                </div>
                <div class="hbtr"></div>
                <div
                  class="is-flex is-flex-direction-column is-align-items-center is-justify-content-center"
                >
                  <span
                    :class="
                      sites.down && sites.down > 0 ? 'is-alert' : 'is-zlue'
                    "
                    is-zlue
                    class="is-size-1"
                  >
                    {{ sites.down }}
                    <span class="is-size-7 has-text-grey">
                      / {{ sites.deployed || sites.total }}</span
                    >
                  </span>
                  <span class="mt-0 pt-0 is-capitalized has-text-grey is-14"
                    >Down Sites
                  </span>
                </div>
                <div class="hbtr"></div>
                <div
                  class="is-flex is-flex-direction-column is-align-items-center is-justify-content-center"
                >
                  <span
                    :class="
                      ![null, undefined].includes(sites.live) &&
                      sites.live < sites.total
                        ? 'is-alert'
                        : 'is-zlue'
                    "
                    class="is-size-1"
                  >
                    {{ sites.live }}
                    <span class="is-size-7 has-text-grey">
                      / {{ sites.total }}</span
                    >
                  </span>
                  <span class="mt-0 pt-0 is-capitalized has-text-grey is-14"
                    >Live Sites
                  </span>
                </div>
                <div class="hbtr"></div>
                <div
                  class="is-flex is-flex-direction-column is-align-items-center is-justify-content-center"
                >
                  <span
                    :class="
                      ![null, undefined].includes(sites.deployed) &&
                      sites.deployed < sites.total
                        ? 'is-alert'
                        : 'is-zlue'
                    "
                    class="is-size-1"
                  >
                    {{ sites.deployed }}
                    <span class="is-size-7 has-text-grey">
                      / {{ sites.total }}</span
                    >
                  </span>
                  <span class="mt-0 pt-0 is-capitalized has-text-grey is-14"
                    >Deployed Sites
                  </span>
                </div>
              </div>

              <hr class="mb-3" />
              <div
                class="is-flex is-flex-direction-row is-justify-content-space-around"
              >
                <!-- Network Pie Chart -->
                <div
                  v-if="!siteLoading"
                  class="is-flex is-flex-direction-column is-align-items-center"
                >
                  <PieChart
                    class="pb-2 mt-1"
                    :series="sites.networks.series"
                    :labels="sites.networks.labels"
                  />

                  <p class="is-capitalized has-text-grey is-size-7">
                    Network Distribution
                  </p>
                </div>

                <!-- Company Pie Chart -->
                <div
                  v-if="!siteLoading"
                  class="is-flex is-flex-direction-column is-align-items-center"
                >
                  <PieChart
                    class="pb-2 mt-1"
                    :series="sites.companies.series"
                    :labels="sites.companies.labels"
                  />

                  <p class="is-capitalized has-text-grey is-size-7">
                    Company Distribution
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Second row -->
      <div class="columns mb-0">
        <div class="column is-4">
          <div class="card">
            <b-loading
              :is-full-page="false"
              v-model="userLoading"
              :can-cancel="true"
            ></b-loading>
            <header class="card-header-title p-1">
              <p style="" class="card-header-title is-size-6">
                <span class="pb-1 my-2 hbt">Users</span>
              </p>
              <button class="card-header-icon" aria-label="more options">
                <span class="icon">
                  <i
                    class="mdi mdi-account-group mdi-40px"
                    aria-hidden="true"
                  ></i>
                </span>
              </button>
            </header>
            <div class="card-content dbcard pt-0">
              <div
                class="px-4 is-flex is-flex-direction-row is-justify-content-space-around"
              >
                <div
                  class="is-flex is-flex-direction-column is-align-items-center is-justify-content-center"
                >
                  <span class="is-zlue is-size-1">
                    {{ users.active }}
                    <span class="is-size-7 has-text-grey">
                      / {{ users.total }}</span
                    >
                  </span>
                  <span class="mt-0 pt-0 is-capitalized has-text-grey is-14"
                    >Active Users
                  </span>
                </div>
                <div class="hbtr"></div>
                <div
                  class="is-flex is-flex-direction-column is-align-items-center is-justify-content-center"
                >
                  <span class="is-zlue is-size-1">
                    {{ users.logged_in }}
                    <span class="is-size-7 has-text-grey">
                      / {{ users.total }}</span
                    >
                  </span>
                  <span class="mt-0 pt-0 is-capitalized has-text-grey is-14"
                    >Logged In Users
                  </span>
                </div>
              </div>

              <hr class="mb-3" />
              <div
                class="is-flex is-flex-direction-row is-justify-content-space-around"
              >
                <!-- Network Pie Chart -->
                <div
                  v-if="!userLoading"
                  class="is-flex is-flex-direction-column is-align-items-center"
                >
                  <div>
                    <PieChart
                      class="pb-2 mt-0"
                      width="310px"
                      :series="users.series"
                      :labels="users.labels"
                    />
                  </div>

                  <p class="is-capitalized has-text-grey is-size-7">
                    Company Distribution
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="column is-8">
          <div class="card">
            <b-loading
              :is-full-page="false"
              v-model="trafficLoading"
              :can-cancel="true"
            ></b-loading>
            <header
              class="card-header-title is-justify-content-space-between p-1"
            >
              <div
                style=""
                class="pb-4 p-4 my-1 is-flex is-flex-direction-column is-size-6"
              >
                <p class="pb-1">All Time Traffic</p>
                <p class="hbt"></p>
              </div>
              <button class="card-header-icon" aria-label="more options">
                <span class="icon">
                  <i
                    class="mdi mdi-chart-gantt mdi-40px"
                    aria-hidden="true"
                  ></i>
                </span>
              </button>
            </header>
            <div class="card-content dbcard pt-0">
              <div
                class="px-4 is-flex is-flex-direction-row is-justify-content-space-around"
              >
                <div
                  class="is-flex is-flex-direction-column is-align-items-center is-justify-content-center"
                >
                  <span class="is-zlue is-size-1 mb-0 pb-0">
                    0 <span class="is-size-7 has-text-grey"> Erlangs</span>
                  </span>
                  <span class="mt-0 pt-0 is-capitalized has-text-grey is-14"
                    >Voice Traffic
                  </span>
                </div>
                <div class="hbtr"></div>
                <div
                  class="is-flex is-flex-direction-column is-align-items-center is-justify-content-center"
                >
                  <span class="is-zlue is-size-1">
                    0 <span class="is-size-7 has-text-grey"> Bytes</span>
                  </span>
                  <span class="mt-0 pt-0 is-capitalized has-text-grey is-14"
                    >Data Traffic
                  </span>
                </div>
                <div class="hbtr"></div>
                <div
                  class="is-flex is-flex-direction-column is-align-items-center is-justify-content-center"
                >
                  <span class="is-zlue is-size-1">
                    0 <span class="is-size-7 has-text-grey"> Units</span>
                  </span>
                  <span class="mt-0 pt-0 is-capitalized has-text-grey is-14"
                    >Voice Terminations
                  </span>
                </div>
                <div class="hbtr"></div>
                <div
                  class="is-flex is-flex-direction-column is-align-items-center is-justify-content-center"
                >
                  <span class="is-zlue is-size-1">
                    0 <span class="is-size-7 has-text-grey"> Units</span>
                  </span>
                  <span class="mt-0 pt-0 is-capitalized has-text-grey is-14"
                    >Data Terminations
                  </span>
                </div>
              </div>

              <hr class="mb-3" />
              <div class="is-flex is-justify-content-center">
                <div style="width: 1000px; height: 240px">
                  <GroupedBarChart :categories="categories" :series="series" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- <div class="column">
        <div class="is-flex dbcard is-flex-direction-row card">
          <div class="is-flex is-flex-direction-column">
            <span class="is-zlue is-size-1"> 5 </span>
            <span class="is-capitalized has-text-grey is-14"
              >Total Networks
            </span>
          </div>

          <div class="is-align-self-center">
            <i class="mdi mdi-signal-variant has-text-grey card-icon"></i>
          </div>
        </div>
      </div> -->
      </div>

      <!-- <div class="is-size-7 pt-0 mb-5 has-text-grey has-text-centered">
        Last updated: Just now.
      </div> -->
    </div>
  </div>

  <!-- <div class="column is-8">
    <div class="card">
      <header
        class="card-header-title is-justify-content-space-between p-1"
      >
        <div
          style=""
          class="pb-4 p-4 my-1 is-flex is-flex-direction-column is-size-6"
        >
          <p class="pb-1">All Time Traffic</p>
          <p class="hbt"></p>
        </div>
        <button class="card-header-icon" aria-label="more options">
          <span class="icon">
            <i
              class="mdi mdi-chart-gantt mdi-40px"
              aria-hidden="true"
            ></i>
          </span>
        </button>
      </header>

      <div class="card-content dbhcard pt-0">
        <div
          class="px-4 is-flex is-flex-direction-row is-justify-content-space-around"
        >
          <div
            class="is-flex is-flex-direction-column is-align-items-center is-justify-content-center"
          >
            <span class="is-zlue is-size-1 mb-0 pb-0">
              3K <span class="is-size-7 has-text-grey"> Erlangs</span>
            </span>
            <span class="mt-0 pt-0 is-capitalized has-text-grey is-14"
              >Voice Traffic
            </span>
          </div>
          <div class="hbtr"></div>
          <div
            class="is-flex is-flex-direction-column is-align-items-center is-justify-content-center"
          >
            <span class="is-zlue is-size-1">
              15G <span class="is-size-7 has-text-grey"> Bytes</span>
            </span>
            <span class="mt-0 pt-0 is-capitalized has-text-grey is-14"
              >Data Traffic
            </span>
          </div>
          <div class="hbtr"></div>
          <div
            class="is-flex is-flex-direction-column is-align-items-center is-justify-content-center"
          >
            <span class="is-zlue is-size-1">
              12M <span class="is-size-7 has-text-grey"> Units</span>
            </span>
            <span class="mt-0 pt-0 is-capitalized has-text-grey is-14"
              >Voice Terminations
            </span>
          </div>
          <div class="hbtr"></div>
          <div
            class="is-flex is-flex-direction-column is-align-items-center is-justify-content-center"
          >
            <span class="is-zlue is-size-1">
              69K <span class="is-size-7 has-text-grey"> Units</span>
            </span>
            <span class="mt-0 pt-0 is-capitalized has-text-grey is-14"
              >Data Terminations
            </span>
          </div>
        </div>
      </div>
    </div>

    <br />
    
    <div class="card">
      <header
        class="card-header-title is-justify-content-space-between p-1"
      >
        <div
          style=""
          class="pb-4 p-4 my-1 is-flex is-flex-direction-column is-size-6"
        >
          <p class="pb-1">All Time Traffic</p>
          <p class="hbt"></p>
        </div>
        <button class="card-header-icon" aria-label="more options">
          <span class="icon">
            <i
              class="mdi mdi-chart-gantt mdi-40px"
              aria-hidden="true"
            ></i>
          </span>
        </button>
      </header>

      <div class="card-content dbhcard pt-0">
        <div
          class="px-4 is-flex is-flex-direction-row is-justify-content-space-around"
        >
          <div
            class="is-flex is-flex-direction-column is-align-items-center is-justify-content-center"
          >
            <span class="is-zlue is-size-1 mb-0 pb-0">
              3K <span class="is-size-7 has-text-grey"> Erlangs</span>
            </span>
            <span class="mt-0 pt-0 is-capitalized has-text-grey is-14"
              >Voice Traffic
            </span>
          </div>
          <div class="hbtr"></div>
          <div
            class="is-flex is-flex-direction-column is-align-items-center is-justify-content-center"
          >
            <span class="is-zlue is-size-1">
              15G <span class="is-size-7 has-text-grey"> Bytes</span>
            </span>
            <span class="mt-0 pt-0 is-capitalized has-text-grey is-14"
              >Data Traffic
            </span>
          </div>
          <div class="hbtr"></div>
          <div
            class="is-flex is-flex-direction-column is-align-items-center is-justify-content-center"
          >
            <span class="is-zlue is-size-1">
              12M <span class="is-size-7 has-text-grey"> Units</span>
            </span>
            <span class="mt-0 pt-0 is-capitalized has-text-grey is-14"
              >Voice Terminations
            </span>
          </div>
          <div class="hbtr"></div>
          <div
            class="is-flex is-flex-direction-column is-align-items-center is-justify-content-center"
          >
            <span class="is-zlue is-size-1">
              69K <span class="is-size-7 has-text-grey"> Units</span>
            </span>
            <span class="mt-0 pt-0 is-capitalized has-text-grey is-14"
              >Data Terminations
            </span>
          </div>
        </div>
      </div>
    </div>
  </div> -->
  <!-- Four row data with Chart -->
</template>

<script>
// import { API, snakeToStartCase } from "@/utils";
import { useUserStore } from "@/store";
import { APIMultiRequests, API } from "@/utils";
import PieChart from "./PieChart.vue";
import GroupedBarChart from "./GroupedBarChart.vue";

const module = "/ Dashboard";
export default {
  methods: {
    async populateDashboard() {
      const cardRequests = [
        { mode: "get", url: `/dashboard?card=users` },
        // { mode: "get", url: `/dashboard?card=sites` },
        { mode: "get", url: `/dashboard?card=alarms` },
        { mode: "get", url: `/dashboard?card=tickets` },
        { mode: "get", url: `/dashboard?card=traffic` },
      ];

      // Make all requests in parallel
      const cardResponses = await APIMultiRequests(cardRequests);
      const [users, alarms, tickets, traffic] = cardResponses;

      // Populate in state
      this.users = users.data;
      this.userLoading = false;
      this.alarms = alarms.data;
      this.alarmLoading = false;
      this.tickets = tickets.data;
      this.ticketLoading = false;
      this.traffic = traffic.data;
      this.trafficLoading = false;

      let siteResponse = await API.get("/dashboard?card=sites");
      if (siteResponse.code === 200) {
        this.sites = siteResponse.data;
        this.siteLoading = false;
      }
    },
  },
  async mounted() {
    this.store.updateTopBar(`${module}`);
    await this.populateDashboard();
  },
  watch: {
    // async pageLoaded(status) {
    //   console.log("Page loaded", status);
    // },
    // series(nc) {
    //   this.series = nc;
    // },
  },
  setup() {
    const store = useUserStore();
    return { store };
  },
  components: { PieChart, GroupedBarChart },
  data() {
    return {
      ticketLoading: true,
      siteLoading: true,
      userLoading: true,
      alarmLoading: true,
      trafficLoading: true,

      series: [
        {
          name: "2G",
          data: [0, 0, 0, 0],
        },
        {
          name: "3G",
          data: [0, 0, 0, 0],
        },
        {
          name: "4G",
          data: [0, 0, 0, 0],
        },
      ],
      categories: [
        "Voice Traffic",
        "Data Traffic",
        "Voice Terminations",
        "Data Terminations",
      ],
      alarms: {},
      tickets: {},
      sites: {},
      traffic: {},
      users: {},
    };
  },
};
</script>

<style scoped>
.tg > .mdi:before,
.mdi-set {
  color: grey;
  font-size: 40px !important;
}
.dbcard {
  height: 400px;
  justify-content: space-around;
  align-items: center;
}
.dbhcard {
  height: 149px;
  justify-content: space-around;
  align-items: center;
}

.hbtr {
  border-right-color: #f5f7fb;
  border-right-style: solid;
  border-right-width: 2px;
}

.hbt {
  border-bottom-color: #467fcf;
  border-bottom-style: solid;
  border-bottom-width: 4px;
  width: 24px;
}
</style>
