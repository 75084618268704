<template>
  <section>
    <b-modal width="25vw" :active="show">
      <div class="card">
        <header class="card-header">
          <p class="card-header-title is-capitalized">
            {{ header }}
          </p>
        </header>
        <div class="card-content">
          <div class="content">
            {{ content }}
          </div>
        </div>
        <footer class="card-footer">
          <b-button @click="$emit('response', false)" type="is-light"
            class="is-radiusless card-footer-item">Cancel</b-button>
          <b-button @click="$emit('response', true)" type="is-link" class="is-radiusless card-footer-item">Confirm</b-button>
        </footer>
      </div>
    </b-modal>
  </section>
</template>

<script>
export default {
  props: {
    show: {
      default: false,
      type: Boolean
    },
    header: String,
    content: String,
  }
}
</script>