import { defineStore, createPinia } from 'pinia'
export const pinia = createPinia();


export const useUserStore = defineStore('user', {
    state: () => {
        return {
            // all these properties will have their type inferred automatically
            isAuthenticated: false,
            topBarText: '/ Home',
            userData: {
                company: {}
            },
            sitesData: [],
            showSites: false,
            currentSite: "",
        }
    },
    getters: {
        userId: (state) => state.userData.uuid,
        token: (state) => state.userData.token,
        username: (state) => `${state.userData.first_name} ${state.userData.last_name}`,
        preferences: (state) => JSON.parse(state.userData.ui_preferences),
        userRoles: (state) => state.userData.role_groups.map(n => n.name),
        isStaff: (state) => state.userData.company && state.userData.company.type === "in_house",
    },
    actions: {
        updateUserDetails(userData) {
            this.userData = userData;
        },
        updateAuth(authStatus) {
            this.isAuthenticated = authStatus;
        },
        updateTopBar(text) {
            this.topBarText = text;
        },
        updateCurrentSite(name) {
            this.currentSite = name;
        },
        getAuthStatus() {
            return this.isAuthenticated;
        },
        updateShowSites(newStatus) {
            this.showSites = newStatus;
        },
        updatePreferences(p) {
            this.userData.ui_preferences = p;
        },

        reset() {
            this.userData = {
                company: {}
            };
            this.updateTopBar('/ Home');
            this.updateAuth(false);
            localStorage.clear();
        }
    },
});

// export const requireAdminAccess = (to, from, next) => {
//     const store = useUserStore();
//     console.log(to, 'from', from)
//     console.log(store.userData, 'fghfgh');
//     next()
//     // Redirect to sites if user is not admin
//     // if (!store.userRoles.includes('super_admin')) {
//     //   next({ name: 'sites', });
//     //   return false;
//     // }
//     // else {next('/us/admin')}
// }