<template>
  <div class="is-flex is-flex-direction-column">
    <div style="overflow-x: auto" class="card">
      <header v-show="showHeader" class="card-header is-danger p-2">
        <p class="card-header-title is-size-6 is-flex">
          <b-field>
            <b-input
              style="width: 30vw"
              class="is-fullwidth"
              icon="magnify"
              placeholder="Filter by name, or other values..."
              v-model="search"
            ></b-input>
          </b-field>
        </p>
        <p class="is-align-self-center pr-4">
          <b-dropdown
            :disabled="!rowSelected"
            :title="`${
              rowSelected
                ? 'Perform specific actions on the selected row'
                : 'First select a row'
            }`"
            position="is-bottom-left"
            append-to-body
            aria-role="list"
          >
            <template #trigger="{ active }">
              <b-button
                aria-label="actions"
                type="is-dark"
                outlined
                :icon-right="active ? 'chevron-up' : 'chevron-down'"
                icon-left="account-cog"
                icon-size="small"
                >Actions
              </b-button>
            </template>

            <b-dropdown-item
              aria-role="listitem"
              class="is-flex"
              v-if="
                !(
                  this.rowSelected && selectedRow.email === store.userData.email
                )
              "
              @click="showConfirmModalForDelete = true"
            >
              <span>
                <b-icon class="mr-1" icon="trash-can"></b-icon>
              </span>
              <span class="is-align-self-center"> Delete </span>
            </b-dropdown-item>

            <b-dropdown-item
              aria-role="listitem"
              class="is-flex"
              @click="showConfirmModalForDeactivate = true"
              v-if="
                !(
                  this.rowSelected && selectedRow.email === store.userData.email
                )
              "
            >
              <span>
                <b-icon
                  class="mr-1"
                  :icon="
                    selectedRow.is_active ? 'account-remove' : 'account-check'
                  "
                ></b-icon>
              </span>
              <span class="is-align-self-center">
                {{ selectedRow.is_active ? "Deactivate" : "Activate" }}
              </span>
            </b-dropdown-item>

            <!-- <b-dropdown-item
              aria-role="listitem"
              class="is-flex"
              @click="viewFullDetailsHandler"
            >
              <span>
                <b-icon class="mr-1" icon="eye"></b-icon>
              </span>
              <span class="is-align-self-center">View Full Details</span>
            </b-dropdown-item> -->

            <b-dropdown-item
              aria-role="listitem"
              class="is-flex"
              v-if="
                !(
                  this.rowSelected &&
                  (selectedRow.company.type !== 'in_house' ||
                    selectedRow.email === store.userData.email)
                )
              "
              @click="showChangeAccessModal = true"
            >
              <span>
                <b-icon class="mr-1" icon="account-edit"></b-icon>
              </span>
              <span class="is-align-self-center"> Change Access Role </span>
            </b-dropdown-item>

            <b-dropdown-item
              v-if="!(this.rowSelected && selectedRow.is_registered)"
              aria-role="listitem"
              class="is-flex"
              @click="resendRegistrationEmail"
            >
              <span>
                <b-icon class="mr-1" icon="email-arrow-right"></b-icon>
              </span>
              <span class="is-align-self-center">
                Resend Registration Email
              </span>
            </b-dropdown-item>
          </b-dropdown>
        </p>

        <p class="is-align-self-center demarcator"></p>

        <p class="is-align-self-center pr-4">
          <b-button
            title="Refresh"
            type="is-dark"
            icon-left="refresh"
            class="card-header-icon"
            @click="handleRefresh"
          ></b-button>
        </p>
      </header>
      <div class="card-content p-4">
        <b-table
          style="color: grey"
          class="has-text-grey"
          :data="renderedData.length === 0 ? [] : renderedData"
          :loading="isLoading"
          :hoverable="true"
          :striped="true"
          :selected.sync="selectedRow"
          default-sort-direction="desc"
          :paginated="renderedData.length > perPage + 1"
          :per-page="perPage"
          :current-page.sync="currentPage"
          :pagination-simple="false"
          pagination-position="bottom"          
        >
          <b-table-column
            cell-class="tdd"
            label="No"
            field="no"
            :centered="true"
            :sortable="true"
            v-slot="props"
          >
            <span class="is-capitalized py-4">
              {{ props.row.no }}
            </span>
          </b-table-column>

          <b-table-column
            cell-class="tdd"
            label="First Name"
            field="firstName"
            :centered="false"
            v-slot="props"
          >
            <span class="is-capitalized py-4">
              {{ props.row.first_name || "N/A" }}
            </span>
          </b-table-column>

          <b-table-column
            cell-class="tdd"
            label="Last Name"
            field="lastName"
            :centered="false"
            v-slot="props"
          >
            <span class="is-capitalized py-4">
              {{ props.row.last_name || "N/A" }}
            </span>
          </b-table-column>

          <b-table-column
            cell-class="tdd"
            label="Email Address"
            field="email"
            :centered="true"
            v-slot="props"
          >
            <span class="py-4">
              {{ props.row.email }}
            </span>
          </b-table-column>

          <b-table-column
            cell-class="tdd"
            label="Affilitation"
            field="affiliation"
            :centered="true"
            :width="300"
            :sortable="true"
            v-slot="props"
          >
            <span class="is-capitalized py-4">
              {{ props.row.company.name }}
            </span>
          </b-table-column>

          <b-table-column
            cell-class="tdd"
            label="Roles"
            field="role"
            :width="280"
            :centered="true"
            v-slot="props"
          >
            <span class="is-capitalized py-4">
              <span :key="index" v-for="(role, index) in props.row.role_groups">
                <b-tag type="is-dark" v-if="role.name !== 'user'" class="mr-2">{{ snakeToStartCase(role.name) }}</b-tag>
              </span>
            </span>
          </b-table-column>

          <b-table-column
            cell-class="tdd"
            label="Verification Status"
            field="verified"
            :centered="true"
            :sortable="true"
            v-slot="props"
          >
            <span class="is-capitalized py-4">
              <b-field>
                <b-tag
                  :type="`${
                    props.row.is_verified ? 'is-success' : 'is-danger'
                  }`"
                  :icon="`${
                    props.row.is_verified ? 'account-check' : 'account-remove'
                  }`"
                  >{{
                    props.row.is_verified ? "Verified" : "Unverified"
                  }}</b-tag
                >
              </b-field>
            </span>
          </b-table-column>


          <b-table-column
            cell-class="tdd"
            label="Account Status"
            field="account_status"
            :centered="true"
            :sortable="true"
            v-slot="props"
          >
            <span class="is-capitalized py-4">
              <b-field>
                <b-tag
                  :type="`${props.row.is_active ? 'is-success' : 'is-danger'}`"
                  :icon="`${props.row.is_active ? 'check' : 'close'}`"
                  >{{ props.row.is_active ? "Active" : "Deactivated" }}</b-tag
                >
              </b-field>
            </span>
          </b-table-column>
          <!-- 
          <b-table-column
            :key="index"
            v-for="(column, index) in tableColumns"
            cell-class="tdd"
            :centered="column.centered"
            :visible="column.visible"
            :field="column.field"
            :label="column.label"
            :numeric="column.numeric"
            :sortable="column.sortable"
            v-slot="props"
          >
          </b-table-column> -->

          <template #empty>
            <div class="has-text-centered">No records.</div>
          </template>
        </b-table>
      </div>

      <EditUserRole
        v-if="showChangeAccessModal"
        :show="showChangeAccessModal"
        :user="selectedRow"
        @response="(c) => changeRoleAccessHandler(c)"
      />

      <ConfirmModalStepped
        header="Delete User"
        :confirmAgainst="selectedRow.uuid"
        :show="showConfirmModalForDelete"
        :content="`Are you sure you want to delete that account?`"
        @response="(c) => deleteHander(c)"
      />

      <ConfirmModal
        :header="selectedRow.is_active ? 'Deactivate User' : 'Activate User'"
        :content="`Are you sure you want to ${
          selectedRow.is_active ? 'deactivate' : 'activate'
        } the account registered for ${selectedRow.email}?`"
        :show="showConfirmModalForDeactivate"
        @response="(c) => activateOrDeactivateHandler(c)"
      />
    </div>
  </div>
</template>

<script>
import ConfirmModal from "@/components/ConfirmModal.vue";
import { snakeToStartCase } from "@/utils";
import EditUserRole from "./EditUserRole.vue";
import { useUserStore } from "@/store";
import ConfirmModalStepped from "@/components/ConfirmModalStepped.vue";

export default {
  setup() {
    const store = useUserStore();
    return { store };
  },
  props: {
    tableData: Array,
    showHeader: {
      default: true,
      type: Boolean,
    },
  },
  watch: {
    tableData(ntd) {
      this.renderedData = ntd;
      this.isLoading = false;
      this.rowSelected = false;
    },
    selectedRow(data) {
      this.rowSelected = data !== {};
    },
    // whenever question changes, this function will run
    search(query) {
      this.renderedData = this.tableData;
      if (query.length >= 3) {
        this.isLoading = true;
        this.rowSelected = false;
        setTimeout(() => {
          let filterResult = [];
          for (const item of this.tableData) {
            // Search the values
            for (const s of Object.values(item)) {
              if (
                JSON.stringify(s)
                  .toLocaleLowerCase()
                  .includes(query.toLocaleLowerCase()) &&
                !filterResult.includes(item)
              ) {
                filterResult.push(item);
              }
            }
          }
          this.renderedData = filterResult;
          this.isLoading = false;
        }, 400);
      }
    },
  },
  methods: {
    handleRefresh() {
      this.isLoading = true;
      this.renderedData = this.tableData;
      setTimeout(() => {
        this.isLoading = false;
      }, 200);
    },
    snakeToStartCase(str) {
      return snakeToStartCase(str);
    },

    deleteHander(confirmed) {
      this.showConfirmModalForDelete = false;
      if (!confirmed) {
        return;
      }

      this.$emit("deleteUser", this.selectedRow.uuid);
    },

    viewFullDetailsHandler() {
      this.showFullDetailsModal = true;
    },

    changeRoleAccessHandler(confirmed) {
      this.showChangeAccessModal = false;
      if (!confirmed) {
        return;
      }
      // Success
      this.$emit("roleUpdated");
      this.$buefy.snackbar.open({
        message: "User's roles successfully updated",
        type: "is-success",
        pauseOnHover: false,
        position: "is-bottom",
      });
    },

    // Deactivate user request
    activateOrDeactivateHandler(confirmed) {
      this.showConfirmModalForDeactivate = false;
      if (!confirmed) {
        return;
      }

      // Deactivate user request
      this.$emit("activateOrDeactivate", {
        uuid: this.selectedRow.uuid,
        status: this.selectedRow.is_active,
      });
    },

    resendRegistrationEmail() {
      let existingTime = localStorage.getItem(
        `resend_${this.selectedRow.uuid}`
      );

      if (existingTime) {
        // Don't bombard emails
        let ttl = Math.floor((new Date().getTime() - existingTime) / 1000);
        // 60s
        if (ttl < 60) {
          this.$buefy.snackbar.open({
            message: `Please wait for ${
              60 - ttl
            } seconds before sending another email.`,
            type: "is-warning",
            pauseOnHover: false,
            position: "is-bottom",
          });
          return;
        }
      }
      // Announce and make request
      this.$emit("resendRegistrationEmail", this.selectedRow.email);
      localStorage.setItem(
        `resend_${this.selectedRow.uuid}`,
        new Date().getTime()
      );
    },
  },
  data() {
    return {
      search: "",
      perPage: 25,
      currentPage: 1,
      selectedRow: {},
      isLoading: false,
      renderedData: [],
      rowSelected: false,
      showFullDetailsModal: false,
      showChangeAccessModal: false,
      showConfirmModalForDelete: false,
      showConfirmModalForDeactivate: false,
    };
  },
  components: { EditUserRole, ConfirmModal, ConfirmModalStepped },
};
</script>
<style scoped>
.status-icon-green {
  color: green;
}

.card-content > .table td,
.table th {
  padding-top: 0.8em !important;
  padding-bottom: 0.8em !important;
}

.status-icon-red {
  color: red;
}

.status-icon > .mdi:before {
  font-size: 24px !important;
}
.demarcator {
  width: 18px;
  border-color: #f5f7fb;
  border-left: solid;
  border-width: 1px;
  height: 30px;
}
</style>
